import React, { useState } from "react";
import { Layout, Menu, Dropdown, Spin } from "antd";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "./MainLayout.css"; // Import the CSS file
import AdminHeader from "./components/AdminHeader/AdminHeader";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../Assets/svg/TPMlogo.svg";
import LoginInfoIcon from "../Assets/svg/loginInfoIcon.svg";
import ProfileInfoIcon from "../Assets/svg/profileInfoIcon.svg";
import icon7 from "../Assets/svg/iconMenu7.svg";
import backToWorkspace from "../Assets/svg/backToWorkspace.svg";

const { Header, Content, Sider } = Layout;

const SettingsLayout = ({
  notifications,
  setNotifications,
  unreadNotifications,
  setUnreadNotifications,
}) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // Event listener for window resize
  window.addEventListener("resize", handleResize);

  if (window.innerWidth < 768 && !isSmallScreen) {
    setIsSmallScreen(true);
  }

  const handleLogout = async (event) => {
    if (event && event.preventDefault) {
      event?.preventDefault();
    }

    setLoading(true);
    try {
      // Assuming a simulated delay for logging out
      setTimeout(() => {
        localStorage.clear();
        setLoading(false);
        navigate("/login");
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      setLoading(false);
    }
  };

  const menu = (
    <Menu theme="dark" mode="vertical">
      <Menu.Item key="1">
        <Link
          to="/settings/account-summary"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Account summary
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          to="/settings/profile-information"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Profile informations
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          to="/settings/login-information"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Log in informations
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <Link
          to="/settings/payments"
          style={{ textDecoration: "none", color: "#6A113C", textWrap: "wrap !important"}}
        >
          Subscription plans and Payment
        </Link>
      </Menu.Item> */}
      <Menu.Item key="7">
        <Link
          to="/dashboard/contracts"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Back to contracts
        </Link>
      </Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {isSmallScreen ? null : (
        <Sider className="sider">
          <div className="menuDesign">
            <img src={logo} style={{ marginBottom: "46px" }} alt="logo" />
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            className="menu"
          >
            {" "}
            <Menu.Item key="7" style={{ marginBottom: "20px" }}>
              <Link
                to="/dashboard/contracts"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img
                  src={backToWorkspace}
                  style={{ marginRight: "12px" }}
                  alt="menu2"
                />
                Back to contracts
              </Link>
            </Menu.Item>
            <p className="siderText">Account Settings</p>
            {/* <Menu.Item
              key="1"
              className={
                location.pathname === "/settings/account-summary"
                  ? "costum-menu-item-active"
                  : ""
              }
            >
              <Link
                to="/settings/account-summary"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img src={AccountSummaryIcon} style={{ marginRight: "12px" }} alt="menu1" />
                Account summary
              </Link>
            </Menu.Item> */}
            <Menu.Item
              key="2"
              className={
                location.pathname === "/settings/profile-information"
                  ? "costum-menu-item-active"
                  : ""
              }
            >
              <Link
                to="/settings/profile-information"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img
                  src={ProfileInfoIcon}
                  style={{ marginRight: "12px" }}
                  alt="menu2"
                />
                Profile informations
              </Link>
            </Menu.Item>
            <Menu.Item
              key="3"
              className={
                location.pathname === "/settings/login-information"
                  ? "costum-menu-item-active"
                  : ""
              }
            >
              <Link
                to="/settings/login-information"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img
                  src={LoginInfoIcon}
                  style={{ marginRight: "12px" }}
                  alt="menu2"
                />
                Log in informations
              </Link>
            </Menu.Item>
            {/* <Menu.Item
              key="4"
              className={
                location.pathname === "/settings/payments"
                  ? "costum-menu-item-active"
                  : ""
              }
            >
              <Link
                to="/settings/payments"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img src={PaymentMenuIcon} style={{ marginRight: "12px"}} alt="menu2" />
                Subscription plans and Payment
              </Link>
            </Menu.Item> */}
            <Menu.Item
              key="9"
              className={
                location.pathname === "/login"
                  ? "costum-menu-item-active logoutMenu"
                  : "logoutMenu"
              }
              onClick={handleLogout}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <Spin size="small" style={{ marginRight: 8 }} />
                  Logging out...
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <img
                    src={icon7}
                    style={{ marginRight: 12 }}
                    alt="Logout Icon"
                  />
                  Logout
                </div>
              )}
            </Menu.Item>
            {/* Add more menu items as needed */}
          </Menu>
        </Sider>
      )}
      <Layout className="site-layout">
        <Header
          className="adminHeader"
          style={{ minHeight: "100px", padding: "0px !important" }}
        >
          <div className="leftContent">
            {isSmallScreen ? (
              <Dropdown overlay={menu} trigger={["click"]}>
                <MenuOutlined
                  style={{
                    fontSize: "24px",
                    marginBottom: "16px",
                    color: "#6A113C",
                  }}
                />
              </Dropdown>
            ) : null}
          </div>
          <div className="rightContent">
            <AdminHeader
              notifications={notifications}
              setNotifications={setNotifications}
              unreadNotifications={unreadNotifications}
              setUnreadNotifications={setUnreadNotifications}
            />
          </div>
        </Header>
        <Content style={{ margin: "0px", backgroundColor: "white" }}>
          <Outlet style={{ width: "100% !important" }} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SettingsLayout;
