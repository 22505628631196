import { Table } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import done from "../../../../../Assets/svg/done.svg";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import EditDraftVisits from "../Modals/EditDraftVisits";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import { formatDateAndTime } from "../../../../../services/helpFunctions/formatDateAndTime";

function DraftsTable({
  siteVisitCards,
  setSiteVisitCards,
  fetchDraftSiteVisitCards,
  draftsLoading,
  setDraftsLoading,
  onDraftPageChange,
  draftsPagination,
  setDraftPagination,
  onOk,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  useEffect(() => {
    setDraftsLoading(true);
    setSiteVisitCards([]);
    fetchDraftSiteVisitCards(projectID);
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const [recordToDelete, setRecordToDelete] = useState();

  const handleDelete = (record) => {
    const siteVisitId = record.key;
    setRecordToDelete(siteVisitId);
    setShowDeleteModal(true);
  };

  const onEdited = () => {
    setShowEditModal(false);
    onOk();
  };

  const handleConfirmDelete = async () => {
    setDraftsLoading(true);
    if (recordToDelete) {
      const requestBody = [
        {
          id: recordToDelete,
          isDeleted: false,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;
      httpService.delete(
        url,
        JSON.stringify(requestBody),
        (response) => {
          const message =
            response.message ||
            "This draft has been deleted successfully from the database!";
          setMessage({
            trigger: true,
            type: "success",
            icon: done,
            title: "Draft deleted successfully!",
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setDraftsLoading(false);
          fetchDraftSiteVisitCards(projectID);
          setShowDeleteModal(false);
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message || "Error deleting record.";
          console.error("Error deleting record:", error);
          setMessage({
            trigger: true,
            type: "danger",
            icon: exclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setDraftsLoading(false);
        }
      );

      setRecordToDelete(null);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setDraftPagination({ ...draftsPagination, current: 1 });
  };

  const columns = [
    {
      title: <span className="styledTitle">Last updated</span>,
      dataIndex: "lastUpdateDate",
      key: "lastUpdateDate",
      sorter: (a, b) => a.lastUpdateDate.localeCompare(b.lastUpdateDate),
      render: (text) => {
        return <span className="styledDataIndex3">{text}</span>;
      },
    },
    // {
    //   title: <span className="styledTitle">Site Name</span>,
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    //   dataIndex: "name", // Raw field for identifier
    //   key: "name",
    //   render: (text, record) => (
    //     <span className="styledDataIndex3">{text}</span>
    //   ),
    // },
    {
      title: <span className="styledTitle">Date of Planned Visit</span>,
      sorter: (a, b) =>
        a.dateOfPlannedVisit.localeCompare(b.dateOfPlannedVisit),
      dataIndex: "dateOfPlannedVisit",
      key: "dateOfPlannedVisit",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    // {
    //   title: <span className="styledTitle">Location</span>,
    //   sorter: (a, b) => a.location.localeCompare(b.location),
    //   dataIndex: "location",
    //   key: "location",
    //   render: (text) => <span className="styledDataIndex3">{text}</span>,
    // },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    // {
    //   title: <span className="styledTitle">Site Visit Status</span>,
    //   sorter: (a, b) => a.siteVisitStatus.localeCompare(b.siteVisitStatus),
    //   dataIndex: "siteVisitStatus",
    //   key: "siteVisitStatus",
    //   render: (text) => {
    //     let className;
    //     let fill;
    //     if (text === "Data Collection") {
    //       className = "progress";
    //       fill = "#3B81FE";
    //     } else if (text === "Post-data Collection") {
    //       className = "monitoring";
    //       fill = "#FC9403";
    //     } else if (text === "Finalization") {
    //       className = "review";
    //       fill = "#9670B8";
    //     } else if (text === "Delivered") {
    //       className = "delivered";
    //       fill = "#045B46";
    //     } else if (text === "IP Feedback") {
    //       className = "debrief";
    //       fill = "#0F7507";
    //     } else {
    //       className = "noStatus";
    //       fill = "red";
    //     }
    //     return (
    //       <div className={`${className} m-0`}>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="8px"
    //           height="8px"
    //           viewBox="0 0 9 9"
    //         >
    //           <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
    //         </svg>
    //         <span>{text}</span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={editIcon}
            onClick={() => handleToggleEditModal(record)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={deleteIcon}
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(record)}
          ></img>
        </div>
      ),
    },
  ];

  const formatData = (data) => {
    let siteVisitCard;

    try {
      siteVisitCard = JSON.parse(data.jsonData);
    } catch (error) {
      return {
        key: data?.id,
        dateOfPlannedVisit: "Not valid data!",
        ip: "Not valid data!",
        cluster: "Not valid data!",
        virtual: "Not valid data!",
        lastUpdateDate:
          data?.updatedDate !== null
            ? formatDateAndTime(data?.updatedDate)
            : formatDateAndTime(data?.createdDate) || "No Date Available",
      };
    }

    return {
      key: data?.id,
      dateOfPlannedVisit: siteVisitCard?.plannedVisit || "No Date Available",
      ip: siteVisitCard?.IPPlan?.[0]?.implementingPartner?.name || "N/A",
      cluster: siteVisitCard?.Cluster?.[0]?.name || "N/A",
      virtual: siteVisitCard?.LocationDetails?.isVirtual ? "Yes" : "No",
      lastUpdateDate:
        data?.updatedDate !== null
          ? formatDateAndTime(data?.updatedDate)
          : formatDateAndTime(data?.createdDate) || "No Date Available",
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  return (
    <>
      <div>
        {isAuthorized([
          RolesEnum.SystemAdmin,
          RolesEnum.ProjectManager,
          RolesEnum.FieldCoordinator,
          RolesEnum.FieldLogistics,
        ]) && (
          <Table
            loading={draftsLoading}
            dataSource={formattedData}
            columns={columns}
            pagination={{
              ...draftsPagination,
              onChange: onDraftPageChange,
            }}
            className="tableTPM"
            scroll={{ x: "max-content" }}
          />
        )}
      </div>
      <EditDraftVisits
        onOk={onEdited}
        visible={showEditModal}
        onCancel={handleToggleEditModal}
        siteVisitCardId={selectedSiteVisit}
        setMessage={setMessage}
      />
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "Warning: You are about to permanently delete this site visit card from your drafts. This action cannot be undone."
        }
        header={"Delete "}
        onConfirm={handleConfirmDelete}
        loading={draftsLoading}
      />
    </>
  );
}

export default DraftsTable;
