import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Modal, Spin } from "antd";
import "./style.scss";
import { httpService } from "../../../../services/httpService.service";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import { useFilePreview } from "../../completed/ReportingList/Download";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";

const PreviewModal = ({ visible, record, onCancel }) => {
  console.log(record);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ipPlanCard, setIpPlanCard] = useState({});
  const { previewFiles } = useFilePreview();

  useEffect(() => {
    if (record) {
      setLoading(true);
      const apiIpPlan = `/api/IPPlan/getIPPlan?id=${record}`;

      httpService.get(
        apiIpPlan,
        (res) => {
          setIpPlanCard(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [record]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  function getMonthName(monthNumber) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (monthNumber < 1 || monthNumber > 12) {
      throw new Error("Invalid month number");
    }

    return monthNames[monthNumber - 1];
  }

  const checkStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        className = "discussion";
        fill = "var(--system-yellow-warning, #AA1A5F)";
        text = "Discussion";
        break;
      case 2:
        className = "documentReview";
        fill = "var(--system-yellow-warning, #FC9403)";
        text = "Document Review";
        break;
      case 3:
        className = "prePlanningPhase";
        fill = "var(--Primary-Blueberry-700, #325899)";
        text = "Pre Planning Phase";
        break;
      case 4:
        className = "dataCollection";
        fill = "#3B81FE";
        text = "Data Collection";
        break;
      case 5:
        className = "closed";
        fill = "#555555";
        text = "Closed";
        break;
      default:
        className = "noStatus";
        fill = " rgba(172, 12, 12, 0.777)";
        text = "No status";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Preview IP Plan Card</h2>
        </>
      }
      open={visible}
      closable={false}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      {
        <Spin spinning={loading}>
          <div>
            <Form form={form} layout="vertical">
              <Form.Item
                name="id"
                label={<span style={{ fontWeight: "bold" }}>Identifier:</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{ipPlanCard?.identifier}</p>
              </Form.Item>
              <p className="modalsHeader1">General details</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Implementing Partner:
                    </span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.implementingPartner?.name}
                  </p>
                </Form.Item>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Governorate/State:
                    </span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.ipPlanStates
                      ?.map((state) => state?.stateName)
                      .join(", ")}
                  </p>
                </Form.Item>
                {ipPlanCard?.ipPlanCities?.length ? (
                  <Form.Item
                    name="ip"
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        City/Disctrict/Municipality:
                      </span>
                    }
                    style={{ width: "48%" }}
                  >
                    <p className="previewText">
                      {ipPlanCard?.ipPlanCities
                        ?.map((city) => city?.name)
                        .join(", ")}
                    </p>
                  </Form.Item>
                ) : (
                  ""
                )}
              </div>
              {ipPlanCard?.planningRound?.programDescription && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item
                    name="ip"
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        IP Program Activities Description:
                      </span>
                    }
                    style={{ width: "100%" }}
                  >
                    <p className="previewText">
                      {ipPlanCard?.planningRound?.programDescription}
                    </p>
                  </Form.Item>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Assigned to a field coordinator:
                    </span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.assignee?.userDetails?.firstName
                      ? `${ipPlanCard?.assignee?.userDetails?.firstName}
                     ${ipPlanCard?.assignee?.userDetails?.lastName}`
                      : ipPlanCard?.assignee?.email}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Clusters:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.clusters
                      ?.map((cluster) => cluster?.name)
                      .join(", ")}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      General information about on-site operations:
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">{ipPlanCard?.generalInfo}</p>
                </Form.Item>
              </div>
              <p className="modalsHeader1">Assigned to round</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Month:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {getMonthName(ipPlanCard?.planningRound?.month)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Year:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.planningRound?.year}
                  </p>
                </Form.Item>
              </div>
              <p className="modalsHeader1">IP Point of contact</p>
              <div className="contactInfoWrapper">
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>Contact Name:</span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.pointOfContact?.name}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Email:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.pointOfContact?.email}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Role:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.pointOfContact?.role}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={
                    <span style={{ fontWeight: "bold" }}>Organization:</span>
                  }
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.pointOfContact?.organization}
                  </p>
                </Form.Item>
                <Form.Item
                  name="ip"
                  label={<span style={{ fontWeight: "bold" }}>Phone:</span>}
                  style={{ width: "48%" }}
                >
                  <p className="previewText">
                    {ipPlanCard?.pointOfContact?.phone}
                  </p>
                </Form.Item>
                {ipPlanCard?.pointOfContact?.skype && (
                  <Form.Item
                    name="ip"
                    label={
                      <span style={{ fontWeight: "bold" }}>
                        Name of the tool:
                      </span>
                    }
                    style={{ width: "48%" }}
                  >
                    <p className="previewText">
                      {ipPlanCard?.pointOfContact?.skype}
                    </p>
                  </Form.Item>
                )}
              </div>
              <div
                className="my-3"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Form.Item
                  name="isPostponed"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Current IP status:
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText styledTitle">
                    {checkStatus(ipPlanCard?.currentIPStatus)}
                  </p>
                </Form.Item>
              </div>
              {ipPlanCard?.ipPlanFiles?.length > 0 ? (
                <div className="my-3">
                  <span style={{ fontWeight: "bold" }}>
                    Security plan (recommendations from IP):
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="filesWrapper">
                {ipPlanCard?.ipPlanFiles?.map((file) => (
                  <div
                    className="fileDetails"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                  >
                    <div className="fileIcon">
                      <img src={uploadIcon} alt="uploaded" />
                    </div>
                    <div className="fileInfo">
                      <span>{file?.fileName}</span>
                      <p className="mb">
                        {(parseInt(file?.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                        MB
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <Divider />
              <div className="button-container">
                <Button
                  className="inviteButtonTPM"
                  key="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      }
    </Modal>
  );
};

export default PreviewModal;
