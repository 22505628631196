import React, { useEffect, useState } from "react";
import { Modal, Button, Spin, Divider } from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "./style.scss";
import { formatDate } from "../../../../../services/helpFunctions/formatDateAndTime";

const PreviewTaskModal = ({ isVisible, onClose, taskId }) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTaskData = (taskId) => {
    setLoading(true);
    httpService.get(
      `/api/Tasks/getTask?id=${taskId}`,
      (res) => {
        setTask(res.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (taskId) {
      fetchTaskData(taskId);
    }
  }, [taskId]);

  const formatType = (type) => {
    switch (type) {
      case 1:
        return "Ops preparation";
      case 2:
        return "Data Collection";
      case 3:
        return "Data check";
      case 4:
        return "Translation";
      case 5:
        return "Field report";
      case 6:
        return "Data cleaning";
      case 7:
        return "Data analysis";
      case 8:
        return "Data translation";
      case 9:
        return "IP report";
      case 10:
        return "Donor report";
      case 11:
        return "Other";
      default:
        return "Unknown Type";
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return "Assigned";
      case 2:
        return "Accepted";
      case 3:
        return "Completed";
      case 4:
        return "On hold";
      case 5:
        return "Archived";
      default:
        return "Unknown Status";
    }
  };

  const type = task?.type ? Number(task?.type) : null;
  const formattedType = type !== null ? formatType(type) : "Unknown Type";

  const status = task?.status ? Number(task?.status) : null;
  const formattedStatus =
    status !== null ? formatStatus(status) : "Unknown Status";

  const getStatusClass = (status) => {
    switch (status) {
      case "Archived":
        return { className: "status-archived", fill: "#555555" };
      case "Completed":
        return {
          className: "status-completed",
          fill: "var(--system-green-success, #0E9349)",
        };
      case "On hold":
        return { className: "status-on-hold", fill: "#D46600" };
      case "Accepted":
        return { className: "status-accepted", fill: "#3B81FE" };
      case "Assigned":
        return {
          className: "status-assigned",
          fill: "var(--system-yellow-warning, #FC9403)",
        };
      default:
        return { className: "status-unknown", fill: "#000000" };
    }
  };

  const { className, fill } = getStatusClass(formattedStatus);

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Preview Task</h2>
        </>
      }
      width={620}
      open={isVisible}
      onCancel={onClose}
      closable={false}
      footer={[
        <Button key="back" className="inviteButtonTPM" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        {task ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div>
              <p className="textHeadingTask">Task Card Identifier</p>
              <p className="textParagraphTask">{task?.identifier}</p>
            </div>
            <div>
              <p className="textHeadingTask">Task Name</p>
              <p className="textParagraphTask">{task?.name}</p>
            </div>
            <div>
              <p className="textHeadingTask">Task type</p>
              <p className="textParagraphTask">{formattedType}</p>
            </div>
            <div>
              <p className="textHeadingTask">Task Description</p>
              <p className="textParagraphTask">{task?.description}</p>
            </div>
            <div>
              <p className="textHeadingTask">Deadline</p>
              <p className="textParagraphTask">{formatDate(task?.deadline)}</p>
            </div>
            <div>
              <p className="textHeadingTask">Location</p>
              <p className="textParagraphTask">
                {task?.taskLocation?.country?.stateName}
              </p>
            </div>
            <div>
              <p className="textHeadingTask">Site visit card</p>
              <p className="textParagraphTask">{task?.taskSite?.identifier}</p>
            </div>{" "}
            <div>
              <p className="textHeadingTask">Assigned to</p>

              <div className="userListContainer d-flex flex-column gap-5 mb-4">
                {task?.assignees?.map((member) => (
                  <div key={member.id}>
                    <h1 style={{ fontSize: "16px", margin: "0" }}>
                      {member?.firstName && member?.lastName
                        ? `${member?.firstName} ${member?.lastName}`
                        : member?.email}
                    </h1>
                    <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                      {member.roleName || "No role specified"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <p className="textHeadingTask">Collection method</p>
              <p className="textParagraphTask">{task?.collectionMethod}</p>
            </div>
            <div>
              <p className="textHeadingTask">Number of interviews</p>
              <p className="textParagraphTask">{task?.numberOfInterviews}</p>
            </div>
            {/* <div>
              <p className="textHeadingTask">Number of surveys</p>
              <p className="textParagraphTask">{task?.collectionMethod}</p>
            </div>
            <div>
              <p className="textHeadingTask">
                Number of observation checklists
              </p>
              <p className="textParagraphTask">{task?.collectionMethod}</p>
            </div> */}
            <div>
              <p className="textHeadingTask">Any additional notes</p>
              <p className="textParagraphTask">{task?.additionalNotes}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                alignSelf: "stretch",
              }}
            >
              <p className="textHeadingTask">Status</p>
              <div
                className={`status-badge ${className}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8px"
                  height="8px"
                  viewBox="0 0 9 9"
                  style={{ marginRight: "6px" }}
                >
                  <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
                </svg>
                {formattedStatus}
              </div>
            </div>
          </div>
        ) : (
          <p>Loading task details...</p>
        )}
      </Spin>

      <Divider />
    </Modal>
  );
};

export default PreviewTaskModal;
