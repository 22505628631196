import { Select, DatePicker, Input, Button, List, Spin, Table } from "antd";
import React, { useState, useEffect } from "react";
import "../../reporting/Reporting.scss";
import previewList from "../../../../Assets/svg/previewList.svg";
import downloadList from "../../../../Assets/svg/downloadList.svg";
import { httpService } from "../../../../services/httpService.service";
import PreviewModal from "./PreviewModal";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

import { formatDateAndTime } from "../../../../services/helpFunctions/formatDateAndTime";

function ReportingList() {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const { Option } = Select;

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      includeOnlyWithTracking: true,
      Search: "",
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    const apiUrl = `/api/Site/getAllSiteVisits?${queryString}&SiteVisitStatus=6`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setSiteVisitCards(res.data);
          setPagination({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.pageNumber, pagination.pageSize, debouncedQuery]);

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleToggleExportModal = (record) => {
    const siteVisitCardData = siteVisitCards.find(
      (card) => card.id === record.key
    );
    if (siteVisitCardData) {
      const fileName = siteVisitCardData.identifier || "SiteVisit";
      exportToExcel([formatData(siteVisitCardData)], fileName);
    }
  };

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => (
        <span className="styledDataIndex3">{formatDateAndTime(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Resources</span>,
      sorter: (a, b) =>
        a.resources?.join("").localeCompare(b.resources?.join("")),
      dataIndex: "resources",
      key: "resources",
      render: (resources) => {
        return (
          <div className="resourcesColumn">
            {resources?.map((text, index) => (
              <span key={index}>{text}</span>
            ))}
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {/* {tracked && ( */}
          {/* )} */}
          {/* {isAuthorized([
            RolesEnum.SystemAdmin,
            RolesEnum.ProjectManager,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
          ]) && ( */}
          <img
            src={downloadList}
            onClick={() => handleToggleExportModal(record)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={previewList}
            onClick={() => handleTogglePreviewModal(record)}
            style={{ cursor: "pointer" }}
          ></img>
        </div>
      ),
    },
  ];

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      actualDateOfVisit: siteVisitCard?.tracking?.actualDateOfVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      resources: siteVisitCard?.siteVisitFiles
        .filter(
          (item) =>
            item.fileCategory === 1 ||
            item.fileCategory === 3 ||
            item.fileCategory === 4
        )
        ?.slice(-2)
        .map((file) => file?.fileName),

      reportingYear: siteVisitCard?.tracking?.reportingYear,
      reportingMonth: siteVisitCard?.tracking?.reportingMonth,
      trainingDate: siteVisitCard?.tracking?.trainingDate,
      trainingScoreInfo: siteVisitCard?.tracking?.trainingScoreInfo,
      actualDateOfVisit: siteVisitCard?.tracking?.actualDateOfVisit,
      dateOfReport: siteVisitCard?.tracking?.dateOfReport,
      dateOfDebrief: siteVisitCard?.tracking?.dateOfDebrief,
      percentageOfDataCollectionCompleted:
        siteVisitCard?.tracking?.percentageOfDataCollectionCompleted,
      fieldTeamReportingStatus:
        siteVisitCard?.tracking?.fieldTeamReportingStatus,
      trainingIsCompleted: siteVisitCard?.tracking?.trainingIsCompleted,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  const onPageChange = (page, pageSize) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: pageSize });
  };

  return (
    <>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Site Visit Reporting List</span>

        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input
              onChange={handleSearch}
              placeholder="Search... "
              suffix={<img src={SearchIcon} alt="Search" />}
            />
          </div>
        </div>
        <div>
          <Table
            loading={loading}
            // rowSelection={{ ...rowSelection }}
            dataSource={formattedData}
            columns={columns}
            pagination={{
              ...pagination,
              onChange: onPageChange,
            }}
            className="tableTPM"
            scroll={{ x: "max-content" }}
          />
        </div>
        <PreviewModal
          onClose={handleTogglePreviewModal}
          visible={showPreviewModal}
          siteVisitCardId={selectedSiteVisit}
        />
        {/* <Edit
          visible={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onOk={() => setShowEditModal(false)}
          siteVisitCardId={selectedSiteVisit}
          setMessage={setMessage}
        /> */}
      </div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
    </>
  );
}

export default ReportingList;
