import { Modal, Table, notification, Button } from "antd";
import { useState, useEffect } from "react";
import { httpService } from "../../../../services/httpService.service";
import deleteIcon from "../../../../Assets/svg/table-action-delete.svg";
import { RevertButton } from "../../../../Assets/svg";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import DeleteComponent from "../../../components/DeleteComponent/DeleteComponent";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../Assets/svg/exclamationMark.svg";

const DeletedModal = ({ visible, onOk, onCancel, fetchRedFlags }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState();
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (pagination) => {
    setLoading(true);
    const projectId = localStorage.getItem("currentProjectID");
    if (!projectId) {
      throw new Error("Project ID is not available");
    }
    const url = `/api/RedFlag/getAllRedFlags?ProjectId=${projectId}&GetDeletedOnly=true&page=${pagination.current}&pageSize=${pagination.pageSize}`;

    httpService.get(
      url,
      (res) => {
        setData(res.data);
        setPagination({
          ...pagination,
          total: res.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (err) => {
        console.error("Failed to fetch tasks:", err);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination);
    }
  }, [visible]);

  const deleteTask = (taskId) => {
    setRecordToDelete(taskId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async (taskId) => {
    setLoading(true);

    const url = `/api/RedFlag/deleteRedFlags?includeIsDeleted=true`;
    const data = JSON.stringify([{ id: recordToDelete, isDeleted: true }]);
    httpService.delete(
      url,
      data,
      (res) => {
        fetchData(pagination);
        setShowDeleteModal(false);
        setLoading(false);
      },
      (error) => {
        console.error("Failed to delete task:", error);
        const errorMessage = error?.errors?.[0]?.message;
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setShowDeleteModal(false);
        setLoading(false);
      }
    );
  };

  const recoverData = async (id) => {
    setLoading(true);
    await httpService.put(
      `/api/Site/revertDelete`,
      {
        entityType: 6,
        ids: [id],
      },
      (response) => {
        const message =
          response.message ||
          "Flag is successfully reverted from soft deletion";
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Flag reverted successfully!",
          text: message,
        });
        fetchData(pagination);
        fetchRedFlags(projectID);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message || "Failed to recover flags.";

        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination);
  };

  const columns = [
    {
      title: <span className="styledTitle">Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">IP tag</span>,
      dataIndex: "implementingPartnerName",
      key: "implementingPartnerName",
      sorter: (a, b) =>
        a.implementingPartnerName.localeCompare(b.implementingPartnerName),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Description</span>,
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Site visit id</span>,
      dataIndex: "siteVisitId",
      key: "siteVisitId",
      sorter: (a, b) => a.siteVisitId.localeCompare(b.siteVisitId),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Date of site visit</span>,
      dataIndex: "dateOfSiteVisit",
      key: "dateOfSiteVisit",
      sorter: (a, b) => a.dateOfSiteVisit.localeCompare(b.dateOfSiteVisit),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Office</span>,
      dataIndex: "office",
      key: "office",
      sorter: (a, b) => a.office.localeCompare(b.office),
      render: (record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Due date</span>,
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (record) => (
        <span className="styledDataIndex3">{record?.username}</span>
      ),
    },
    {
      title: <span className="styledTitle">Status</span>,
      dataIndex: "flagStatus",
      key: "flagStatus",
      sorter: (a, b) => a.flagStatus.localeCompare(b.flagStatus),
      render: (record) => {
        let className;
        let text;
        let fill;
        if (record === 1) {
          className = "archived";
          text = "Archived";
          fill = "var(--Neutrals-Gray, #555)";
        } else if (record === 2) {
          className = "resolved";
          text = "Resolved";
          fill = "#045B46";
        } else if (record === 3) {
          className = "onHold";
          text = "On Hold";
          fill = "#D46600";
        } else if (record === 4) {
          className = "inProgress";
          text = "In progress";
          fill = "var(--Primary-Blueberry-700, #325899);";
        } else if (record === 5) {
          className = "assigned";
          text = "Assigned";
          fill = "var(--system-yellow-warning, #FC9403)";
        }
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            onClick={() => deleteTask(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={deleteIcon} alt="delete" />
          </Button>
          <Button
            onClick={() => recoverData(record.id)}
            style={{ border: "none", boxShadow: "none" }}
          >
            <img src={RevertButton} alt="RevertButton" />
          </Button>
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete this red flag. This action can’t be undone. "
        }
        header={"Delete "}
        onConfirm={handleConfirmDelete}
      />
      <Modal
        title={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={cancelX}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={onCancel}
                alt="cancelX"
              />
            </div>
            <h2 className="modalHeader">Deleted flags</h2>
          </>
        }
        closable={false}
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        footer={[]}
        width={"70%"}
      >
        <Table
          loading={loading}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey="key"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </div>
  );
};

export default DeletedModal;
