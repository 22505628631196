import React, { useEffect, useState } from "react";
import { Pagination, Spin } from "antd";
import CalendarViewComponent from "../../../../components/CalendarView/CalendarViewComponent";
import Filters from "../Filters";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";

const CalendarView = ({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  onPageChange,
  handleFilterChange,
  filters,
  setFilters,
}) => {
  const projectID = localStorage.getItem("currentProjectID");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();
  const [pagination, setPagination] = useState();

  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  useEffect(() => {
    fetchSiteVisitCards(
      projectID,
      filters?.pageSize,
      filters?.current,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters]);

  const events = siteVisitCards.reduce((acc, siteVisitCard) => {
    const dateKey = siteVisitCard?.visit?.plannedVisit?.split("T")[0];

    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push({
      type: "success",
      status: siteVisitCard?.ipPlan?.currentIPStatus,
      content: siteVisitCard?.identifier,
      id: siteVisitCard?.id,
    });
    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format("YYYY-MM-DD");
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter((event) => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (
        filters.search &&
        !event.content.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
      <Filters
        onChange={handleFilterChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        fetchSiteVisitCards={fetchSiteVisitCards}
      />
      <Spin spinning={loading}>
        <CalendarViewComponent
          getListData={getListData}
          setClickedEventId={handleEventClicked}
        />
      </Spin>
      <div className="d-flex justify-content-end my-5">
        {/* <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) => {
            onPageChange(page, pageSize);
            setPagination({ ...pagination, current: page, pageSize });
          }}
        /> */}
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleCancel}
        siteVisitCardId={clickedEventId}
      />
    </>
  );
};

export default CalendarView;
