import { Button, DatePicker, Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import { httpService } from "../../../../services/httpService.service";

import { debounce } from "lodash";
const { Option } = Select;
//months
const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

const startYear = 2024;
const endYear = 2034;
const years = Array.from(
  { length: endYear - startYear + 1 },
  (_, i) => startYear + i
);

const Filters = ({
  filters,
  setFilters,
  pagination,
  setPagination,
  onFiltersChange,
  inputSearch,
  setInputSearch,
}) => {
  //Handle FIlters

  const [showFilters, setShowFilters] = useState(false);

  const [implementingPartners, setImplementingPartners] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [allCountries, setAllContries] = useState([]);
  const contractId = localStorage.getItem("currentContractId");

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      Search: "",
      ImplementingPartnerId: [],
      State: [],
      SiteVisitStatus: [],
      ClusterId: [],
      Month: [],
      Year: [],
      Governates: [],
      Cities: [],
    });
    setPagination({ ...pagination, current: 1 });
    setInputSearch("");
    setCities([]);
    setStates([]);
  };

  // const handleSearch = (event) => {
  //   const Search = event.target.value.toLowerCase();
  //   setFilters((prevFilters) => ({ ...prevFilters, Search }));
  //   // setPagination({ ...pagination, current: 1 });
  //   onFiltersChange({ Search: Search });
  // };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        Search: query,
      }));
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));

      onFiltersChange({ Search: query });
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setInputSearch(search);
    debouncedSearchHandler(search);
  };

  const handleIp = (ip) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ImplementingPartnerId: ip,
    }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ ImplementingPartnerId: ip });
  };

  const handleProgressStatus = (progressStatus) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SiteVisitStatus: progressStatus,
    }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ SiteVisitStatus: progressStatus });
  };

  const handleLocation = (location, countries = []) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Governates: [],
      Cities: [],
    }));
    setStates([]);
    setCities([]);
    const countryIds = countries
      .filter((country) => country?.key)
      .map((country) => country.key);

    const url =
      `/api/GeoData/states?` +
      countryIds.map((id) => `countryIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setStates(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({ ...prevFilters, State: location }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ State: location });
  };

  const handleStateChange = (value, governates) => {
    setCities([]);
    setFilters((prevFilters) => ({ ...prevFilters, Cities: [] }));
    const stateIds = governates
      .filter((state) => state?.key)
      .map((state) => state.key);

    const url =
      `/api/GeoData/cities?` + stateIds.map((id) => `stateIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setCities(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({ ...prevFilters, Governates: value }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Governorate: value });
  };

  const handleCityChange = (Cities) => {
    setFilters((prevFilters) => ({ ...prevFilters, Cities }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Governorate: Cities });
  };

  const handleCluster = (cluster) => {
    setFilters((prevFilters) => ({ ...prevFilters, ClusterId: cluster }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ ClusterId: cluster });
  };

  const handleMonth = (month) => {
    setFilters((prevFilters) => ({ ...prevFilters, Month: month }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Month: month });
  };

  const handleYear = (year) => {
    setFilters((prevFilters) => ({ ...prevFilters, Year: year }));
    // setPagination({ ...pagination, current: 1 });

    onFiltersChange({ Year: year });
  };

  useEffect(() => {
    const apiContries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;

    httpService.get(
      apiContries,
      (res) => setAllContries(res.data),
      (error) => {
        console.log(error);
      }
    );

    const apiClusters = "/api/Site/getAllClusters";
    httpService.get(
      apiClusters,
      (res) => setClusters(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input
          placeholder="Search..."
          onChange={handleSearchChange} // Correct prop name
          suffix={<img src={SearchIcon} alt="search icon" />}
          value={inputSearch}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              showSearch
              mode="multiple"
              value={
                filters.ImplementingPartnerId
                  ? filters.ImplementingPartnerId
                  : undefined
              }
              onChange={handleIp}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Progress status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              value={
                filters.SiteVisitStatus ? filters.SiteVisitStatus : undefined
              }
              mode="multiple"
              onChange={handleProgressStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={2}>Pre-planning phase</Option>
              <Option value={3}>Planning</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              onChange={handleCluster}
              value={filters.ClusterId ? filters.ClusterId : undefined}
              allowClear
              showSearch
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {clusters &&
                clusters.map((cluster) => (
                  <Option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month </p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              onChange={handleMonth}
              allowClear
              mode="multiple"
              showSearch
              value={filters.Month ? filters.Month : undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <Select
              onChange={handleYear}
              allowClear
              showSearch
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              value={filters.Year ? filters.Year : undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}

              {/* Add more role options as needed */}
            </Select>
          </div>

          <div className="filter">
            <p className="filterText">Country</p>
            <Select
              onChange={handleLocation}
              value={filters.State ? filters.State : undefined}
              allowClear
              showSearch
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries &&
                allCountries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          {states?.length ? (
            <div className="filter">
              <p className="filterText">
                {cities?.length
                  ? "Governorate/State"
                  : "Governorate/State or City"}
              </p>
              <Select
                onChange={handleStateChange}
                value={filters.Governates ? filters.Governates : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {states.map((state) => (
                  <Option key={state.id} value={state.stateName}>
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
          {cities?.length ? (
            <div className="filter">
              <p className="filterText">City</p>
              <Select
                onChange={handleCityChange}
                value={filters.Cities ? filters.Cities : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.name}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Filters;
