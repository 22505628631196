import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Upload,
  Divider,
  Progress,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../Assets/svg/exclamationMark.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Not addressed" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const AddRedFlagCardModal = ({
  visible,
  onClose,
  setMessage,
  fetchRedFlags,
  fetchDraftData,
}) => {
  const [users, setUsers] = useState();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [savingPorgres, setSavingPorgres] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState([]);

  const [form] = Form.useForm();

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";
    return date.format("YYYY-MM-DD");
  };

  const deleteFilesFromDB = async (fileKeys) => {
    await axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      });
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((files) => {
    const newFiles = files.reduce((acc, file) => {
      if (!fileList.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingFiles(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingFiles([]);
        setFileList((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingFiles((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, 300);

  const handleOk = async () => {
    setLoadingData(true);

    await form
      .validateFields()
      .then((values) => {
        const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
        const dueDate = formatDate(values.dueDate);
        const projectId = localStorage.getItem("currentProjectID");
        const contractId = localStorage.getItem("currentContractId");

        const formData = new FormData();

        formData.append("Name", values.name);
        formData.append("Description", values.description);
        formData.append("SiteVisitId", values.siteVisitCard);
        formData.append("ProjectId", projectId);
        formData.append("ContractId", contractId);
        formData.append("DateOfSiteVisit", dateOfSiteVisit);
        formData.append("DueDate", dueDate);
        formData.append("AssignedTo", values.assignedTo);
        formData.append("FlagStatus", values.flagStatus);
        formData.append("FollowUpNotes", values.followUpNotes);

        fileList?.forEach((file, index) => {
          // formData.append(
          //   `Files[${index}].id`,
          //   "00000000-0000-0000-0000-000000000000"
          // );

          formData.append(`Files[${index}].fileName`, file?.name);
          formData.append(`Files[${index}].fileKey`, file?.fileKey);
          formData.append(`Files[${index}].fileSize`, file?.size);
        });

        httpService.upload(
          "/api/RedFlag",
          formData,
          (response) => {
            const message =
              response.message ||
              "A new red flag has been created and added to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Red flag added successfully!",
              text: message,
            });
            form.resetFields();
            fetchRedFlags(false);
            fetchDraftData();
            handleLeave("submit");
            setFileList([]);
            setLoadingData(false);
          },
          (error) => {
            const messageError = error?.errors?.[0]?.message;
            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text:
                messageError ||
                "We had a problem adding this red flag to your database, please try again.",
            });
            setLoadingData(false);
          }
        );
      })
      .catch((errorInfo) => {
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Validation Error",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setLoadingData(false);
      });

    setTimeout(() => {
      setMessage({
        trigger: false,
      });
    }, 3000);
  };

  const handleSiteVisitChange = (id) => {
    const siteVisit = siteVisits?.find((siteVisit) => siteVisit?.id === id);
    const ipOfSiteVisit = siteVisit?.ipPlan?.implementingPartner?.name;
    form.setFieldValue("implementingPartner", ipOfSiteVisit);
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        const fieldCoordinators = res?.data;
        setUsers(fieldCoordinators);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleFileChange = ({ fileList }) => {
    setLoadingData(true);
    debouncedHandleUploadChange(fileList);
  };

  const handleResourceRemove = (file) => {
    deleteFilesFromDB([file.fileKey]);

    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleSaveProgress = () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    const filesData = fileList.map((file) => ({
      fileName: file?.name,
      fileKey: file?.fileKey,
      fileSize: file?.size,
    }));

    let slectedUsers;
    if (values.assignedTo) {
      slectedUsers = users?.find((user) => user.id === values.assignedTo);
    }

    const projectId = localStorage.getItem("currentProjectID");

    const RedFlagDraftData = {
      Name: values.name,
      ImplementingPartner: values?.implementingPartner,
      Description: values.description,
      SiteVisitId: values?.siteVisitCard,
      ProjectId: projectId,
      DateOfSiteVisit: values?.dateOfSiteVisit,
      DueDate: values?.dueDate,
      AssignedTo: slectedUsers,
      FlagStatus: values.flagStatus,
      FollowUpNotes: values.followUpNotes,
      ...(filesData?.length && { fileList: filesData }),
    };
    const jsonData = JSON.stringify(RedFlagDraftData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 3,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          fetchRedFlags(false);

          fetchDraftData();
          handleLeave("submit");
          setFileList([]);
          form.resetFields();
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: ExclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 1000);
      });
  };

  const handleLeave = (submit) => {
    if (submit != "submit") {
      const fileKeys = fileList?.map((file) => file.fileKey) || [];
      deleteFilesFromDB(fileKeys);
    }
    form.resetFields();
    setFileList([]);
    onClose();
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  return (
    <Modal
      destroyOnClose
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="cancelX"
        />
      </div>
      <h2 className="modalHeader">Add Red flag card</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          className="fullWidthInput"
          name="name"
          label="Name (type of red flag)"
          rules={[
            { required: true, message: "Enter the name of the red flag" },
          ]}
        >
          <Input
            placeholder="Enter the name of the red flag"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          name="siteVisitCard"
          label="Select the site visit card"
          rules={[{ required: true, message: "Select the site visit card" }]}
        >
          <Select
            onChange={handleSiteVisitChange}
            placeholder="<###> - SV - <Sitename> - <Location 2> - <Location 3>"
          >
            {siteVisits &&
              siteVisits.map((siteVisit) => (
                <Option key={siteVisit.id} value={siteVisit.id}>
                  {siteVisit.identifier}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="implementingPartner" label="Implementing Partner">
          <Input
            disabled
            placeholder="Implementing Partner of the selected site visit card"
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: "Enter the description of the flag" },
          ]}
        >
          <TextArea rows={4} placeholder="Enter the description of the flag" />
        </Form.Item>
        <Form.Item
          name="dateOfSiteVisit"
          label="Date of the red flag"
          rules={[{ required: true, message: "Enter the date of red flag" }]}
        >
          <DatePicker placeholder="Date of site visit" format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          name="dueDate"
          label="Due date for action"
          rules={[{ required: true, message: "Enter the due date" }]}
        >
          <DatePicker placeholder="Due date" format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item
          name="assignedTo"
          label="Assigned to a field coordinator"
          rules={[{ required: true, message: "Select a field coordinator..." }]}
        >
          <Select placeholder="Please select a field coordinator">
            {users &&
              users.map((user) => (
                <Option key={user.id} value={user.id}>
                  {user.userDetails?.firstName && user.userDetails?.lastName
                    ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                    : user.userName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="flagStatus"
          label="Flag status"
          rules={[{ required: true, message: "Select the flag status" }]}
        >
          <Select placeholder="Select the flag status">
            {flagStatuses.map((status) => (
              <Option key={status.id} value={status.id}>
                {status.status}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="followUpNotes"
          label="Follow-up notes"
          rules={[{ required: true, message: "Enter the follow-up notes" }]}
        >
          <TextArea rows={4} placeholder="Enter the follow-up notes" />
        </Form.Item>
        <div className="resourcesElement">
          <div className="resourcesLabel">
            <div className="resourcesUploadElement">
              <Form.Item
                name="files"
                label={
                  <div>
                    <p
                      className="modalsHeader1"
                      style={{
                        color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                        fontFamily: "Poppins", // Use camelCase for properties in React
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "144%",
                      }}
                    >
                      Evidence
                    </p>
                    <p
                      style={{
                        color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                        fontFamily: "Poppins", // Use camelCase for properties in React
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "22px",
                      }}
                    >
                      Please attach any relevant evidence if needed
                    </p>
                  </div>
                }
              >
                <Upload.Dragger
                  multiple
                  listType="text"
                  accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={(file) => handleFileSize(file)}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">
                      PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload.Dragger>
                <div className="uploadsContainer">
                  {loadingFiles?.map((file) => (
                    <div className="uploadContentLoading" key={file.uid}>
                      <div className="d-flex flex-row gap-3">
                        <Progress
                          className="uploadIcon"
                          type="circle"
                          width={44}
                          percent={file.percent || 0}
                          format={(percent) =>
                            percent < 100 ? (
                              `${percent}%`
                            ) : (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 48 }}
                                    spin
                                  />
                                }
                              />
                            )
                          }
                        />
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row gap-2">
                            {file.percent < 100 && (
                              <h1
                                style={{ color: "red" }}
                                className="loading-text"
                              >
                                Loading file's
                                <span className="dots">
                                  <span>.</span>
                                  <span>.</span>
                                  <span>.</span>
                                </span>
                              </h1>
                            )}
                            {file.percent === 100 && (
                              <h1
                                style={{ color: "green" }}
                                className="loading-text"
                              >
                                Saving the file's to the server
                                <span className="dots">
                                  <span>.</span>
                                  <span>.</span>
                                  <span>.</span>
                                </span>
                              </h1>
                            )}
                          </div>
                          {file.size && (
                            <p className="m-0 p-0">
                              {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                              MB
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {fileList &&
                    fileList.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            type="circle"
                            percent={100}
                            className="uploadIcon"
                            width={44}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1 className="loading-text">{file.name}</h1>
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
        <Divider />
        <Form.Item>
          <div className="buttonsModal mt-3">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              loading={loadingData || savingPorgres}
              onClick={handleSaveProgress}
            >
              Save as Draft
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loadingData || savingPorgres}
            >
              Add new red flag
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRedFlagCardModal;
