import React, { useContext, useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import PreviewTaskModal from "../Modals/PreviewTaskModal";
import Filters from "../Filters";
import { DataContext } from "../DataContext";
import { Pagination, Spin } from "antd";

const columns = [
  { id: 1, title: "Assigned", color: "#FC9403" },
  { id: 2, title: "Accepted", color: "#3B81FE" },
  { id: 3, title: "Completed", color: "#0E9349" },
  { id: 4, title: "On hold", color: "#D46600" },
  { id: 5, title: "Archived", color: "#555555" },
];

const KanbanView = ({ dataUpdated, setDataUpdated }) => {
  const context = useContext(DataContext);

  const [clickedTaskId, setClickedTaskId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [filters, setFilters] = useState({
    Search: "",
    Type: "",
    Status: "",
    Country: "",
    UserId: "",
  });

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(true);
  };

  const changeTaskStatus = async (draggedTask) => {
    const { id, column: newStatus } = draggedTask;

    // Optimistically update the task's status in the UI
    const updatedData = context.data.map((task) => {
      if (task.id === id) {
        return { ...task, status: newStatus };
      }
      return task;
    });

    // Update the context data temporarily
    context.setData(updatedData);

    try {
      // Change the Task Status in the backend
      await context.changeTaskStatus(id, newStatus);
    } catch (error) {
      console.error("Error changing Task Status:", error);
      // Optionally, re-fetch data to get the correct state
      context.fetchData(pagination.current, pagination.pageSize);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });
    return `${formattedDate} Time: ${formattedTime}`;
  };

  if (!context) {
    return <></>;
  }

  const { data, loading, fetchData, pagination, setPagination } = context;

  if (dataUpdated) {
    fetchData();
    setDataUpdated(false);
  }

  const onPageChange = (current, pageSize) => {
    setPagination({ ...pagination, current, pageSize });
  };

  const formattedData = data.map((task) => ({
    id: task.id,
    content: {
      title: task.name,
      statusUpdated: formatDate(task.updatedDate) || "Never updated",
      ip: task.implementingPartner?.name,
      state: task.taskLocation?.country?.stateName,
      municipality: task.taskLocation?.municipality,
    },
    column: task.status,
  }));

  return (
    <div>
      {clickedTaskId && (
        <PreviewTaskModal
          isVisible={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
          taskId={clickedTaskId}
        />
      )}
      <div className="mb-4 mt-5">
        <Filters
          filters={filters}
          setFilters={setFilters}
          setPagination={setPagination}
        />
      </div>
      <Spin spinning={loading}>
        <KanbanBoardComponent
          tasks={formattedData}
          columns={columns}
          changeStatus={changeTaskStatus}
          setClickedTaskId={handleTaskClick}
        />
      </Spin>
      <div className="d-flex justify-content-end my-5">
        <Spin spinning={loading}>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) => {
              onPageChange(page, pageSize);
              setPagination({ ...pagination, current: page, pageSize });
            }}
          />
        </Spin>
      </div>
    </div>
  );
};

export default KanbanView;
