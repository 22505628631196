import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Typography,
  Spin,
  Col,
  Select,
  Row,
  Switch,
  DatePicker,
  Upload,
  Checkbox,
  InputNumber,
  Progress,
  Tooltip,
} from "antd";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import "../../../planning/VisitBoard/Modals/style.scss";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import dayjs from "dayjs";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";
import dangerIcon from "../../../../../Assets/svg/dangerIcon.svg";
import Done from "../../../../../Assets/svg/done.svg";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import { useFilePreview } from "../../../completed/ReportingList/Download";
import { debounce } from "lodash";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;

const { TextArea } = Input;

const { Title } = Typography;

const Edit = ({ visible, onCancel, onOk, siteVisitCardId, setMessage }) => {
  const { previewFiles } = useFilePreview();

  const [form] = Form.useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enumerators, setEnumerators] = useState([]);
  const [files, setFiles] = useState({
    reportedDocs: [],
    siteVideos: [],
    otherReportedDocs: [],
  });
  const [loadingFiles, setLoadingFiles] = useState({
    reportedDocs: [],
    siteVideos: [],
    otherReportedDocs: [],
  });
  const [showVisitDetails, setShowVisitDetails] = useState(false);
  const [siteVisitId, setSiteVisitId] = useState("");
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [existingFiles, setExistingFiles] = useState([
    {
      report_documents: [],
      video_files: [],
      otherReportedDocs: [],
    },
  ]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [showScores, setShowScores] = useState({
    hasTrainingScore: false,
    hasPercentageCompleted: false,
  });

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => setMessage({ trigger: false }), 3000);
    }
    return isValidSize;
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((fileList, docsType) => {
    // Define newFiles first so it can be used both for the state update and API request
    const newFiles = fileList.reduce((acc, file) => {
      if (!files[docsType].some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    // Update the state with new files
    setLoadingFiles((prevState) => ({
      ...prevState,
      [docsType]: [...prevState[docsType], ...newFiles],
    }));

    // Create the formData for the API request
    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    // Send the API request
    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) => ({
            ...prevFiles,
            [docsType]: prevFiles[docsType]?.map((f) => ({
              ...f,
              percent,
            })),
          }));
        },
      })
      .then((response) => {
        // Reset loading files
        setLoadingFiles({
          reportedDocs: [],
          siteVideos: [],
          otherReportedDocs: [],
        });

        // Process the response and update files state
        const filesWithKeys = newFiles
          .map((file) => {
            const matchingFileData = response?.data?.filter(
              (fileData) =>
                reformatToOriginalUUID(fileData.entityId) == file.uid
            );
            if (matchingFileData?.length) {
              return matchingFileData?.map((fileData) => ({
                ...file,
                fileDataId: fileData.fileDataId,
                fileKey: fileData.fileKey,
              }));
            }
            return file;
          })
          .flat();

        setFiles((prevFiles) => ({
          ...prevFiles,
          [docsType]: [filesWithKeys, ...prevFiles[docsType]].flat(),
        }));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitLoader(false);
      });
  }, 300);

  const handleVideoUpload = (info) => {
    const { fileList } = info;

    setSubmitLoader(true);
    debouncedHandleUploadChange(fileList, "siteVideos");
  };

  const handleReportingDocs = (info) => {
    const { fileList } = info;

    setSubmitLoader(true);
    debouncedHandleUploadChange(fileList, "reportedDocs");
  };

  const handleOtherReportDocs = (info) => {
    const { fileList } = info;

    setSubmitLoader(true);
    debouncedHandleUploadChange(fileList, "otherReportedDocs");
  };

  const handleDeleteSiteVisit = (itemToRemove) => {
    // const newFiles = reportedDocs?.filter(
    //   (item) => item?.uid !== itemToRemove?.uid
    // );
    // setReportedDocs(newFiles);
  };

  const handleDeleteOtherReport = (itemToRemove) => {
    // const newFiles = otherReportedDocs?.filter(
    //   (item) => item?.uid !== itemToRemove?.uid
    // );
    // setOtherReportedDocs(newFiles);
  };

  const handleDeleteSiteVideos = (itemToRemove) => {
    const newFiles = files.siteVideos?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    // setSiteVideos(newFiles);
  };

  const handleDeleteExistingSiteVisit = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        report_documents: prevFiles.report_documents?.filter(
          (item) => item.fileKey !== file.fileKey
        ),
        video_files: prevFiles.video_files,
        otherReportedDocs: prevFiles.otherReportedDocs,
      }));
    }
    const fileKey = file.fileKey;
    setFilesToDelete((prevFiles) => [...prevFiles, fileKey]);
  };
  const handleDeleteExistingOtherReportingDocs = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        otherReportedDocs: prevFiles.otherReportedDocs?.filter(
          (item) => item.fileKey !== file.fileKey
        ),
        report_documents: prevFiles.report_documents,
        video_files: prevFiles.video_files,
      }));
    }

    const fileKey = file.fileKey;
    setFilesToDelete((prevFiles) => [...prevFiles, fileKey]);
  };
  const handleDeleteExistingVideos = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        report_documents: prevFiles.report_documents,
        otherReportedDocs: prevFiles.otherReportedDocs,
        video_files: prevFiles.video_files?.filter(
          (item) => item.fileKey !== file.fileKey
        ),
      }));
    }
    const fileKey = file.fileKey;
    setFilesToDelete((prevFiles) => [...prevFiles, fileKey]);
  };

  const formatSiteVisitStatus = (status) => {
    const statusMap = {
      2: "Pre-planning phase",
      3: "Planning",
      4: "Data Collection",
      5: "Post data collection/Data check",
      6: "Visit Completed",
    };
    return statusMap[status] || "No Status";
  };

  useEffect(() => {
    if (siteVisitCardId && visible && form) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          const enumeratorsData = res?.data?.tracking?.enumerators;
          const enumeratorsProgress = enumeratorsData.reduce(
            (acc, enumerator, index) => {
              acc[`trainingScore-${index}`] = enumerator?.trainingScore || "";
              acc[`percentageCompleted-${index}`] =
                enumerator?.percentageCompleted || "";
              return acc;
            },
            {}
          );
          const hasTrainingScore = res?.data?.tracking?.trainingIsCompleted;
          const hasPercentageCompleted =
            res?.data?.siteVisitStatus == 4 ? true : false;
          setShowScores({
            hasTrainingScore: hasTrainingScore,
            hasPercentageCompleted: hasPercentageCompleted,
          });
          setEnumerators(res?.data?.tracking?.enumerators);
          setSiteVisitId(res?.data?.id);
          const files = res?.data?.siteVisitFiles;
          setExistingFiles({
            report_documents:
              files?.filter((file) => file?.fileCategory == 1) || [],
            video_files:
              files?.filter(
                (file) => file?.fileCategory == 3 || file?.fileCategory == 4
              ) || [],
            otherReportedDocs:
              files?.filter((file) => file?.fileCategory == 5) || [],
          });
          console.log(typeof res?.data?.videoCount); // Check the data type here

          const initialData = {
            ...enumeratorsProgress,

            videosRequired: res?.data?.hasVideos || false,
            videosLimit: res?.data?.videoCount ?? 0,
            photosRequired: res?.data?.hasPhotos || false,
            photosLimit: res?.data?.photoCount ?? 0,
            identifier: res?.data?.identifier,
            actualDateOfVisit: dayjs(res?.data?.tracking?.actualDateOfVisit),
            dateOfReport: dayjs(res?.data?.tracking?.dateOfReport),
            dateOfDebrief: dayjs(res?.data?.tracking?.dateOfDebrief),
            fieldTeamReportingStatus:
              res?.data?.tracking?.fieldTeamReportingStatus,
            percentageOfDataCollectionCompleted:
              res?.data?.tracking?.percentageOfDataCollectionCompleted,
            reportingMonth: res?.data?.tracking?.reportingMonth,
            reportingYear: res?.data?.tracking?.reportingYear,
            siteVisitStatus: formatSiteVisitStatus(res?.data?.siteVisitStatus),
            trainingDate: dayjs(res?.data?.tracking?.trainingDate),
            trainingIsCompleted: res?.data?.tracking?.trainingIsCompleted,
            trainingScoreInfo: res?.data?.tracking?.trainingScoreInfo,
          };
          setRequiredPhotos(res?.data?.hasPhotos || false);
          setRequiredVideos(res?.data?.hasVideos || false);
          form.setFieldsValue(initialData);

          setLoading(false);
        },
        (err) => {
          console.log("Error: ", err);
          setLoading(false);
        }
      );
    }
  }, [visible, form]);

  const closeModal = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        formData.append("Id", siteVisitCardId);

        formData.append("HasVideos", values.videosRequired || false);
        formData.append("VideoCount", values.videosLimit || 0);

        formData.append("HasPhotos", values.photosRequired || false);
        formData.append("PhotoCount", values.photosLimit || 0);

        let index = 0;

        existingFiles?.otherReportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].fileName`, file?.fileName);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.fileSize);
          formData.append(`UpdateFiles[${index}].FileCategory`, 5);
          index++;
        });

        files.otherReportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].fileName`, file?.name);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.size);
          formData.append(`UpdateFiles[${index}].FileCategory`, 5);
          index++;
        });

        existingFiles?.report_documents?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].fileName`, file?.fileName);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.fileSize);
          formData.append(`UpdateFiles[${index}].FileCategory`, 1);
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          index++;
        });

        files.reportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].fileName`, file?.name);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.size);
          formData.append(`UpdateFiles[${index}].FileCategory`, 1);
          index++;
        });

        const existingVideos = [];
        const existingImages = [];

        existingFiles?.video_files?.forEach((file) => {
          if (file?.fileKey?.endsWith(".mp4")) {
            existingVideos.push(file);
          } else {
            existingImages.push(file);
          }
        });

        existingVideos?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].fileName`, file?.fileName);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.fileSize);
          formData.append(`UpdateFiles[${index}].FileCategory`, 3);
          index++;
        });

        existingImages?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].FileCategory`, 4);
          formData.append(`UpdateFiles[${index}].fileName`, file?.fileName);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.fileSize);
          index++;
        });

        const videosUploaded = [];
        const imagesUploaded = [];

        files.siteVideos?.forEach((file) => {
          if (file.type.startsWith("image")) {
            imagesUploaded.push(file);
          } else if (file.type.startsWith("video")) {
            videosUploaded.push(file);
          }
        });

        videosUploaded?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 3);
          formData.append(`UpdateFiles[${index}].fileName`, file?.name);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.size);
          index++;
        });

        imagesUploaded?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 4);
          formData.append(`UpdateFiles[${index}].fileName`, file?.name);
          formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
          formData.append(`UpdateFiles[${index}].fileSize`, file?.size);
          index++;
        });

        httpService.put(
          "/api/Site/updateSiteVisit",
          formData,
          (res) => {
            if (filesToDelete?.length) {
              deleteFilesFromDB(filesToDelete);
            }
            const message =
              res?.message || "You have successfully updated site visit files!";
            setMessage({
              trigger: true,
              type: "success",
              icon: Done,
              title: `Site visit updated successfully!`,
              text: message,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setSubmitLoader(false);
            form.resetFields();
            setFiles({
              reportedDocs: [],
              siteVideos: [],
              otherReportedDocs: [],
            });
            setLoadingFiles({
              reportedDocs: [],
              siteVideos: [],
              otherReportedDocs: [],
            });
            setExistingFiles({
              report_documents: [],
              video_files: [],
              otherReportedDocs: [],
            });
            onCancel();
            onOk();
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "Failed to add files to this site visit. Please try again later!";

            setMessage({
              trigger: true,
              type: "danger",
              icon: exclamationMark,
              title: "Update Failed",
              text: errorMessage,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            setSubmitLoader(false);
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: exclamationMark,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const getAllFileKeys = () => {
    const allFiles = [
      ...files.reportedDocs,
      ...files.siteVideos,
      ...files.otherReportedDocs,
    ];

    const fileKeys = allFiles.map((file) => file.fileKey);

    return fileKeys;
  };

  const deleteFilesFromDB = (fileKeys) => {
    axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      })
      .finally(() => setFilesToDelete([]));
  };

  const handleLeave = () => {
    const fileKeys = getAllFileKeys();
    deleteFilesFromDB(fileKeys);
    form.resetFields();
    setFiles({
      reportedDocs: [],
      siteVideos: [],
      otherReportedDocs: [],
    });
    setLoadingFiles({
      reportedDocs: [],
      siteVideos: [],
      otherReportedDocs: [],
    });
    setExistingFiles({
      report_documents: [],
      video_files: [],
      otherReportedDocs: [],
    });
    onCancel();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handlePhtosLimitChange = (limit) => {
    form.setFieldsValue({ photosLimit: limit });
    setRequiredPhotos(limit > 0);
    form.setFieldsValue({ photosRequired: limit > 0 });
  };

  const handleVideosLimitChange = (limit) => {
    form.setFieldsValue({ videosLimit: limit });
    setRequiredVideos(limit > 0);
    form.setFieldsValue({ videosRequired: limit > 0 });
  };

  const handleVideosRequiredChange = (e) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ videosRequired: isChecked });
    setRequiredVideos(isChecked); // optional, if you still need an external state
  };

  const handlePhotosRequiredChange = (e) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ photosRequired: isChecked });
    setRequiredPhotos(isChecked); // optional, if you still need an external state
  };

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={closeModal}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site Visit Reporting</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button
          loading={submitLoader}
          key="back"
          onClick={closeModal}
          className="inviteButtonTPM"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          Save changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form key={siteVisitCardId} form={form} layout="vertical">
          <div className="d-flex justify-content-between align-items-center gap-4 w-100">
            <div className="indentifierTracking w-100">
              <Form.Item
                className="w-100"
                label="Site Visit Card Identifier"
                name="identifier"
              >
                <Input disabled />
              </Form.Item>
            </div>
            <Button
              className="btnSeeSiteDetails d-flex mt-1 justify-content-center"
              onClick={() => setShowVisitDetails(!showVisitDetails)}
            >
              See site visit card
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 12L10 8L6 4"
                  stroke="#AA1A5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>

          <Divider />

          <h3 className="m-0 mb-4 p-0">Tracking</h3>
          <div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="reportingYear" label="Reporting year">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="reportingMonth" label="Reporting month">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={"Training Completed"}
                  name="trainingIsCompleted"
                  valuePropName="checked"
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="trainingDate" label="Training date">
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8} />
            </Row>

            {showScores?.hasTrainingScore && (
              <div className="d-flex flex-column gap-3 mb-5 mt-4">
                <h3 className="m-0 p-0">Training scores</h3>
                {enumerators?.map((enumerator, index) => (
                  <div
                    key={enumerator?.id}
                    className="d-flex justify-content-between align-items-center w-100"
                    style={{
                      padding: "10px 16px",
                      alignSelf: "stretch",
                      borderRadius: "8px",
                      border: "1px solid var(--5, #F5B0D2)",
                      background: "var(--7, #FFF5FA)",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center h-100 w-100">
                      <p
                        className="m-0"
                        style={{
                          color: "var(--Neutrals-Black, #3A3737)",
                          fontFamily: "Lato",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "22px",
                        }}
                      >
                        {enumerator?.firstName && enumerator?.lastName
                          ? enumerator?.firstName + " " + enumerator?.lastName
                          : enumerator?.email}
                      </p>
                    </div>

                    <Form.Item
                      name={`trainingScore-${index}`}
                      className="m-0 p-0"
                      label="Training score"
                      style={{ width: "178px" }}
                    >
                      <Input
                        type="number"
                        disabled
                        value={enumerator?.trainingScore}
                      />
                    </Form.Item>
                  </div>
                ))}
              </div>
            )}

            {showScores?.hasPercentageCompleted && (
              <Row gutter={24}>
                <Col span={16}>
                  <Form.Item
                    name="actualDateOfVisit"
                    label="Actual date of visit"
                  >
                    <DatePicker style={{ width: "100%" }} disabled />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <div className="d-flex flex-column gap-3 mb-5 mt-4">
              <h3 className="m-0 p-0">% of data collection completed</h3>

              {enumerators?.map((enumerator, index) => (
                <div
                  key={enumerator?.id}
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{
                    padding: "10px 16px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid var(--5, #F5B0D2)",
                    background: "var(--7, #FFF5FA)",
                  }}
                >
                  <div className="d-flex justify-content-start align-items-center h-100 w-100">
                    <p
                      className="m-0"
                      style={{
                        color: "var(--Neutrals-Black, #3A3737)",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                    >
                      {enumerator?.firstName && enumerator?.lastName
                        ? enumerator?.firstName + " " + enumerator?.lastName
                        : enumerator?.email}
                    </p>
                  </div>

                  <Form.Item
                    name={`percentageCompleted-${index}`}
                    className="m-0 p-0"
                    label="% completed"
                    style={{ width: "178px" }}
                  >
                    <Input
                      suffix="%"
                      type="number"
                      disabled
                      value={enumerator?.percentageCompleted}
                    />
                  </Form.Item>
                </div>
              ))}
            </div>

            <Row gutter={24}></Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="dateOfReport" label="Date of report" disabled>
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="dateOfDebrief" label="Date of debrief">
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="siteVisitStatus" label="Site Visit Status">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Preview
              visible={showVisitDetails}
              onClose={() => setShowVisitDetails(false)}
              siteVisitCardId={siteVisitId}
            />
            <Col span={8}>
              <Form.Item
                name="fieldTeamReportingStatus"
                label="Reporting Status"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </div>

          <Divider />

          <Form.Item
            name="report_documents"
            label="Upload site visit report document(s)"
            style={{ fontWeight: "500" }}
          >
            <Tooltip
              placement="top"
              title={
                submitLoader ? "You can not upload files while loading!" : ""
              }
            >
              <Upload.Dragger
                listType="text"
                multiple={true}
                name="report_documents"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                fileList={files.reportedDocs}
                onChange={handleReportingDocs}
                beforeUpload={(file) => handleFileSize(file)}
                disabled={submitLoader}
              >
                <div className="d-flex justify-content-center pb-3">
                  <img src={uploadIcon} alt="" />
                </div>
                <Button className="uploadButton">
                  Drag and drop site visit report document(s) here, or click to
                  select files
                </Button>
                <div className="d-flex justify-content-center bottomText ">
                  <span className="span3">
                    Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
                  </span>
                  <span className="span4">Max file size: 5MB</span>
                </div>
              </Upload.Dragger>
            </Tooltip>
            <div className="uploadsContainer">
              {loadingFiles.reportedDocs?.map((file) => (
                <div className="uploadContentLoading" key={file.uid}>
                  <div className="d-flex flex-row gap-3">
                    <Progress
                      className="uploadIcon"
                      type="circle"
                      width={44}
                      percent={file.percent || 0}
                      format={(percent) =>
                        percent < 100 ? (
                          `${percent}%`
                        ) : (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 48 }} spin />
                            }
                          />
                        )
                      }
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        {file.percent < 100 && (
                          <h1 style={{ color: "red" }} className="loading-text">
                            Loading file's
                            <span className="dots">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </h1>
                        )}
                        {file.percent === 100 && (
                          <h1
                            style={{ color: "green" }}
                            className="loading-text"
                          >
                            Saving the file's to the server
                            <span className="dots">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </h1>
                        )}
                      </div>
                      {file.size && (
                        <p className="m-0 p-0">
                          {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {Array.isArray(files.reportedDocs) &&
                files.reportedDocs?.map((file) => (
                  <div className="uploadContentLoading" key={file.uid}>
                    <div className="d-flex flex-row gap-3">
                      <Progress
                        type="circle"
                        percent={100}
                        className="uploadIcon"
                        width={44}
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row gap-2">
                          <h1 className="loading-text">{file.name}</h1>
                        </div>
                        {file.size && (
                          <p className="m-0 p-0">
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        )}
                      </div>
                    </div>
                    <img
                      onClick={() => handleDeleteSiteVisit(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              {existingFiles?.report_documents?.map((file, index) => (
                <div className="uploadContentLoading" key={file?.fileKey}>
                  <a
                    className="d-flex flex-row gap-3"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                  >
                    <img
                      className="uploadIconExistingFiles"
                      src={uploadIcon}
                      alt={file?.fileName}
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        <h1>{file?.fileName}</h1>
                      </div>
                      <p className="m-0 p-0">
                        {(parseInt(file?.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                        MB
                      </p>
                    </div>
                  </a>
                  <img
                    onClick={() => handleDeleteExistingSiteVisit(file)}
                    src={RemoveIcon}
                    alt="RemoveIcon"
                  />
                </div>
              ))}
            </div>
          </Form.Item>
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              paddingBottom: "20px",
            }}
          >
            <div className="mediaWrapper">
              <div className="info">
                <div className="content">
                  <div className="image">
                    <img src={dangerIcon} alt="danger icon" />
                  </div>
                  <p>
                    <span>Disclosure:</span> prior informed consent is needed.
                  </p>
                </div>
              </div>
              <div className="card">
                <Form.Item name="videosRequired">
                  <Checkbox
                    checked={requiredVideos}
                    onChange={handleVideosRequiredChange}
                  >
                    Videos
                  </Checkbox>
                </Form.Item>

                <Divider className="m-2" />
                <Form.Item
                  name="videosLimit"
                  label="How many?"
                  required={requiredVideos}
                >
                  <InputNumber
                    min={0}
                    onChange={handleVideosLimitChange}
                    disabled={!requiredVideos}
                  />
                </Form.Item>
              </div>
              <div className="card">
                <Form.Item name="photosRequired">
                  <Checkbox
                    checked={requiredPhotos}
                    onChange={handlePhotosRequiredChange}
                  >
                    Photos
                  </Checkbox>
                </Form.Item>

                <Divider className="m-2" />
                <Form.Item
                  name="photosLimit"
                  label="How many?"
                  required={requiredPhotos}
                >
                  <InputNumber
                    onChange={handlePhtosLimitChange}
                    min={0}
                    disabled={!requiredPhotos}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="uploadDivStyle">
            <Form.Item
              name="video_files"
              label="Upload site photo and video file(s)"
              style={{ fontWeight: "500" }}
            >
              <Tooltip
                placement="top"
                title={
                  submitLoader ? "You can not upload files while loading!" : ""
                }
              >
                <Upload.Dragger
                  listType="text"
                  accept=".mp4, .jpg, .jpeg, .png"
                  fileList={files.siteVideos}
                  beforeUpload={(file) => handleFileSize(file)}
                  multiple={true}
                  onChange={handleVideoUpload}
                  disabled={submitLoader}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText">
                    <span className="span3">
                      Allowed formats: .mp4, .jpg, .jpeg, .png
                    </span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload.Dragger>
              </Tooltip>
              <div className="uploadsContainer">
                {loadingFiles.siteVideos?.map((file) => (
                  <div className="uploadContentLoading" key={file.uid}>
                    <div className="d-flex flex-row gap-3">
                      <Progress
                        className="uploadIcon"
                        type="circle"
                        width={44}
                        percent={file.percent || 0}
                        format={(percent) =>
                          percent < 100 ? (
                            `${percent}%`
                          ) : (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 48 }}
                                  spin
                                />
                              }
                            />
                          )
                        }
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row gap-2">
                          {file.percent < 100 && (
                            <h1
                              style={{ color: "red" }}
                              className="loading-text"
                            >
                              Loading file's
                              <span className="dots">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                              </span>
                            </h1>
                          )}
                          {file.percent === 100 && (
                            <h1
                              style={{ color: "green" }}
                              className="loading-text"
                            >
                              Saving the file's to the server
                              <span className="dots">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                              </span>
                            </h1>
                          )}
                        </div>
                        {file.size && (
                          <p className="m-0 p-0">
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {Array?.isArray(files.siteVideos) &&
                  files.siteVideos?.map((file) => (
                    <div className="uploadContentLoading" key={file.uid}>
                      <div className="d-flex flex-row gap-3">
                        <Progress
                          type="circle"
                          percent={100}
                          className="uploadIcon"
                          width={44}
                        />
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row gap-2">
                            <h1 className="loading-text">{file.name}</h1>
                          </div>
                          {file.size && (
                            <p className="m-0 p-0">
                              {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                              MB
                            </p>
                          )}
                        </div>
                      </div>
                      <img
                        onClick={() => handleDeleteSiteVideos(file)}
                        src={RemoveIcon}
                        alt="RemoveIcon"
                      />
                    </div>
                  ))}

                {existingFiles?.video_files?.map((file, index) => (
                  <div className="uploadContentLoading" key={file?.fileKey}>
                    <a
                      className="d-flex flex-row gap-3"
                      type="button"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                    >
                      <img
                        className="uploadIconExistingFiles"
                        src={uploadIcon}
                        alt={file?.fileName}
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row gap-2">
                          <h1>{file?.fileName}</h1>
                        </div>
                        <p className="m-0 p-0">
                          {(parseInt(file?.fileSize) / (1024 * 1024)).toFixed(
                            1
                          )}{" "}
                          MB
                        </p>
                      </div>
                    </a>
                    <img
                      onClick={() => handleDeleteExistingVideos(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </div>

          <Form.Item
            name="otherReportDocs"
            label="Upload other report document(s)"
            style={{ fontWeight: "500" }}
            tooltip={
              submitLoader ? "You can not upload files while loading!" : ""
            }
          >
            <Tooltip
              placement="top"
              title={
                submitLoader ? "You can not upload files while loading!" : ""
              }
            >
              <Upload.Dragger
                listType="text"
                multiple={true}
                name="otherReportDocs"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                fileList={files.otherReportedDocs}
                onChange={handleOtherReportDocs}
                beforeUpload={(file) => handleFileSize(file)}
                disabled={submitLoader}
              >
                <div className="d-flex justify-content-center pb-3">
                  <img src={uploadIcon} alt="" />
                </div>
                <Button className="uploadButton">
                  Drag and drop other report document(s) here, or click to
                  select files
                </Button>
                <div className="d-flex justify-content-center bottomText ">
                  <span className="span3">
                    Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
                  </span>
                  <span className="span4">Max file size: 5MB</span>
                </div>
              </Upload.Dragger>
            </Tooltip>
            <div className="uploadsContainer">
              {loadingFiles.otherReportedDocs?.map((file) => (
                <div className="uploadContentLoading" key={file.uid}>
                  <div className="d-flex flex-row gap-3">
                    <Progress
                      className="uploadIcon"
                      type="circle"
                      width={44}
                      percent={file.percent || 0}
                      format={(percent) =>
                        percent < 100 ? (
                          `${percent}%`
                        ) : (
                          <Spin
                            indicator={
                              <LoadingOutlined style={{ fontSize: 48 }} spin />
                            }
                          />
                        )
                      }
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        {file.percent < 100 && (
                          <h1 style={{ color: "red" }} className="loading-text">
                            Loading file's
                            <span className="dots">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </h1>
                        )}
                        {file.percent === 100 && (
                          <h1
                            style={{ color: "green" }}
                            className="loading-text"
                          >
                            Saving the file's to the server
                            <span className="dots">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </h1>
                        )}
                      </div>
                      {file.size && (
                        <p className="m-0 p-0">
                          {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {Array.isArray(files.otherReportedDocs) &&
                files.otherReportedDocs?.map((file) => (
                  <div className="uploadContentLoading" key={file.uid}>
                    <div className="d-flex flex-row gap-3">
                      <Progress
                        type="circle"
                        percent={100}
                        className="uploadIcon"
                        width={44}
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row gap-2">
                          <h1 className="loading-text">{file.name}</h1>
                        </div>
                        {file.size && (
                          <p className="m-0 p-0">
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        )}
                      </div>
                    </div>
                    <img
                      onClick={() => handleDeleteOtherReport(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              {existingFiles?.otherReportedDocs?.map((file, index) => (
                <div className="uploadContentLoading" key={file?.fileKey}>
                  <a
                    className="d-flex flex-row gap-3"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                  >
                    <img
                      className="uploadIconExistingFiles"
                      src={uploadIcon}
                      alt={file?.fileName}
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        <h1>{file?.fileName}</h1>
                      </div>
                      <p className="m-0 p-0">
                        {(parseInt(file?.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                        MB
                      </p>
                    </div>
                  </a>
                  <img
                    onClick={() => handleDeleteExistingOtherReportingDocs(file)}
                    src={RemoveIcon}
                    alt="RemoveIcon"
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default Edit;
