import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Spin, Form, message } from "antd";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationIcon from "../../../../Assets/svg/exclamationMark.svg";

const EditDataset = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (visible && record) {
      setLoading(true);
      httpService.get(
        `/api/Site/getSiteVisit?id=${record.id}`,
        (res) => {
          setLoading(false);
          form.setFieldsValue({
            fulcrumAccount: res?.data?.logistic?.fulcrumAccount || "",
            siteVisitId: res?.data?.identifier || "",
          });
        },
        (error) => {
          console.error("Fetch Error:", error);
          setLoading(false);
        }
      );
    }
  }, [visible, record, form]);

  const validateURL = (_, value) => {
    const regex = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Please enter a valid link (https, http, or www).")
    );
  };

  const handleSubmit = async () => {
    setSubmitting(true); // Show loader when submitting

    await form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        formData.append("Id", record.id);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);

        httpService.put(
          "/api/Site/updateSiteVisit",
          formData,
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              icon: Done,
              title: "Site visit card updated successfully!",
              text: "You have successfully modified the site visit.",
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            form.resetFields();
            setSubmitting(false);
            onSubmit();
            onClose();
          },
          (error) => {
            let errorMessage =
              error?.errors?.[0]?.message ||
              "There was a problem updating this site visit card!";

            setMessage({
              trigger: true,
              type: "danger",
              icon: ExclamationIcon,
              title: "Failed to update this site visit card",
              text: errorMessage,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            setSubmitting(false); // Hide loader after failure
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Submission Failed:", errorInfo);

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Failed to update this site visit card",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);

        setSubmitting(false); // Hide loader on form validation error
      });
  };

  const handleOpenPreviewModal = () => {
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={660}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Data Collection Results</h2>
      <Spin spinning={loading || submitting}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item label="Site Visit Card Identifier">
            <div
              className="dataCollectionMain"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Input
                value={form.getFieldValue("siteVisitId")}
                disabled
                style={{ flex: 1, marginRight: 8 }}
              />
              <Button
                className="dataCollectionButton"
                onClick={handleOpenPreviewModal}
              >
                See site visit card
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            label="Link to Data Collection Results"
            name="fulcrumAccount"
            rules={[
              {
                required: true,
                message: "Link to Data Collection Results is required.",
              },
              { validator: validateURL },
            ]}
          >
            <Input placeholder="Enter Link to Data Collection Results URL" />
          </Form.Item>

          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              htmlType="submit"
              loading={submitting}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </Spin>
      <Preview
        visible={showPreviewModal}
        onClose={handleClosePreviewModal}
        siteVisitCardId={record?.id}
      />
    </Modal>
  );
};

export default EditDataset;
