import React, { useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../Filters";
import { Pagination, Spin } from "antd";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import Preview from "../TableView/modals/Preview";
import { formatDate } from "../../../../../services/helpFunctions/formatDateAndTime";

const KanbanView = ({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  onPageChange,
  pagination,
  setPagination,
  onOk,
  filters,
  setFilters,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();
  const [inputSearch, setInputSearch] = useState(filters.Search || "");

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const projectID = localStorage.getItem("currentProjectID");

  useEffect(() => {
    fetchSiteVisitCards();
  }, [filters]);

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  console.log("filters:", filters);
  console.log("inputSearch:", inputSearch);

  const columns = [
    {
      id: 2,
      title: "Pre-planning phase",
      color: "var(--Primary-Blueberry-700, #325899)",
    },
    { id: 3, title: "Planning", color: "#964B00" },
    // { id: 4, title: "Data collection", color: "#3B81FE" },
    // { id: 5, title: "Post data collection/Data check", color: "#045B46" },
    // { id: 6, title: "Visit completed", color: "#0F7507" },
  ];

  // format data for Kanban View
  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: formatDate(siteVisitCard?.createdDate),
        ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      column: siteVisitCard?.siteVisitStatus,
    };
  };

  const formattedData = siteVisitCards.map(formatData);

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />

      <Filters
        filters={filters || {}}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        onFiltersChange={handleFiltersChange}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
      />
      <Spin spinning={loading}>
        <div id="excludedStrictMode">
          <KanbanBoardComponent
            tasks={formattedData}
            columns={columns}
            changeStatus={false}
            setClickedTaskId={handleTaskClick}
          />
        </div>
      </Spin>
      <div className="d-flex justify-content-end my-5">
        <Spin spinning={loading}>
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) => {
              onPageChange(page, pageSize);
              setPagination({ ...pagination, current: page, pageSize });
            }}
          />
        </Spin>
      </div>
    </>
  );
};

export default KanbanView;
