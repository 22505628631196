import React, { useState } from "react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { httpService } from "../../../services/httpService.service";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import Done from "../../../Assets/svg/done.svg";
import ExclamationMark from "../../../Assets/svg/exclamationMark.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleForgotPassword = async (event) => {
    setMessage({
      trigger: true,
      type: "success",
      icon: Done,
      title: `Please wait!`,
      text: "A new confirmation code is being sent to your email...",
    });
    setTimeout(() => {
      setMessage({
        trigger: false,
      });
    }, 3000);
    event.preventDefault();
    setLoadingPage(true);
    if (!email) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Oops! A problem has occurred!",
        text: "Please enter your email address.",
      });

      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      return;
    }

    httpService.get(
      `/api/account/resendCode?email=${email}`,
      (response) => {
        const sucMessage =
          response.message || "Verification code sent to your email.";
        // message.success(sucMessage);
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: `Verification code sent!`,
          text: sucMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);

        setLoadingPage(false);
        navigate("/forgotPassword/code", { state: { email } });
      },
      (error) => {
        const errorMessage = error.response?.data?.errors[0]?.message;
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);

        setLoadingPage(false);
      }
    );
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <Spin spinning={loadingPage}>
              <form onSubmit={handleForgotPassword}>
                <h2>Forgot Password?</h2>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    * Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  loading={loadingPage}
                  disabled={loadingPage}
                >
                  Submit
                </button>
              </form>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
