import React, { useState } from "react";
import { Modal, Button, Select, Checkbox, Spin, message } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";
import "./export.scss";
import Done from "../../../Assets/svg/done.svg";
import ExclamationMark from "../../../Assets/svg/exclamationMark.svg";
import MessagePopUp from "../../components/Messages/MessagePopUp"

const { Option } = Select;

const ExportModal = ({ visible, onCancel }) => {
  const [exportFormat, setExportFormat] = useState("excel");
  const [includeMedia, setIncludeMedia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleMediaCheckboxChange = (e) => {
    setIncludeMedia(e.target.checked);
  };

  const handleFormatChange = (value) => {
    setExportFormat(value);
  };

  const projectId = localStorage.getItem("currentProjectID");

  const handleExportClick = () => {
    setLoading(true);
    const requestBody = {
      projectId,
      includeFiles: includeMedia,
    };

    httpService.post(
      "/api/Site/exportSiteVisits",
      requestBody,
      (response) => {
        const message = response.message;
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Export successfully!",
          text: message || "There is problem",
        });
        onCancel();
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "There was a problem adding this contract to the database. Please try again later!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: `Oops! A problem has occurred!`,
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Modal
        visible={visible}
        closable={false}
        footer={false}
        width={600}
        onCancel={onCancel}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={cancelX}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={onCancel}
          />
        </div>
        <h2 className="modalHeader">Export Options</h2>
        <div className="selectFileType">
          <span>Select Export Format: </span>
          <Select
            value={exportFormat}
            onChange={handleFormatChange}
            style={{ width: "100%" }}
          >
            <Option value="excel">Excel</Option>
          </Select>
          <Checkbox
            style={{ marginTop: 16 }}
            checked={includeMedia}
            onChange={handleMediaCheckboxChange}
          >
            Include all media files
          </Checkbox>
          <div className="exportNote">
            Note: "All media files" refers to all the media included in your
            site visit cards.
          </div>
        </div>
        <div className="buttonsModal" style={{ marginTop: "32px" }}>
          <Button className="inviteButtonTPM" key="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleExportClick}
            disabled={loading}
          >
            {loading ? <Spin size="small" /> : "Export"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ExportModal;
