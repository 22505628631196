import React, { useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../Filters";
import { httpService } from "../../../../../services/httpService.service";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";
import { Pagination, Spin } from "antd";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import Done from "../../../../../Assets/svg/done.svg";
import { formatDateAndTime } from "../../../../../services/helpFunctions/formatDateAndTime";

const KanbanView = ({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  setSiteVisitCards,
  pagination,
  setPagination,
  filters,
  setFilters,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const changeSiteVisitCardStatus = (draggedTask) => {
    setLoading(true);
    const draggedSiteVisit = siteVisitCards?.filter(
      (siteVisitCard) => siteVisitCard?.id === draggedTask?.id
    );
    if (!draggedSiteVisit || draggedSiteVisit.length === 0) {
      console.error("No matching site visit card found");
      return;
    }

    const trackingId = draggedSiteVisit[0]?.tracking?.id;
    const fieldTeamReportingStatus = Number(draggedTask.column);

    if (!trackingId || isNaN(fieldTeamReportingStatus)) {
      console.error("Invalid trackingId or fieldTeamReportingStatus", {
        trackingId,
        fieldTeamReportingStatus,
      });
      return;
    }

    const updatedSiteVisitCards = siteVisitCards.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          tracking: {
            ...card.tracking,
            fieldTeamReportingStatus: fieldTeamReportingStatus,
          },
        };
      }
      return card;
    });

    setSiteVisitCards(updatedSiteVisitCards);

    const url = `/api/Site/changeFieldTeamReportingStatus?TrackingId=${trackingId}&FieldTeamReportingStatus=${fieldTeamReportingStatus}`;

    console.log("Request URL:", url);

    httpService.put(
      url,
      "",
      (response) => {
        console.log("Response:", response);
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Status updated successfully!",
          text: "Red flag status was correctly updated to your database.",
        });
        fetchSiteVisitCards();
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem updating red flag status, please try again.";
        console.error("Error:", error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: exclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setLoading(false);
      }
    );

    setTimeout(() => {
      setMessage({
        trigger: false,
      });
    }, 3000);
  };

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  const columns = [
    { id: 1, title: "Data Collection", color: "#3B81FE" },
    { id: 2, title: "Data Check", color: "#045B46" },
    { id: 3, title: "Field Report Writing", color: "#3D9B4C" },
    { id: 4, title: "Translation", color: "#918d27" },
    { id: 5, title: "Field Reporting Completed", color: "#9670b8" },
    // { id: 6, title: "Field Report Approved", color: "#06768E" },
    // { id: 7, title: "Field Report Submitted to Client", color: "#6FC5D5" },
  ];

  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: formatDateAndTime(siteVisitCard?.createdDate),
        ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      column: siteVisitCard?.tracking?.fieldTeamReportingStatus,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  return (
    <>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
      <Spin spinning={loading}>
        <Filters filters={filters} setFilters={setFilters} />
        <div id="excludedStrictMode">
          <KanbanBoardComponent
            tasks={formattedData}
            columns={columns}
            changeStatus={changeSiteVisitCardStatus}
            setClickedTaskId={handleTaskClick}
          />
        </div>
        <div className="d-flex justify-content-end my-5">
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.totalItems}
            onChange={(page, pageSize) => {
              setPagination((prevPagination) => ({
                ...prevPagination,
                current: page,
                pageSize: pageSize,
              }));
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default KanbanView;
