import React, { useEffect, useState } from "react";
import "../Implementation.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import TableView from "./TableView/TableView";
import TrackingModal from "../../planning/VisitBoard/Modals/AddTrackingCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import ExportSelectedTable from "../../../components/ExportExcel/ExportSelectedExcel/exportSelectedTable";

function VisitBoard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [siteVisitCardsUntracked, setSiteVisitCardsUntracked] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [selectedSiteVisitIds, setSelectedSiteVisitIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [paginationUntracked, setPaginationUntracked] = useState({
    pageSize: 10,
    current: 1,
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [currentView, setCurrentView] = useState("1");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: [],
    State: [],
    SiteVisitStatus: [],
    ClusterId: [],
    Month: [],
    Year: [],
    Governates: [],
    Cities: [],
    IgnorePagination: false,
  });

  const [filtersUntracked, setFiltersUntracked] = useState({
    Search: "",
    ImplementingPartnerId: [],
    State: [],
    SiteVisitStatus: [],
    ClusterId: [],
    Month: [],
    Year: [],
    Governates: [],
    Cities: [],
    IgnorePagination: false,
  });
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const handleRowSelectionChange = (selectedIds) => {
    setSelectedSiteVisitIds(selectedIds);
  };

  const handleModalCancel = () => {
    setIsExportModalVisible(false);
  };

  const handleExportClick = () => {
    setIsExportModalVisible(true); // Show modal when export button is clicked
  };

  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards();
    fetchSiteVisitCardsUntracked();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const onChange = (key) => {
    setCurrentView(key);
  };

  useEffect(() => {
    fetchSiteVisitCards();
  }, [currentView]);

  const fetchSiteVisitCards = async () => {
    setLoading(true);

    const params = {
      ProjectId: projectID,
      ...(currentView === "1" ? { includeOnlyWithTracking: true } : {}),
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      Search: filters.Search,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters?.SiteVisitStatus?.length
        ? filters?.SiteVisitStatus
        : currentView == "1"
        ? [4, 5]
        : [],
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      Governates: filters.Governates,
      Cities: filters.Cities,
      IgnorePagination: currentView == "3" ? true : false,
    };
    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();

    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const siteVisitCardsData = response?.data;
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        if (siteVisitCardsData.length > 0) {
          setSiteVisitCards(siteVisitCardsData);
        } else {
          setSiteVisitCards([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const fetchSiteVisitCardsUntracked = async () => {
    setLoading(true);
    const params = {
      ProjectId: projectID,
      includeOnlyWithTracking: false,
      pageNumber: paginationUntracked.current,
      pageSize: paginationUntracked.pageSize,
      Search: filtersUntracked.Search,
      ImplementingPartnerId: filtersUntracked.ImplementingPartnerId,
      State: filtersUntracked.State,
      SiteVisitStatus: filtersUntracked.SiteVisitStatus?.length
        ? filtersUntracked.SiteVisitStatus
        : [2, 3],
      ClusterId: filtersUntracked.ClusterId,
      Month: filtersUntracked.Month,
      Year: filtersUntracked.Year,
      Governates: filtersUntracked.Governates,
      Cities: filtersUntracked.Cities,
    };

    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();

    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const siteVisitCardsData = response?.data;
        setPaginationUntracked({
          ...paginationUntracked,
          total: response.metaData.totalItemCount,
        });
        if (siteVisitCardsData.length > 0) {
          setSiteVisitCardsUntracked(siteVisitCardsData);
        } else {
          setSiteVisitCardsUntracked([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onPageChangeUntracked = (pageNumber, pageSize) => {
    setPaginationUntracked({
      ...paginationUntracked,
      current: pageNumber,
      pageSize,
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const handleFilterChangeUntracked = (key, value) => {
    setFiltersUntracked({
      ...filtersUntracked,
      [key]: value,
    });
    setPaginationUntracked({ ...paginationUntracked, current: 1 });
  };

  const items = [
    {
      key: "1",
      label: "Table View",
      children: (
        <>
          <TableView
            onOk={() => handleOk()}
            tracked={false}
            siteVisitCards={siteVisitCardsUntracked}
            fetchSiteVisitCards={fetchSiteVisitCardsUntracked}
            loading={loading}
            setLoading={setLoading}
            setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
            onPageChange={onPageChangeUntracked}
            pagination={paginationUntracked}
            setPagination={setPaginationUntracked}
            handleFilterChange={handleFilterChangeUntracked}
            filters={filtersUntracked}
            setFilters={setFiltersUntracked}
            onRowSelectionChange={handleRowSelectionChange}
          />

          <TableView
            onOk={() => handleOk()}
            tracked={true}
            siteVisitCards={siteVisitCards}
            fetchSiteVisitCards={fetchSiteVisitCards}
            loading={loading}
            setLoading={setLoading}
            setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
            onPageChange={onPageChange}
            pagination={pagination}
            setPagination={setPagination}
            handleFilterChange={handleFilterChange}
            filters={filters}
            setFilters={setFilters}
            onRowSelectionChange={handleRowSelectionChange}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Kanban View",
      children: (
        <KanbanView
          siteVisitCards={siteVisitCards}
          setSiteVisitCards={setSiteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar View",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <span className="tableViewHeaderText">Visits Board</span>
        <div className="tableViewHeaderButtons">
          {/* <Button
            className="inviteButtonTPM"
            onClick={showDeletedModal}
          >
            <img src={DeleteButton} alt="DeleteButton"/>
            See deleted cards
          </Button> */}
          <Button
            className={`addButtonTPM ${currentView != "1" ? "d-none" : ""}`}
            onClick={handleExportClick}
          >
            <img src={ExportButton} alt="ExportButton" />
            Export Selected Site Visits
          </Button>
          {/* <Button
            className="addButtonTPM" onClick={showModal}
          >
            <img src={AddButton} alt="AddButton"/>
            Add New Site Visit Card
          </Button> */}
          <TrackingModal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          />
          <DeletedModal
            visible={isDeletedModalVisible}
            onOk={showDeletedModal}
            onCancel={() => {
              setIsDeletedModalVisible(false);
            }}
            fetchSiteVisitCards={fetchSiteVisitCards}
          />
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey={currentView}
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
      <ExportSelectedTable
        visible={isExportModalVisible}
        onCancel={handleModalCancel}
        selectedSiteVisitIds={selectedSiteVisitIds}
      />
    </div>
  );
}

export default VisitBoard;
