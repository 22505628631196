import React, { useState } from "react";
import RightArrow from "../../../../../Assets/svg/rightArrow.svg";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";

const VisitCards = ({ cards }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  const handleSeeMore = (siteVisitId) => {
    if (siteVisitId) {
      setClickedTaskId(siteVisitId);
      setShowPreviewModal(true);
    }
  };

  return (
    <div className="recentVisitCardsWrapper">
      {cards?.length < 1 ? (
        <div style={{ width: "100%", height: "200px", textAlign: "center" }}>
          <p>No cards available</p>
        </div>
      ) : (
        cards?.map((task) => (
          <div key={task.id} className="visitCard">
            <h1>{task.name}</h1>
            <p>
              Last Update:{" "}
              <span>
                {new Date(task.updatedDate).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </span>
            </p>

            <p>
              IP: <span>{task.ipName}</span>
            </p>
            <p>
              State: <span>{task.state}</span>
            </p>
            <p>
              Municipality: <span>{task.municipality}</span>
            </p>
            <div className="buttonWrapper">
              <button onClick={() => handleSeeMore(task.id)}>
                See more
                <img src={RightArrow} />
              </button>
            </div>
          </div>
        ))
      )}
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
    </div>
  );
};

export default VisitCards;
