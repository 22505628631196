export const formatDateAndTime = (isoString) => {
  const date = new Date(isoString);

  if (isNaN(date)) {
    return "Invalid date";
  }
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

  return `${formattedDate}, ${formattedTime}`;
};

export const formatDate = (isoString) => {
  const date = new Date(isoString);

  if (isNaN(date)) {
    return "Invalid date";
  }
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};
