export function getRelativeTime(isoTimestamp) {
  const cleanedTimestamp = isoTimestamp.split(".")[0].replace("Z", "");

  const timestamp = new Date(cleanedTimestamp);
  if (isNaN(timestamp)) {
    return "Invalid date";
  }

  const now = new Date();
  const deltaSeconds = Math.floor((now - timestamp) / 1000);
  const deltaMinutes = Math.floor(deltaSeconds / 60);
  const deltaHours = Math.floor(deltaMinutes / 60);
  const deltaDays = Math.floor(deltaHours / 24);
  const deltaWeeks = Math.floor(deltaDays / 7);
  const deltaMonths = Math.floor(deltaDays / 30);
  const deltaYears = Math.floor(deltaDays / 365);

  if (deltaSeconds < 60) {
    return deltaSeconds === 1 ? "1 second ago" : `${deltaSeconds} seconds ago`;
  } else if (deltaMinutes < 60) {
    return deltaMinutes === 1 ? "1 minute ago" : `${deltaMinutes} minutes ago`;
  } else if (deltaHours < 24) {
    return deltaHours === 1 ? "1 hour ago" : `${deltaHours} hours ago`;
  } else if (deltaDays === 1) {
    return "yesterday";
  } else if (deltaDays < 7) {
    return `${deltaDays} days ago`;
  } else if (deltaWeeks === 1) {
    return "last week";
  } else if (deltaWeeks < 4) {
    return `${deltaWeeks} weeks ago`;
  } else if (deltaMonths === 1) {
    return "last month";
  } else if (deltaMonths < 12) {
    return `${deltaMonths} months ago`;
  } else if (deltaYears === 1) {
    return "last year";
  } else {
    return `${deltaYears} years ago`;
  }
}
