import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Typography,
  Spin,
  Tooltip,
  Select,
} from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import { useEffect, useState } from "react";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";
import { useSiteVisitStore } from "./store/siteVisitStore";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import Done from "../../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import dayjs from "dayjs";
import axios from "axios";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
const { Option } = Select;

const { TextArea } = Input;

const { Title } = Typography;

const EditDraftVisits = ({
  visible,
  onCancel,
  onOk,
  siteVisitCardId,
  setMessage,
}) => {
  const [form] = Form.useForm();
  const [siteContactsRequired, setSiteContactsRequired] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [clusters, setClusters] = useState([]);
  const [singleTeam, setSingleTeam] = useState({});
  const [allIPPlans, setAllIPPlans] = useState([]);
  const [postponed, setPostponed] = useState(false);
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [observationList, setObservationList] = useState(false);
  const [selectedIpPlan, setSelectedIpPlan] = useState([]);
  const [showHideIpPlanDetails, setShowHideIpPlanDetails] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [parishCheck, setParishCheck] = useState(false);
  const [address, setAddress] = useState("");
  const { setOtherDocuments } = useSiteVisitStore();
  const [filesToSave, setFilesToSave] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);

  const [SiteLoader, setSiteLoader] = useState(false);
  const [kills, setKills] = useState(0);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const deleteFilesFromDB = (fileKeys) => {
    axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      })
      .finally(() => setFilesToDelete([]));
  };

  const handleLeave = () => {
    onCancel();
    const inputFiles = form.getFieldValue("other_files");
    const fileKeys = inputFiles?.map((file) => file.fileKey) || [];
    deleteFilesFromDB(fileKeys);
    form.resetFields();
    setOtherDocuments([]);
    setSingleTeam(null);
    setObservationList(false);
    setKills(0);
    setClusters([]);
    setSectors([]);
    setSelectedIpPlan("");
    setShowHideIpPlanDetails(false);
    setAddress("");
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    if (visible && siteVisitCardId && form) {
      setSiteLoader(true);
      httpService.get(
        `/api/Draft/getDraftById?id=${siteVisitCardId}`,
        (res) => {
          setSiteLoader(false);

          const siteVisitCard = JSON.parse(res?.data?.jsonData);
          console.log("siteVisitCard", siteVisitCard);

          const KIIContacts = siteVisitCard?.Visit?.KIIcontacts || [];
          const pointContacts = siteVisitCard?.Visit?.pointContacts[0] || [];
          const siteContacts = siteVisitCard?.Visit?.siteContacts[0] || null;

          const newSiteContactsRequired = siteContacts !== null;
          const parishChecked =
            siteVisitCard?.parish === "false" ? false : true;
          setParishCheck(parishChecked);

          const ipPlanId = siteVisitCard?.IPPlan?.[0]?.id || null;
          const initialData = {
            name: siteVisitCard.name,
            IPlaningCard: ipPlanId,
            cluster: siteVisitCard?.Cluster?.[0]?.id || null,
            sector: siteVisitCard?.SectorId || null,
            subSector: siteVisitCard?.SubSectorId || null,
            projectId: projectId,
            latitude: siteVisitCard?.LocationDetails?.Latitude || null,
            longitude: siteVisitCard?.LocationDetails?.Longitude || null,
            isVirtual: siteVisitCard?.LocationDetails?.isVirtual || null,
            dateOfPlannedVisit: dayjs(siteVisitCard?.plannedVisit || undefined),

            siteVisitStatus: siteVisitCard?.SiteVisitStatus,
            parishCheck: parishChecked,
            parish:
              siteVisitCard?.parish !== "false"
                ? siteVisitCard?.parish
                : undefined,
            observationChecklistType:
              siteVisitCard?.Visit?.observationChecklistType,
            ObservationListCheck:
              siteVisitCard?.Visit?.ObservationListCheck || false,
            beneficiariesCount:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.BeneficiariesCount || 0,
            kills: KIIContacts?.length || 0,
            observationChecklistCount:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.ObservationChecklist || 0,
            // mediaLimit:
            //   siteVisitCard?.Visit?.CreateInterviewDetails?.MediaLimit || 0,
            samplingInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.SamplingInstructions || "",
            clientInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.ClientInstructions || "",
            otherInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails?.OtherInstructions ||
              "",
            visitPlanApproval:
              siteVisitCard?.Visit?.VisitPlanApproval || undefined,

            specificsOfOnSiteOperation:
              siteVisitCard?.SpecificsOfOnSiteOperation || "",

            tpmCoordinator: siteVisitCard?.Logistic?.TPMCoordinatorId || null,
            coordinationType:
              siteVisitCard?.Logistic?.CoordinationType || undefined,
            fulcrumAccount: siteVisitCard?.Logistic?.FulcrumAccount || "",
            activationDate: siteVisitCard?.Logistic?.ActivationDate || null,
            isTeamLocal: siteVisitCard?.Logistic?.IsTeamLocal || false,

            team: siteVisitCard?.Logistic?.TeamId || null,
            isPostponed: siteVisitCard?.Logistic?.IsPostponed || false,
            postponedReason: siteVisitCard?.Logistic?.PostponedReason || "",
            postponedDetails: siteVisitCard?.Logistic?.PostponedDetails || "",
            notes: siteVisitCard?.Logistic?.Notes || "",

            KIIcontacts: KIIContacts,
            pointContacts: pointContacts,
            siteContacts: siteContacts,

            siteContactsRequired: newSiteContactsRequired,
          };
          if (ipPlanId != null) {
            fetchIpPlanDetails(ipPlanId);
            setSelectedIpPlan(ipPlanId);
          }

          siteVisitCard?.Cluster?.[0]?.id &&
            fetchSectorData(siteVisitCard?.Cluster?.[0]?.id);
          setKills(KIIContacts?.length);
          setObservationList(
            siteVisitCard?.Visit?.ObservationListCheck || false
          );
          setSiteContactsRequired(newSiteContactsRequired);
          setPostponed(siteVisitCard?.Logistic?.IsPostponed);
          setSelectedSectorId(siteVisitCard?.SectorId);

          const teamId = siteVisitCard?.Logistic?.TeamId;

          if (teamId) {
            httpService.get(
              `/api/User/getTeamById?teamId=${teamId}`,
              (res) => {
                setSingleTeam(res?.data);
              },
              (err) => {
                console.log(err);
              }
            );
          }
          const filesData = siteVisitCard?.files || [];
          setExistingFiles(filesData);

          setInitialValues(initialData);
          form.setFieldsValue(initialData);
        },
        (err) => {
          setSiteLoader(false);
          console.log(err);
        }
      );
    }
  }, [visible]);
  const fetchIpPlanDetails = (ipPlanId) => {
    if (ipPlanId) {
      const apiIpPlan = `/api/IPPlan/getIPPlan?id=${ipPlanId}`;

      httpService.get(
        apiIpPlan,
        (res) => {
          setSelectedIpPlan(res.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  const fetchSectorData = async (clusterId) => {
    httpService.get(
      "/api/Site/getAllSectorsByCluster?clusterId=" + clusterId,
      (res) => setSectors(res.data),
      (error) => console.error("Error fetching data:", error)
    );
  };

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = moment(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const handleSaveProgress = () => {
    setSubmitLoader(true);

    const values = form.getFieldsValue();
    const newFiles = values.other_files || [];
    const newFilesData = newFiles.map((file) => {
      return {
        fileName: file.name,
        fileKey: file.fileKey,
        fileSize: file.size,
        FileCategory: 2,
      };
    });
    const files = [...newFilesData, ...existingFiles];

    const selectedCluster = clusters?.filter(
      (cluster) => cluster.id === values.cluster
    );

    const selectedIpPlan = allIPPlans.filter(
      (ipPlan) => ipPlan.id === values.IPlaningCard
    );

    let siteContacts = [values.siteContacts] || [];
    let pointContacts = [values.pointContacts] || [];
    let KIIcontacts = values.KIIcontacts || [];

    siteContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 1;
      }
    });
    pointContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 2;
      }
    });
    KIIcontacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 3;
      }
    });

    setKills(KIIcontacts?.length);

    const siteVisitDraft = {
      ProjectId: projectId,
      name: values.name,
      plannedVisit: formatDate(values.dateOfPlannedVisit),
      trainingDate: formatDate(values.trainingDate),
      actualDateOfVisit: formatDate(values.actualDateOfVisit),
      activationDate: formatDate(values.dateOfPlannedVisit, "activation"),
      dateOfReport: formatDate(values.dateOfReport),
      dateOfDebrief: formatDate(values.dateOfDebrief),
      IPPlan: selectedIpPlan,
      Cluster: selectedCluster,
      SectorId: values.sector,
      SubSectorId: values.subSector,
      LocationDetails: {
        Latitude: values.latitude,
        Longitude: values.longitude,
        isVirtual: values.isVirtual || false,
      },
      parish: values?.parish || "false",
      SiteVisitStatus: values?.siteVisitStatus,
      Visit: {
        PlannedVisit: values.plannedVisit,
        siteContacts: siteContacts,
        pointContacts: pointContacts,
        KIIcontacts: KIIcontacts,
        ObservationListCheck: values.ObservationListCheck,
        observationChecklistType: values.observationChecklistType,
        CreateInterviewDetails: {
          BeneficiariesCount: values.beneficiariesCount,
          ClientsCount: values.ClientsCount,
          ObservationChecklist: values.observationChecklistCount,
          MediaLimit: values.MediaLimit,
          SamplingInstructions: values.samplingInstructions,
          ClientInstructions: values.clientInstructions,
          OtherInstructions: values.otherInstructions,
        },
        VisitPlanApproval: values.visitPlanApproval,
      },
      SpecificsOfOnSiteOperation: values.specificsOfOnSiteOperation,
      ObservationList: values.observationList,
      Logistic: {
        TPMCoordinatorId: values.tpmCoordinator,
        CoordinationType: values.coordinationType,
        FulcrumAccount: values.fulcrumAccount,
        ActivationDate: values.activationDate,
        IsTeamLocal: values.isTeamLocal,
        TeamId: values.team,
        IsPostponed: values.isPostponed,
        PostponedReason: values.postponedReason,
        PostponedDetails: values.postponedDetails,
        Notes: values.notes,
      },
      ...(files && {
        files: files,
      }),
    };

    const jsonData = JSON.stringify(siteVisitDraft);

    const params = {
      id: siteVisitCardId,
      projectId: projectId,
      jsonData: jsonData,
      type: 1,
    };

    httpService.post(
      "/api/Draft/createOrEditDraft",
      params,
      (res) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Draft site visit card created successfully!",
          text: "A new draft site visit card has been created and saved into the database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setSubmitLoader(false);
        setOtherDocuments([]);
        setFilesToSave([]);
        if (filesToDelete?.length) {
          deleteFilesFromDB(filesToDelete);
        }
        form.resetFields();
        onOk();
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to create draft site visit. Please try again later.";
        console.error("There was an error adding the project!", error);
        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      }
    );
  };

  // const handleSaveProgress = async () => {
  //   setSubmitLoader(true);

  //   const values = form.getFieldsValue();

  //   const allFieldsEmpty = Object.values(values).every((value) => {
  //     if (Array.isArray(value)) {
  //       return value?.length === 0;
  //     }
  //     return !value;
  //   });

  //   if (allFieldsEmpty) {
  //     setMessage({
  //       trigger: true,
  //       type: "danger",
  //       icon: ExclamationMark,
  //       title: "Validation Error",
  //       text: "You cannot save this site visit card as a draft if all fields are empty.",
  //     });
  //     setTimeout(() => {
  //       setMessage({ trigger: false });
  //     }, 1000);
  //     setSubmitLoader(false);
  //     return;
  //   }

  //   const reportedDocuments = values.report_documents;
  //   const otherFiles = values.other_files;
  //   const videoFiles = values.video_files;

  //   if (
  //     reportedDocuments?.length > 0 ||
  //     otherFiles?.length > 0 ||
  //     videoFiles?.length > 0
  //   ) {
  //     const formData = new FormData();

  //     formData.append("FolderName", "TPM");
  //     formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

  //     reportedDocuments?.forEach((file, index) => {
  //       formData.append(
  //         `entityFormFileDtos[${index}].entityId`,
  //         "3fa85f64-5717-4562-b3fc-2c963f611111"
  //       );
  //       formData.append(
  //         `entityFormFileDtos[${index}].formFile`,
  //         file.originFileObj
  //       );
  //     });

  //     otherFiles?.forEach((file, index) => {
  //       const filesLength = reportedDocuments?.length;
  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].entityId`,
  //         "3fa85f64-5717-4562-b3fc-2c963f622222"
  //       );
  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].formFile`,
  //         file.originFileObj
  //       );
  //     });

  //     const videosUploaded = [];
  //     const imagesUploaded = [];
  //     videoFiles?.forEach((file) => {
  //       if (file.type.startsWith("image")) {
  //         imagesUploaded.push(file);
  //       } else if (file.type.startsWith("video")) {
  //         videosUploaded.push(file);
  //       }
  //     });
  //     videosUploaded?.forEach((file, index) => {
  //       const filesLength =
  //         (reportedDocuments?.length || 0) + (otherFiles?.length || 0);

  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].entityId`,
  //         "3fa85f64-5717-4562-b3fc-2c963f633333"
  //       );
  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].formFile`,
  //         file.originFileObj
  //       );
  //     });

  //     imagesUploaded?.forEach((file, index) => {
  //       const filesLength =
  //         videosUploaded?.length +
  //         (reportedDocuments?.length || 0) +
  //         (otherFiles?.length || 0);

  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].entityId`,
  //         "3fa85f64-5717-4562-b3fc-2c963f644444"
  //       );
  //       formData.append(
  //         `entityFormFileDtos[${filesLength + index}].formFile`,
  //         file.originFileObj
  //       );
  //     });

  //     axios
  //       .post(
  //         "https://kapfilemanager.kapsurvey.com/FileData/addFile",
  //         formData,
  //         {
  //           headers: {
  //             Accept: "text/plain",
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const filesData = response.data.map((fileData) => fileData);
  //         postJsonData(values, filesData);
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "Error:",
  //           error.response ? error.response.data : error.message
  //         );
  //       });
  //   } else {
  //     postJsonData(values);
  //   }
  // };

  const handleDelete = async () => {
    if (siteVisitCardId) {
      const requestBody = [
        {
          id: siteVisitCardId,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);

    form
      .validateFields()
      .then((values) => {
        if (!address?.parish) {
          setMessage({
            trigger: true,
            type: "danger",
            icon: exclamationMark,
            title: "Validation Error",
            text: "The address you provided could not be found. Please check location details and try again.",
          });
          setTimeout(() => {
            setMessage({ trigger: false });
          }, 1000);
          setSubmitLoader(false);
          return;
        } else {
          const plannedVisit = `${formatDate(values.dateOfPlannedVisit)}`;
          const trainingDate = formatDate(values.trainingDate);
          const actualDateOfVisit = formatDate(values.actualDateOfVisit);
          const activationDate = formatDate(
            values.dateOfPlannedVisit,
            "activation"
          );
          const dateOfReport = formatDate(values.dateOfReport);
          const dateOfDebrief = formatDate(values.dateOfDebrief);

          let siteContacts = values.siteContacts ? [values.siteContacts] : [];
          let pointContacts = values.pointContacts
            ? [values.pointContacts]
            : [];
          let KIIcontacts = Array.isArray(values.KIIcontacts)
            ? values.KIIcontacts
            : [];

          siteContacts?.map((contact) => (contact.contactType = 1));
          pointContacts?.map((contact) => (contact.contactType = 2));
          KIIcontacts?.map((contact) => (contact.contactType = 3));

          const createContacts = [
            ...siteContacts,
            ...pointContacts,
            ...KIIcontacts,
          ];

          const formData = new FormData();
          formData.append("Name", values.name);
          formData.append("IPPlanId", values.IPlaningCard);
          formData.append("ClusterId", values.cluster);
          formData.append("SectorId", values.sector);
          formData.append("SubSectorId", values.subSector || "");

          formData.append("Perish", values.parish || false);
          formData.append("ProjectId", projectId);
          formData.append("LocationDetails.Latitude", address?.Latitude);
          formData.append("LocationDetails.Longitude", address?.Longitude);
          formData.append("LocationDetails.HouseNumber", address?.houseNumber);
          formData.append("LocationDetails.Road", address?.road);
          formData.append("LocationDetails.City", address?.city);
          formData.append(
            "LocationDetails.Municipality",
            address?.municipality
          );
          formData.append("LocationDetails.District", address?.district);
          formData.append("LocationDetails.Postcode", address?.postcode);
          formData.append("LocationDetails.Country", address?.country);
          formData.append("LocationDetails.CountryCode", address?.countryCode);
          formData.append("LocationDetails.Address", address?.parish);
          formData.append("isVirtual", values.isVirtual || false);
          formData.append("Visit.PlannedVisit", plannedVisit);

          createContacts?.forEach((contact, index) => {
            formData.append(
              `visit.createContacts[${index}].name`,
              contact.contactName
            );
            formData.append(
              `visit.createContacts[${index}].email`,
              contact.email
            );
            formData.append(
              `visit.createContacts[${index}].role`,
              contact.role
            );
            formData.append(
              `visit.createContacts[${index}].phone`,
              contact.phone || ""
            );
            formData.append(
              `visit.createContacts[${index}].skype`,
              contact.skype || ""
            );
            formData.append(
              `visit.createContacts[${index}].organization`,
              contact.organization || ""
            );
            formData.append(
              `visit.createContacts[${index}].contactType`,
              contact.contactType
            );
          });

          formData.append(
            "ObservationList",
            values.ObservationListCheck || false
          );
          formData.append(
            "Visit.CreateInterviewDetails.ObservationChecklist",
            values.observationChecklistCount || 0
          );
          formData.append(
            "Visit.CreateInterviewDetails.ObservationChecklistType",
            values.observationChecklistType || ""
          );
          formData.append(
            "Visit.CreateInterviewDetails.BeneficiariesCount",
            values.beneficiariesCount || 0
          );
          formData.append(
            "Visit.CreateInterviewDetails.ClientsCount",
            values.kills || 0
          );
          formData.append(
            "Visit.CreateInterviewDetails.MediaLimit",
            values.mediaLimit
          );
          formData.append(
            "Visit.CreateInterviewDetails.SamplingInstructions",
            values.samplingInstructions || ""
          );
          formData.append(
            "Visit.CreateInterviewDetails.ClientInstructions",
            values.clientInstructions || ""
          );
          formData.append(
            "Visit.CreateInterviewDetails.OtherInstructions",
            values.otherInstructions || ""
          );
          formData.append(
            "Visit.VisitPlanApproval",
            values.visitPlanApproval || ""
          );

          formData.append(
            "SpecificsOfOnSiteOperation",
            values.specificsOfOnSiteOperation
          );
          formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
          formData.append("Logistic.CoordinationType", values.coordinationType);
          formData.append(
            "Logistic.FulcrumAccount",
            values.fulcrumAccount || ""
          );
          formData.append("Logistic.ActivationDate", activationDate);
          formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
          formData.append("Logistic.TeamId", values.team);
          formData.append("Logistic.IsPostponed", values.isPostponed || false);
          formData.append(
            "Logistic.PostponedReason",
            values.postponedReason || ""
          );
          formData.append(
            "Logistic.PostponedDetails",
            values.postponedDetails || ""
          );
          formData.append("Logistic.Notes", values.notes || "");

          formData.append("Tracking.ReportingYear", values.reportingYear);
          formData.append("Tracking.ReportingMonth", values.reportingMonth);
          formData.append("Tracking.TrainingDate", trainingDate);
          formData.append(
            "Tracking.TrainingScoreInfo",
            values.trainingScoreInfo
          );
          formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
          formData.append("Tracking.DateOfReport", dateOfReport);
          formData.append("Tracking.DateOfDebrief", dateOfDebrief);
          formData.append(
            "Tracking.PercentageOfDataCollectionCompleted",
            values.percentageOfDataCollectionCompleted
          );
          formData.append("Tracking.SiteVisitStatus", 1);
          formData.append("Tracking.FieldTeamReportingStatus", 1);
          formData.append(
            "Tracking.TrainingIsCompleted",
            values.trainingIsCompleted || false
          );

          existingFiles?.forEach((file, index) => {
            formData.append(`Files[${index}].fileName`, file?.fileName);
            formData.append(`Files[${index}].fileKey`, file?.fileKey);
            formData.append(`Files[${index}].fileSize`, file?.fileSize);
            formData.append(`Files[${index}].FileCategory`, 2);
          });

          const existingFilesLength = existingFiles?.length || 0;
          const otherFiles = values.other_files || [];
          otherFiles?.forEach((file, index) => {
            const newIndex = index + existingFilesLength;
            formData.append(`Files[${newIndex}].fileName`, file?.name);
            formData.append(`Files[${newIndex}].fileKey`, file?.fileKey);
            formData.append(`Files[${newIndex}].fileSize`, file?.size);
            formData.append(`Files[${newIndex}].fileCategory`, 2);
          });

          httpService.post(
            "/api/Site/createSiteVisitCard",
            formData,
            (res) => {
              setMessage({
                trigger: true,
                type: "success",
                icon: Done,
                title: "Site vist card created succesfully!",
                text: "A new site visit card has been created and saved into the database!",
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
              setOtherDocuments([]);
              setFilesToSave([]);
              form.resetFields();

              handleDelete();
              setTimeout(() => {
                onOk();
              }, 1000);
            },

            (error) => {
              const errorMessage =
                error?.errors?.[0]?.message ||
                "Failed to create site visit. Please try again later.";
              console.error("There was an error adding the project!", error);
              setSubmitLoader(false);
              setMessage({
                trigger: true,
                type: "danger",
                icon: ExclamationMark,
                title: "Oops! A problem has occurred!",
                text: errorMessage,
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Site visit card updated successfully!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields?.[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name?.[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site visit card</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button
          key="back"
          onClick={handleSaveProgress}
          loading={submitLoader}
          className="inviteButtonTPM"
        >
          Save Progress
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          Add Site Visit Card
        </Button>,
      ]}
    >
      <Spin spinning={SiteLoader}>
        <div className="draftHeaderText">
          <p>
            This is a draft of the Site visit card. Once you finish editing,
            click 'Add New Site Visit Card' to add it as a new Site visit card,
            or save your progress.
          </p>
        </div>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="Site Visit Card Identifier" name="identifier">
            <div className="form-control-plaintext">
              <p
                style={{
                  border: "2px solid #d9d9d9",
                  borderRadius: "10px",
                  padding: "8px 12px",
                  fontSize: "14px",
                  color: "#495057",
                }}
              >
                <Tooltip title="This number will be generated automatically!">
                  <span>00X - </span>
                </Tooltip>
                <Tooltip title="Selected Implementing Partner!">
                  <span>
                    {selectedIpPlan?.implementingPartner?.name !== undefined
                      ? selectedIpPlan?.implementingPartner?.name
                      : "Implementing Partner"}
                  </span>
                </Tooltip>
                <Tooltip title="Selected location city!">
                  <span> - Location </span>
                </Tooltip>
                <Tooltip title="Created date!">
                  <span>{`- ${formattedDate}`}</span>
                </Tooltip>
              </p>
            </div>
          </Form.Item>
          <GeneralDetails
            form={form}
            selectedSectorId={selectedSectorId}
            setSelectedSectorId={setSelectedSectorId}
            clusters={clusters}
            setClusters={setClusters}
            allIPPlans={allIPPlans}
            setAllIPPlans={setAllIPPlans}
            selectedIpPlan={selectedIpPlan}
            setSelectedIpPlan={setSelectedIpPlan}
            sectors={sectors}
            setSectors={setSectors}
          />
          <Divider />
          <h3>Location details</h3>
          <LocationDetails
            form={form}
            isEditing={true}
            address={address}
            setAddress={setAddress}
            onClose={onCancel}
            parishCheck={parishCheck}
            setParishCheck={setParishCheck}
            showHideIpPlanDetails={showHideIpPlanDetails}
            setShowHideIpPlanDetails={setShowHideIpPlanDetails}
            selectedIpPlan={selectedIpPlan}
            setSelectedIpPlan={setSelectedIpPlan}
          />

          <Divider />
          <h3>Visit details</h3>

          <VisitDetails
            form={form}
            kills={kills}
            setKills={setKills}
            requiredPhotos={requiredPhotos}
            setRequiredPhotos={setRequiredPhotos}
            requiredVideos={requiredVideos}
            setRequiredVideos={setRequiredVideos}
            siteContactsRequired={siteContactsRequired}
            setSiteContactsRequired={setSiteContactsRequired}
            observationList={observationList}
            setObservationList={setObservationList}
          />

          <Divider />
          <h3>Logistics</h3>

          <Logistics />

          <Divider />
          <h3>Team</h3>
          <TeamDetails singleTeam={singleTeam} setSingleTeam={setSingleTeam} />

          <Divider />
          <h3>Postponement</h3>
          <Postponement postponed={postponed} setPostponed={setPostponed} />
          <Divider />

          <Form.Item required label="Site Visit Status" name="siteVisitStatus">
            <Select
              style={{ width: 370, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={2}>Pre-planning phase</Option>
              <Option value={3}>Planning</Option>
            </Select>
          </Form.Item>
          <Divider />
          <h3>Resources</h3>

          <ResourcesDetails
            form={form}
            existingFiles={existingFiles}
            setExistingFiles={setExistingFiles}
            setMessage={setMessage}
            filesToSave={filesToSave}
            setFilesToSave={setFilesToSave}
            setSubmitLoader={setSubmitLoader}
            setFilesToDelete={setFilesToDelete}
          />

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default EditDraftVisits;
