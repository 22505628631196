import { Select, Input, Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { httpService } from "../../../../services/httpService.service";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import debounce from "lodash/debounce";

const { Option } = Select;

const Filters = ({
  onChange,
  filters,
  setFilters,
  pagination,
  setPagination,
  fetchRedFlags,
}) => {
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [search, setSearch] = useState("");
  const projectID = localStorage.getItem("currentProjectID");

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleClearAll = () => {
    setSearch("");
    setFilters({
      Search: "",
      ImplementingPartnerId: "",
    });
    setPagination({ ...pagination, current: 1 });
    fetchRedFlags({ projectID, pageNumber: 1, pageSize: 10 });
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        Search: value,
      }));
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
    setSearch(e.target.value);
  };

  const handleIp = (value, option) => {
    const ImplementingPartnerId = option ? [option.key] : [];
    setFilters((prevFilters) => ({
      ...prevFilters,
      Search: "",
      ImplementingPartnerId,
    }));
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input
          placeholder="Search by name..."
          value={search}
          onChange={handleSearchChange}
          suffix={<img src={SearchIcon} alt="Search" />}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              onChange={handleIp}
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          {/* <div className="filter">
            <p className="filterText">Month</p>
            <MonthPicker
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              allowClear
              value={month ? dayjs(month, "MM") : null}
              onChange={handleMonth}
              format="MM"
            />
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <YearPicker
              onChange={handleYear}
              allowClear
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              value={year ? dayjs(year, "YYYY") : null}
            />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Filters;
