import React, { useCallback, useEffect, useRef, useState } from "react";
import "./AdminHeader.css";
import {
  Button,
  Dropdown,
  Menu,
  Empty,
  List,
  Avatar,
  Badge,
  Divider,
  Spin,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../../services/authentication.service";
import RedFlagIcon from "../../../Assets/svg/notification1.svg";
import IpPlanIcon from "../../../Assets/svg/notification2.svg";
import SiteVisitIcon from "../../../Assets/svg/notification1.svg";
import { httpService } from "../../../services/httpService.service";
import { getRelativeTime } from "../../../services/helpFunctions/getRelativeTime";
import taskIcon from "../../../Assets/svg/taskIcon.svg";
import teamIcon from "../../../Assets/svg/teamIcon.svg";
import tripLogIcon from "../../../Assets/svg/tripLogIcon.svg";
import userIcon from "../../../Assets/svg/userIcon.svg";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";

const AdminHeader = ({
  notifications,
  setNotifications,
  setUnreadNotifications,
  unreadNotifications,
}) => {
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const observer = useRef();
  const userId = localStorage.getItem("userID");
  const contractName =
    localStorage.getItem("currentContractName") || "Contract Name";
  const projectName =
    localStorage.getItem("projectRoundName") || "Project Name";
  const location = useLocation();
  const isProjectRoute = location.pathname.includes("/project/");
  const pathParts = location.pathname.split("/").filter(Boolean);
  const dynamicParts = [];
  const projectIndex = pathParts.indexOf("project");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  if (projectIndex >= 0 && projectIndex + 1 < pathParts.length) {
    for (let i = projectIndex + 1; i < pathParts.length; i++) {
      dynamicParts.push(pathParts[i]);
    }
  }

  const navigate = useNavigate();

  const loadNotifications = async (pageNumber) => {
    if (!userId || isLoadingMore) return;
    setIsLoadingMore(true);
    httpService
      .get(
        `/api/Notification/getAllNotifications?pageSize=10&pageNumber=${pageNumber}`,
        (response) => {
          const notificationsData = response?.data?.notifications?.data;
          const newNotifications = notificationsData?.map((data) => {
            const createdDate = data?.createdDate || "";

            return {
              id: data?.id,
              type: data?.notificationType,
              description: data?.message,
              entityId: data?.entityId,
              read: data?.isRead,
              title: data?.title,
              time: getRelativeTime(createdDate) || "",
              contractId: data?.contractId,
              projectId: data?.projectId,
            };
          });
          const metaData = response?.data?.notifications?.metaData;
          const unreadCount = response?.data?.unreadCount;

          setUnreadNotifications(unreadCount);
          setNotifications((prev) => ({
            data: [...(prev?.data || []), ...newNotifications],
            metaData: metaData,
            unreadCount: unreadCount,
          }));
        },
        (error) => {
          console.error("Error fetching initial notifications:", error);
        }
      )
      .finally(() => setIsLoadingMore(false));
  };

  useEffect(() => {
    localStorage.setItem("entityId", "");
  }, []);

  useEffect(() => {
    if (page > 1) {
      loadNotifications(page);
    }
  }, [page]);

  useEffect(() => {
    if (userId) {
      loadNotifications(1);
    }
  }, [userId]);

  const lastNotificationRef = useCallback(
    (node) => {
      if (isLoadingMore) return; // Don't load if we're already loading
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            notifications?.metaData?.hasNextPage
          ) {
            // When the last item is in view, load the next page
            setPage((prevPage) => prevPage + 1); // Increment page number
          }
        },
        {
          rootMargin: "100px", // Trigger loading before the last item is fully in view
          threshold: 1.0, // Trigger when the last item is fully visible
        }
      );
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, notifications?.metaData?.hasNextPage]
  );

  let userRoles = localStorage.getItem("role");
  let userEmail = localStorage.getItem("userEmail");
  let userName = localStorage.getItem("username");

  const handleNotificationClick = (id) => {
    if (id) {
      setNotifications((prevNotifications) => {
        const updatedNotifications =
          prevNotifications?.data?.map((notification) =>
            notification?.id === id
              ? { ...notification, read: true }
              : notification
          ) ?? [];
        if (unreadNotifications != 0) {
          setUnreadNotifications(unreadNotifications - 1);
        }
        return {
          data: updatedNotifications,
          metaData: prevNotifications?.metaData - 1,
        };
      });

      httpService.put(
        `/api/Notification/markAsRead?id=${id}`,
        "",
        (res) => console.log(res),
        (err) => console.log(err)
      );
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 1:
        return SiteVisitIcon;
      case 2:
        return IpPlanIcon;
      case 3:
        return RedFlagIcon;
      case 4:
        return taskIcon;
      case 5:
        return tripLogIcon;
      case 6:
        return userIcon;
      case 7:
        return teamIcon;
      default:
        return null;
    }
  };

  // {
  //     [Description("Site Visit")] SiteVisit = 1,
  //     [Description("IP Plan")] IPPlan = 2,
  //     [Description("Red Flag")] RedFlag = 3,
  //     [Description("Task")] Task = 4,
  //     [Description("Trip Logistic")] TripLogistic = 5,
  //     [Description("User")] User = 6,
  //     [Description("Team")] Team = 7,
  // }

  const handleViewNotificationDetails = (item) => {
    if (item) {
      setDropdownVisible(false);

      localStorage.setItem("currentProjectID", item?.projectId);
      localStorage.setItem("currentContractId", item?.contractId);
      localStorage.setItem("entityId", item?.entityId);

      switch (item?.type) {
        case 1:
          navigate("/project/planning?visits");
          break;
        case 2:
          navigate("/project/planning?ip-planning");
          break;
        case 3:
          navigate("/project/flags?Flags");
          break;
        case 4:
          navigate("/project/planning?tasks");
          break;
        case 5:
          navigate("/project/planning?logistics");
          break;
        default:
          break;
        // navigate("/project/dashboard");
      }
    }
  };

  const backLogout = () => {
    AuthService.logout();
  };

  const profilePicture = localStorage.getItem("profilePicture");
  const profileInitial =
    userName && userName?.trim() !== ""
      ? userName?.charAt(0)
      : userEmail?.charAt(0);

  const notificationMenu = (
    <div style={{ width: 360 }}>
      {notifications?.data?.length > 0 ? (
        <div className="notificationContainer">
          <p className="notificationsTitle">Notifications</p>
          <div
            className="notificationContent"
            style={{
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={notifications?.data}
              renderItem={(item, index) => (
                <List.Item
                  ref={
                    index === notifications?.data?.length - 1
                      ? lastNotificationRef
                      : null
                  }
                  style={{
                    padding: "12px 20px",
                    backgroundColor: item.read
                      ? "transparent"
                      : "rgba(250, 220, 234, 0.50)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNotificationClick(item?.id)}
                >
                  <List.Item.Meta
                    className="custom-divider"
                    avatar={
                      <div className="mt-1">
                        <Avatar src={getIcon(item?.type)} />
                      </div>
                    }
                    description={
                      <div>
                        {/* <strong className="notificationTitle">
                          Title: {item?.title}
                        </strong> */}
                        <p className="description">{item?.description}</p>
                        {(item?.type === 1 &&
                          isAuthorized([
                            RolesEnum.ProjectManager,
                            RolesEnum.SystemAdmin,
                            RolesEnum.FieldCoordinator,
                            RolesEnum.FieldEnumerator,
                            RolesEnum.FieldLogistics,
                            RolesEnum.Guest,
                            RolesEnum.LimitedGuest,
                          ])) ||
                        (item?.type === 2 &&
                          isAuthorized([
                            RolesEnum.ProjectManager,
                            RolesEnum.SystemAdmin,
                            RolesEnum.Guest,
                          ])) ||
                        (item?.type === 3 &&
                          isAuthorized([
                            RolesEnum.ProjectManager,
                            RolesEnum.SystemAdmin,
                            RolesEnum.FieldCoordinator,
                            RolesEnum.FieldTeamLeader,
                            RolesEnum.FieldEnumerator,
                            RolesEnum.FieldLogistics,
                          ])) ||
                        (item?.type === 4 &&
                          isAuthorized([
                            RolesEnum.ProjectManager,
                            RolesEnum.SystemAdmin,
                            RolesEnum.FieldCoordinator,
                            RolesEnum.FieldLogistics,
                            RolesEnum.FieldTeamLeader,
                            RolesEnum.Guest,
                          ])) ||
                        (item?.type === 5 &&
                          isAuthorized([
                            RolesEnum.ProjectManager,
                            RolesEnum.SystemAdmin,
                            RolesEnum.FieldCoordinator,
                            RolesEnum.FieldLogistics,
                            RolesEnum.FieldTeamLeader,
                            RolesEnum.Guest,
                            RolesEnum.LimitedGuest,
                            RolesEnum.FieldEnumerator,
                          ])) ? (
                          <button
                            className="previewTextHeader"
                            onClick={() => handleViewNotificationDetails(item)}
                          >
                            {"View details >>"}
                          </button>
                        ) : null}

                        <div className="timeText">{item?.time}</div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
            {isLoadingMore && <Divider className="m-0 p-0" />}
            <div className="py-2 w-100 d-flex flex-row justify-content-center align-items-ceter gap-5">
              <Spin size="small" spinning={isLoadingMore} />
            </div>
            {isLoadingMore && <Divider className="m-0 p-0" />}
          </div>
        </div>
      ) : (
        <div className="notificationContainer">
          <div className="notificationTitle ms-3">Notifications</div>
          <div
            style={{ padding: "40px", background: "rgba(255, 245, 250, 0.70)" }}
          >
            <Empty description="No notifications here!" />
          </div>
        </div>
      )}
    </div>
  );

  const profileMenu = (
    <Menu>
      <Menu.Item key="2">
        <Link
          to="/settings/profile-information"
          style={{ textDecoration: "none" }}
        >
          Profile information
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          to="/settings/login-information"
          style={{ textDecoration: "none" }}
        >
          Login information
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5">
        <Button
          type="link"
          style={{ color: "red", textAlign: "center", width: "100%" }}
          onClick={backLogout}
        >
          Log Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isProjectRoute ? (
        <div
          className="topHeaderWrapper"
          style={{ width: "100%", padding: "0 16px" }}
        >
          <div
            className=" costumNavMenu"
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              fontSize: "18px",
              color: "#737373",
            }}
          >
            {/* Static Breadcrumbs */}
            <a href="/dashboard/contracts" className="navMenuText">
              Contracts
            </a>
            &nbsp;&gt;&nbsp;
            <a href="/projects" className="navMenuText">
              {contractName}
            </a>
            &nbsp;&gt;&nbsp;
            <a href="/project/dashboard" className="navMenuText">
              {projectName}
            </a>
            {dynamicParts.map((part, index) => {
              const formattedPart =
                part === "ip-management"
                  ? "IP Management"
                  : part.charAt(0).toUpperCase() + part.slice(1);

              return (
                <React.Fragment key={index}>
                  &nbsp;&gt;&nbsp;
                  <span
                    className="navMenuText"
                    style={{ color: "#AA1A5F", cursor: "default" }}
                  >
                    {formattedPart}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
          <div
            className="d-flex gap-2 h-100 userInformation"
            style={{ maxHeight: "60px" }}
          >
            <Dropdown
              overlay={notificationMenu}
              trigger={["click"]}
              placement="bottom"
              open={dropdownVisible}
              onVisibleChange={(visible) => setDropdownVisible(visible)}
            >
              <Badge count={unreadNotifications} offset={[-10, 10]}>
                <div
                  className="d-flex iconWrapper"
                  style={{ cursor: "pointer" }}
                >
                  <div className="notificationIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                    >
                      <path
                        d="M10.5992 18.4246C10.4365 18.7053 10.2029 18.9382 9.92166 19.1001C9.64054 19.262 9.32184 19.3473 8.9974 19.3473C8.67295 19.3473 8.35425 19.262 8.07314 19.1001C7.79203 18.9382 7.55832 18.7053 7.39554 18.4246M14.553 6.75796C14.553 5.1863 13.9677 3.67902 12.9257 2.5677C11.8839 1.45636 10.4708 0.832031 8.9974 0.832031C7.52397 0.832031 6.1109 1.45636 5.06903 2.5677C4.02716 3.67902 3.44184 5.1863 3.44184 6.75796C3.44184 13.6716 0.664062 15.6468 0.664062 15.6468H17.3307C17.3307 15.6468 14.553 13.6716 14.553 6.75796Z"
                        stroke="#777777"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </Badge>
            </Dropdown>

            {/* Profile Dropdown */}
            <Dropdown
              overlay={profileMenu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <div
                className="d-flex align-items-center adminWrapper"
                style={{ cursor: "pointer" }}
              >
                <div className="adminHeaderLogo">
                  <div className="adminHeaderCircle">
                    {profilePicture &&
                    profilePicture !==
                      "Profile picture removed successfully" ? (
                      <img
                        src={profilePicture}
                        className="adminHeaderCircle"
                        style={{ objectFit: "cover" }}
                        alt="Profile"
                      />
                    ) : (
                      <span
                        className="adminHeaderCircleText"
                        style={{ textTransform: "capitalize" }}
                      >
                        {profileInitial}
                      </span>
                    )}
                  </div>
                </div>

                <div className="adminHeaderName">
                  <div className="adminHeaderNameText">
                    {userName && userName.trim() !== "" ? userName : userEmail}
                    <br />
                    <span className="adminHeaderNameTextPosition">
                      {userRoles && userRoles}
                    </span>
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
      ) : (
        <div
          className="d-flex gap-2 h-100 userInformation "
          style={{ maxHeight: "60px" }}
        >
          {/* Notification Dropdown */}
          <Dropdown
            overlay={notificationMenu}
            trigger={["click"]}
            placement="bottom"
          >
            <Badge count={unreadNotifications} offset={[-10, 10]}>
              <div className="d-flex iconWrapper" style={{ cursor: "pointer" }}>
                <div className="notificationIcon ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                  >
                    <path
                      d="M10.5992 18.4246C10.4365 18.7053 10.2029 18.9382 9.92166 19.1001C9.64054 19.262 9.32184 19.3473 8.9974 19.3473C8.67295 19.3473 8.35425 19.262 8.07314 19.1001C7.79203 18.9382 7.55832 18.7053 7.39554 18.4246M14.553 6.75796C14.553 5.1863 13.9677 3.67902 12.9257 2.5677C11.8839 1.45636 10.4708 0.832031 8.9974 0.832031C7.52397 0.832031 6.1109 1.45636 5.06903 2.5677C4.02716 3.67902 3.44184 5.1863 3.44184 6.75796C3.44184 13.6716 0.664062 15.6468 0.664062 15.6468H17.3307C17.3307 15.6468 14.553 13.6716 14.553 6.75796Z"
                      stroke="#777777"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </Badge>
          </Dropdown>

          {/* Profile Dropdown */}
          <Dropdown
            overlay={profileMenu}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div
              className="d-flex align-items-center adminWrapper"
              style={{ cursor: "pointer" }}
            >
              <div className="adminHeaderLogo">
                <div className="adminHeaderCircle">
                  {profilePicture &&
                  profilePicture !== "Profile picture removed successfully" ? (
                    <img
                      src={profilePicture}
                      className="adminHeaderCircle"
                      style={{
                        objectFit: "cover",
                      }}
                      alt="Profile"
                    />
                  ) : (
                    <span
                      className="adminHeaderCircleText"
                      style={{ textTransform: "capitalize" }}
                    >
                      {profileInitial}
                    </span>
                  )}
                </div>
              </div>

              <div className="adminHeaderName">
                <div className="adminHeaderNameText">
                  {userName && userName.trim() !== "" ? userName : userEmail}
                  <br />
                  <span className="adminHeaderNameTextPosition">
                    {userRoles && userRoles}
                  </span>
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default AdminHeader;
