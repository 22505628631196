import { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import { saveAs } from "file-saver";

const getFileExtension = (filePath) => {
  const splitedFileName = filePath.split(".");
  const fileExtension =
    splitedFileName[splitedFileName.length - 1].toLowerCase();
  console.log(fileExtension);
  return fileExtension;
};

export const useFilePreview = () => {
  const [loadingFiles, setLoadingFiles] = useState({});

  const previewFiles = (e, filePath, id) => {
    e.stopPropagation();
    e.preventDefault();
    setLoadingFiles((prev) => ({ ...prev, [filePath]: true }));

    const correctedFilePath = filePath?.replace(/\\202/g, "\\x82");
    const payload = [{ fileKey: correctedFilePath, entityId: id }];

    axios
      .post(
        `https://kapfilemanager.kapsurvey.com/FileData/getFileUrlByFileKeyAndEntityId`,
        payload
      )
      .then((response) => {
        setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));

        if (response && response.data && response.data.data.length > 0) {
          const fileData = response.data.data[0].fileUrl;
          const fileExtension = getFileExtension(filePath);

          const imageExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "svg",
            "webp",
          ];
          const pdfExtension = "pdf";

          const documentExtensions = [
            "xls",
            "xlsx",
            "doc",
            "docx",
            "ppt",
            "pptx",
          ];

          const videoExtensions = ["mp4"];

          if (
            imageExtensions.includes(fileExtension) ||
            videoExtensions.includes(fileExtension) ||
            fileExtension === pdfExtension
          ) {
            const newWindow = window.open(fileData, "_blank");
            if (newWindow) {
              newWindow.focus();
            } else {
              notification.warning({
                message: "Pop-up Blocked",
                description:
                  "Please allow pop-ups for this website to view the file.",
              });
            }
          } else if (documentExtensions.includes(fileExtension)) {
            saveAs(fileData, correctedFilePath);
          } else {
            notification.warning({
              message: "Invalid File Type",
              description: `The file type ${fileExtension} is not supported.`,
            });
          }
        } else {
          notification.warning({
            message: "No Data Available",
            description: "No file URL found in the response.",
          });
        }
      })
      .catch((error) => {
        setLoadingFiles((prev) => ({ ...prev, [filePath]: false }));
        notification.error({
          message: "Error",
          description: "Error fetching file URL. Please try again later.",
        });
        console.error("Error fetching file URL:", error);
      });
  };

  const getVideoDetails = async (filePath, id) => {
    const correctedFilePath = filePath.replace(/\\202/g, "\\x82");
    const payload = [{ fileKey: correctedFilePath, entityId: id }];

    try {
      const response = await axios.post(
        `https://kapfilemanager.kapsurvey.com/FileData/getFileUrlByFileKeyAndEntityId`,
        payload
      );

      if (response && response.data && response.data.data.length > 0) {
        const fileData = response.data.data[0].fileUrl;
        const fileExtension = getFileExtension(filePath);

        if (fileExtension === "mp4") {
          const videoName = correctedFilePath.split("/").pop();
          return { videoName, videoUrl: fileData };
        } else {
          notification.warning({
            message: "Invalid File Type",
            description: "The file is not an MP4 video.",
          });
          return null;
        }
      } else {
        notification.warning({
          message: "No Data Available",
          description: "No file URL found in the response.",
        });
        return null;
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Error fetching video details. Please try again later.",
      });
      console.error("Error fetching video details:", error);
      return null;
    }
  };

  return { loadingFiles, previewFiles, getVideoDetails };
};
