import { Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../../components/DeleteComponent/DeleteComponent";
import { httpService } from "../../../../../../services/httpService.service";
import MessagePopUp from "../../../../../components/Messages/MessagePopUp";
import AddMemberIcon from "../../../../../../Assets/svg/addMemberIcon.svg";
import { RolesEnum } from "../../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../../services/authService";
import EditDraft from "./EditDraft";

function DraftView({
  showAllTripDataDrafts,
  fetchTripLogisticsDrafts,
  siteVisits,
  draftPagination,
  onDraftPageChange,
  setDraftPagination,
  loadingTripLogistic,
  handleFilterChange,
  filters,
  setFilters,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const projectID = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleShowEditModal = (record) => {
    setSelectedRowData(record);
    setShowEditModal(true);
  };

  const handleDeleteModal = (record) => {
    setSelectedRowData(record);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchTripLogisticsDrafts(
      projectID,
      filters.startDate,
      filters.endDate,
      filters.status,
      filters.country,
      filters.city,
      filters.search
    );
  }, [filters, draftPagination?.current, draftPagination?.pageSize]);

  const handleDelete = () => {
    setLoading(true);
    const payload = [
      {
        id: selectedRowData.id,
      },
    ];
    httpService.delete(
      "/api/Draft/deleteDrafts",
      payload,
      (response) => {
        setShowDeleteModal(false);
        fetchTripLogisticsDrafts(projectID);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Trip Logistic deleted successfully!",
          text: "This Trip Logistic has been deleted from your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem deleting this trip logistic to your database, please try again.";
        setMessage({
          trigger: true,
          type: "error",
          icon: AddMemberIcon,
          title: "Failed to delete this trip logistic",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
        console.error("Error deleting trip logistics:", error);
      }
    );
  };

  const columns = [
    // {
    //   title: <span className="styledTitle">Trip status</span>,
    //   dataIndex: "tripStatus",
    //   key: "tripStatus",
    //   sorter: (a, b) => a.tripStatus.localeCompare(b.tripStatus),
    //   render: (tripStatus, text) => {
    //     let className;
    //     let fill;
    //     if (tripStatus === 1) {
    //       className = "progress";
    //       text = "Planned";
    //       fill = "#3B81FE";
    //     } else if (tripStatus === 2) {
    //       className = "monitoring";
    //       text = "In Progress";
    //       fill = "#FC9403";
    //     } else if (tripStatus === 3) {
    //       className = "review";
    //       fill = "#9670B8";
    //       text = "Completed";
    //     } else if (tripStatus === 4) {
    //       className = "delivered";
    //       fill = "#045B46";
    //       text = "Cancelled";
    //     }
    //     return (
    //       <div className={className}>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="8px"
    //           height="8px"
    //           viewBox="0 0 9 9"
    //         >
    //           <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
    //         </svg>
    //         <span>{text}</span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: <span className="styledTitle">Date for trip</span>,
      dataIndex: ["jsonData", "enumeratorInformation", "tripDate"],
      key: "tripDate",
      sorter: (a, b) =>
        new Date(a.jsonData.enumeratorInformation.tripDate) -
        new Date(b.jsonData.enumeratorInformation.tripDate),
      render: (tripDate) => (
        <span className="styledDataIndex3">
          {tripDate ? new Date(tripDate).toLocaleDateString() : "N/A"}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Site Visit ID</span>,
      dataIndex: ["jsonData", "siteVisitId", "identifier"],
      key: "siteVisitId",
      sorter: (a, b) =>
        a.jsonData.siteVisitId.localeCompare(b.jsonData.siteVisitId),
      render: (siteVisitId) => (
        <span className="styledDataIndex3">{siteVisitId || "N/A"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Pickup Time</span>,
      dataIndex: ["jsonData", "enumeratorInformation", "pickupTime"],
      key: "pickupTime",
      sorter: (a, b) =>
        a.jsonData.enumeratorInformation.pickupTime.localeCompare(
          b.jsonData.enumeratorInformation.pickupTime
        ),
      render: (pickupTime) => (
        <span className="styledDataIndex3">{pickupTime || "N/A"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Pickup Place</span>,
      dataIndex: ["jsonData", "enumeratorInformation", "pickupPlace"],
      key: "pickupPlace",
      sorter: (a, b) =>
        a.jsonData.enumeratorInformation.pickupPlace.localeCompare(
          b.jsonData.enumeratorInformation.pickupPlace
        ),
      render: (pickupPlace) => (
        <span className="styledDataIndex3">{pickupPlace || "N/A"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Meeting Time</span>,
      dataIndex: ["jsonData", "enumeratorInformation", "meetingTime"],
      key: "meetingTime",
      sorter: (a, b) =>
        a.jsonData.enumeratorInformation.meetingTime.localeCompare(
          b.jsonData.enumeratorInformation.meetingTime
        ),
      render: (meetingTime) => (
        <span className="styledDataIndex3">{meetingTime || "N/A"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Location 2</span>,
      dataIndex: ["enumeratorInformation", "meetingPlace"],
      key: "meetingPlace",
      sorter: (a, b) =>
        (a.enumeratorInformation?.meetingPlace || "").localeCompare(
          b.enumeratorInformation?.meetingPlace || ""
        ),
      render: (meetingPlace) => (
        <span className="styledDataIndex3">{meetingPlace || "N/A"}</span>
      ),
    },
    {
      title: <span className="styledTitle">Overnight</span>,
      dataIndex: ["enumeratorInformation", "isOverNight"],
      key: "isOverNight",
      sorter: (a, b) =>
        (a.enumeratorInformation?.isOverNight ? "Yes" : "No").localeCompare(
          b.enumeratorInformation?.isOverNight ? "Yes" : "No"
        ),
      render: (isOverNight) => (
        <span className="styledDataIndex3">{isOverNight ? "Yes" : "No"}</span>
      ),
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleShowEditModal(record)}
              style={{ cursor: "pointer" }}
              alt="editIcon"
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteModal(record)}
              alt="deleteIcon"
            ></img>
          )}
        </div>
      ),
    },
  ];

  console.log("test", showAllTripDataDrafts);
  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />

      <div>
        {isAuthorized([
          RolesEnum.SystemAdmin,
          RolesEnum.ProjectManager,
          RolesEnum.FieldCoordinator,
          RolesEnum.FieldLogistics,
        ]) && (
          <Table
            rowKey="id"
            loading={loadingTripLogistic}
            pagination={{
              ...draftPagination,
              onChange: onDraftPageChange,
              showSizeChanger: true,
            }}
            dataSource={showAllTripDataDrafts}
            columns={columns}
            className="tableTPM"
          />
        )}
      </div>
      <EditDraft
        visible={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        record={selectedRowData}
        fetchTripLogistics={fetchTripLogisticsDrafts}
        siteVisits={siteVisits}
        setMessage={setMessage}
      />

      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        text={"You're about to delete this. This action can’t be undone."}
        header={"Delete"}
        loading={loading}
      />
    </>
  );
}

export default DraftView;
