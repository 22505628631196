import React, { useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";
import { formatDate } from "../../../../services/helpFunctions/formatDateAndTime";

const getIpDonorReportingDescription = (enumValue) => {
  const descriptions = {
    1: "Awaiting Field",
    2: "Data cleaning",
    3: "Data analysis",
    4: "Data translation",
    5: "IP Report Development",
    6: "Donor Report",
    7: "Completed",
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to get IP Status description
const getIpStatusDescription = (enumValue) => {
  const descriptions = {
    1: "Discussion",
    2: "Document Review",
    3: "Pre-phase",
    4: "Data Collection",
    5: "Closed",
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to convert true/false to "Yes"/"No"
const getPosponedStatus = (value) => (value ? "Yes" : "No");

// Utility function to format date by removing 'T'

function IpCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(
    projectData?.data?.ipSummaryIPPlanningCardResponse
  )
    ? projectData.data.ipSummaryIPPlanningCardResponse
    : [];

  const checkStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        className = "discussion";
        fill = "var(--system-yellow-warning, #AA1A5F)";
        text = "Discussion";
        break;
      case 2:
        className = "documentReview";
        fill = "var(--system-yellow-warning, #FC9403)";
        text = "Document Review";
        break;
      case 3:
        className = "prePlanningPhase";
        fill = "var(--Primary-Blueberry-700, #325899)";
        text = "Pre Planning Phase";
        break;
      case 4:
        className = "dataCollection";
        fill = "#3B81FE";
        text = "Data Collection";
        break;
      case 5:
        className = "closed";
        fill = "#555555";
        text = "Closed";
        break;
      default:
        className = "noStatus";
        fill = " rgba(172, 12, 12, 0.777)";
        text = "No status";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">IP Planning cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.ipPlanIdentifier}>
              <div className="ipCardCarousel">
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.ipPlanKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">
                    {card?.ipPlanIdentifier}
                  </h6>
                  <span className="ipCardTextName">{`Main Sector: ${card?.mainSector}`}</span>
                  <span className="ipCardTextName">
                    IP/Donor reporting status:{" "}
                  </span>
                  {checkStatus(card?.ipDonorReportingEnum)}
                </div>
              </div>
            </React.Fragment>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
            }}
          >
            <img src={arrow1} alt="arrow1" height={35} width={35} />
            <div className="ipCardList">
              {selectedCardKeyInfo ? (
                <>
                  <span className="ipCardTextName">
                    {`Status: `}{" "}
                    <span>
                      {getIpStatusDescription(selectedCardKeyInfo.ipStatusEnum)}
                    </span>
                  </span>
                  <span className="ipCardTextName">
                    {`Planning Round: `}{" "}
                    <span>{selectedCardKeyInfo.planningRound}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Site Proposed: `}{" "}
                    <span>{formatDate(selectedCardKeyInfo.siteProposed)}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Postponed: `}{" "}
                    <span>
                      {getPosponedStatus(selectedCardKeyInfo?.posponed)}
                    </span>
                  </span>
                  <span className="ipCardTextName">
                    {`Data Collection Completed: `}{" "}
                    <span>{selectedCardKeyInfo.dataCollectionCompleted}</span>
                  </span>
                </>
              ) : (
                <span className="ipCardTextName">
                  Select a card to see key info
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IpCards;
