import React, { useEffect, useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";
import { formatDate } from "../../../../services/helpFunctions/formatDateAndTime";

// Utility function to get IP Status description
const getIpStatusDescription = (enumValue) => {
  const descriptions = {
    1: "Assigned",
    2: "In progress",
    3: "On hold",
    4: "Resolved",
    5: "Archived",
  };
  return descriptions[enumValue] || "Unknown";
};

// Utility function to format the date

function RedFlagCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);
  const [flexBasis, setFlexBasis] = useState("50%");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1090px)");

    const handleResize = () => {
      setFlexBasis(mediaQuery.matches ? "100%" : "50%");
    };

    // Set initial value
    handleResize();

    // Add event listener
    mediaQuery.addEventListener("change", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(
    projectData?.data?.ipSummaryRedFlagCardsResponse
  )
    ? projectData.data.ipSummaryRedFlagCardsResponse
    : [];

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">Red Flag Cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.redFlagIdentifier}>
              <div className="ipCardCarousel" style={{ flexBasis: flexBasis }}>
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.redFlagKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">
                    {card?.redFlagIdentifier}
                  </h6>
                  <span className="ipCardTextName">{`IP name: ${card?.ipName}`}</span>
                  <span className="ipCardTextName">{`Date of visit: ${formatDate(
                    card?.dateOfVisit
                  )}`}</span>

                  <span className="ipCardTextName">
                  Site Visit Card Identifier: {card?.siteVisitIdentifier}
                  </span>
                  <span className="ipCardTextName">
                    Assigned to: {card?.assignedTo}
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
            }}
          >
            <img src={arrow1} alt="arrow1" height={35} width={35} />
            <div className="ipCardList">
              {selectedCardKeyInfo ? (
                <>
                  <span className="ipCardTextName">
                    {`Due Date: `}{" "}
                    <span>{formatDate(selectedCardKeyInfo.dueDate)}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Follow up notes: `}{" "}
                    <span>{selectedCardKeyInfo.followUpNotes}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Last update: `}{" "}
                    <span>{formatDate(selectedCardKeyInfo.lastUpdate)}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Red Flag status: `}{" "}
                    <span>
                      {getIpStatusDescription(
                        selectedCardKeyInfo.redFlagStatus
                      )}
                    </span>
                  </span>
                </>
              ) : (
                <span className="ipCardTextName">
                  Select a card to see key info
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedFlagCards;
