import React, { useEffect, useState, useCallback } from "react";
import { Input } from "antd";
import SearchIcon from "../../../../../../Assets/svg/searchIcon.svg";

const Filters = ({ fetchTeams, teams }) => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  // Debounce effect to update `debouncedSearch` only after user stops typing
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchText !== debouncedSearch) { // Update only if value has changed
        setDebouncedSearch(searchText);
      }
    }, 300); // 300ms debounce delay

    return () => clearTimeout(handler); // Clear timeout on each input change
  }, [searchText]);

  // Trigger `fetchTeams` whenever `debouncedSearch` changes
  useEffect(() => {
    if (debouncedSearch || debouncedSearch === "") { // Allow empty search to reset
      const contractId = localStorage.getItem("currentContractId");
      fetchTeams(contractId, debouncedSearch);
    }
  }, [debouncedSearch, fetchTeams]);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
          placeholder="Type something here"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          suffix={<img src={SearchIcon} alt="" />}
        />
      </div>
    </div>
  );
};

export default Filters;