import React, { useState } from "react";
import CalendarViewComponent from "../../../../components/CalendarView/CalendarViewComponent";
import Filters from "../TableView/Filters";
import { Pagination } from "antd";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";

const CalendarViewReporting = ({
  data,
  filters,
  handleFilterChange,
  setFilters,
  onPageChange,
  pagination,
  setPagination,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState();

  const handleEventClicked = (eventId) => {
    setClickedEventId(eventId);
    if (eventId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  const events = data.reduce((acc, trip) => {
    const dateKey = trip?.ipPlan?.initialMeeting.split("T")[0];
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push({
      type: "success",
      status: trip?.ipPlan?.initialMeeting,
      content: trip?.identifier,
      id: trip?.id,
    });
    return acc;
  }, {});

  const getListData = (value) => {
    const dateKey = value.format("YYYY-MM-DD");
    const filteredEvents = events[dateKey] || [];
    return filteredEvents.filter((event) => {
      let passFilters = true;
      if (filters.status && event.status !== filters.status) {
        passFilters = false;
      }
      if (filters.startDate && filters.endDate) {
        const eventDate = new Date(dateKey);
        if (
          eventDate < new Date(filters?.startDate) ||
          eventDate > new Date(filters?.endDate)
        ) {
          passFilters = false;
        }
      }
      if (
        filters.search &&
        !event.content.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        passFilters = false;
      }
      return passFilters;
    });
  };

  return (
    <>
      <Filters
        filters={filters}
        setFilters={setFilters}
        setPagination={setPagination}
      />
      <CalendarViewComponent
        getListData={getListData}
        setClickedEventId={handleEventClicked}
      />

      <Preview
        visible={showPreviewModal}
        onClose={handleCancel}
        siteVisitCardId={clickedEventId}
      />
    </>
  );
};

export default CalendarViewReporting;
