import { Modal } from "antd";
import React, { useRef } from "react";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const PreviewVideoModal = ({ visible, handleCancel, fileName, videoUrl }) => {
  const videoRef = useRef(null); // Create a reference for the video element

  const handleClose = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
      videoRef.current.currentTime = 0; // Optional: reset playback position to the start
    }
    handleCancel(); // Call the original cancel function
  };


  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleClose} // Use the new close handler
      footer={false}
      width={835}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleClose} // Use the new close handler
        />
      </div>
      <div className="d-flex flex-column align-items-center w-100">
        <h2 className="modalHeader text-start ms-5">{fileName}</h2>
        <video
          ref={videoRef} // Attach the ref to the video element
          style={{
            width: "100%",
            flexShrink: "0",
            borderRadius: "16px",
            background: "lightgray",
          }}
          src={videoUrl}
          controls={true}
        >
          Video stream not available.
        </video>
      </div>
    </Modal>
  );
};

export default PreviewVideoModal;
