import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Button, Card, Spin, Divider } from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import "./Preview.scss";
import { httpService } from "../../../../../../services/httpService.service";
import { useFilePreview } from "../../../../completed/ReportingList/Download";
import PreviewVideoModal from "../../../../../components/VideoPreview/PreviewVideoModal";
import SiteVisitProgress from "./SiteVisitProgress";
import downArrow from "../../../../../../Assets/svg/downArrow.svg";
import { formatDateAndTime } from "../../../../../../services/helpFunctions/formatDateAndTime";

const { Option } = Select;

const Preview = ({ visible, onClose, siteVisitCardId }) => {
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({
    videoName: "",
    videoUrl: "",
  });
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore((prev) => !prev);
  };

  const { previewFiles } = useFilePreview();
  const { getVideoDetails } = useFilePreview();

  const openVideoModal = async (e, filePath, entityId) => {
    e.stopPropagation();
    e.preventDefault();

    const videoDetails = await getVideoDetails(filePath, entityId);
    if (videoDetails) {
      setCurrentVideo({
        videoName: videoDetails.videoName,
        videoUrl: videoDetails.videoUrl,
      });
      setVideoModalVisible(true);
    }
  };

  const fetchVideoDetails = async ({ entityId }) => {
    const details = await getVideoDetails("path/to/video.mp4", entityId);
    if (details) {
      console.log("Video Name:", details.videoName);
      console.log("Video URL:", details.videoUrl);
    }
  };

  const closeVideoModal = () => {
    setVideoModalVisible(false);
    setCurrentVideo("");
  };
  console.log(siteVisitCardId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [siteVisitCard, setSiteVisitCard] = useState({});
  const [filteredContacts, setFilteredContacts] = useState({
    siteContacts: [],
    pointOfContacts: [],
    KIIcontacts: [],
  });
  const enumerators = siteVisitCard?.logistic?.team?.enumerators || [];

  useEffect(() => {
    if (siteVisitCardId) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setSiteVisitCard(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [siteVisitCardId]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const contacts = siteVisitCard?.visit?.contacts || [];
    const siteContacts = contacts.filter(
      (contact) => contact.contactType === 1
    );
    const pointOfContacts = contacts.filter(
      (contact) => contact.contactType === 2
    );
    const KIIcontacts = contacts.filter((contact) => contact.contactType === 3);

    setFilteredContacts({
      siteContacts,
      pointOfContacts,
      KIIcontacts,
    });
  }, [siteVisitCard]);

  const renderMonth = (month) => {
    switch (month) {
      case "01":
        return "January";
        break;
      case "02":
        return "Febuary";
        break;
      case "03":
        return "March";
        break;
      case "04":
        return "April";
        break;
      case "05":
        return "May";
        break;
      case "06":
        return "June";
        break;
      case "07":
        return "July";
        break;
      case "08":
        return "August";
        break;
      case "09":
        return "September";
        break;
      case "10":
        return "October";
        break;
      case "11":
        return "November";
        break;
      case "12":
        return "December";
        break;
      default:
        return "Not set";
    }
  };

  const formatCoordinationType = (status) => {
    if (status === 1) return "Remote";
    if (status === 2) return "In-Person";
  };

  const fulcrumAccount = siteVisitCard?.logistic?.fulcrumAccount;

  const formattedUrl =
    fulcrumAccount &&
    (fulcrumAccount.startsWith("http://") ||
      fulcrumAccount.startsWith("https://"))
      ? fulcrumAccount
      : `http://${fulcrumAccount}`;

  const hasCategoryReportFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 1
  );

  const hasCategoryOtherFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 2
  );

  const hasCategoryVideoFiles = siteVisitCard?.siteVisitFiles?.some(
    (item) => item.fileCategory === 3 || item.fileCategory === 4
  );

  const checkSiteVisitStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        text = "No Status";
        break;
      case 2:
        className = "prePlanningPhase";
        fill = "var(--Primary-Blueberry-700, #325899)";
        text = "Pre-planning phase";
        break;
      case 3:
        className = "planning";
        fill = "#964B00";
        text = "Planning";
        break;
      case 4:
        className = "dataCollection";
        fill = "#3B81FE";
        text = "Data Collection";
        break;
      case 5:
        className = "dataCheck";
        fill = "#045B46";
        text = "Post data collection/Data check";
        break;
      case 6:
        className = "visitCompleted";
        fill = "#0F7507";
        text = "Visit Completed";
        break;
      default:
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        text = "No status";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };
  const checkReportingStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        text = "Data collection";
        className = "dataCollection";
        fill = "#3B81FE";
        break;
      case 2:
        text = "Data check";
        className = "dataCheck";
        fill = "#045B46";
        break;
      case 3:
        text = "Field reporting writing";
        className = "fieldReportWriting";
        fill = "var(--Range-assessment-Excellent, #3D9B4C)";
        break;
      case 4:
        text = "Translation";
        className = "translation";
        fill = "#918d27";
        break;
      case 5:
        text = "Field reporting completed";
        className = "fieldReportCompleted";
        fill = "#9670b8";
        break;
      case 6:
        text = "Field report approved";
        className = "fieldReportApproved";
        fill = "var(--Graph-Green-1, #06768E)";
        break;
      case 7:
        text = "Field report submitted to client";
        className = "fieldReportSubmitted";
        fill = "var(--Graph-Green-3, #6FC5D5)";
        break;
      default:
        text = "No status";
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <SiteVisitProgress
          visible={showHistoryModal}
          onClose={() => setShowHistoryModal(false)}
          siteVisitCard={siteVisitCard}
        />

        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>

      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Site visit identifier</span>
            }
          >
            <p className="previewText">{siteVisitCard?.identifier}</p>
          </Form.Item>
          <Form.Item
            name="name"
            label={<span style={{ fontWeight: "bold" }}>IP Plan Code</span>}
          >
            <p className="previewText">{siteVisitCard?.ipPlan?.ipPlanCode}</p>
          </Form.Item>

          {siteVisitCard?.name && (
            <Form.Item
              name="name"
              label={<span style={{ fontWeight: "bold" }}>Name</span>}
            >
              <p className="previewText">{siteVisitCard?.name}</p>
            </Form.Item>
          )}

          <hr />
          <p className="modalsHeader1">General details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="ip"
              label={
                <span style={{ fontWeight: "bold" }}>Implementing Partner</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {siteVisitCard?.ipPlan?.implementingPartner?.name}
              </p>
            </Form.Item>
            <Form.Item
              name="ip"
              label={
                <span style={{ fontWeight: "bold" }}>IP planning card</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.ipPlan?.identifier}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              cluster="cluster"
              label={<span style={{ fontWeight: "bold" }}>Cluster</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.cluster?.name}</p>
            </Form.Item>
            <Form.Item
              name="sector"
              label={<span style={{ fontWeight: "bold" }}>Sector</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{siteVisitCard?.sector?.name}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {siteVisitCard?.subSector && (
              <Form.Item
                name="subSector"
                label={<span style={{ fontWeight: "bold" }}>Subsector</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">{siteVisitCard?.subSector?.name}</p>
              </Form.Item>
            )}
          </div>

          <hr />
          <p className="modalsHeader1">Location Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="latitude"
              label={
                <span style={{ fontWeight: "bold" }}>Location on the map</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                Latitude: {siteVisitCard?.locationDetails?.latitude}° N,
                Longitude: {siteVisitCard?.locationDetails?.longitude}° W
              </p>
            </Form.Item>
            <Form.Item
              name="latitude"
              label={<span style={{ fontWeight: "bold" }}>Address</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.locationDetails?.address}
              </p>
            </Form.Item>
          </div>
          {siteVisitCard?.perish != "false" && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="latitude"
                label={<span style={{ fontWeight: "bold" }}>Parish</span>}
                style={{ width: "100%" }}
              >
                <p className="previewText">{siteVisitCard?.perish}</p>
              </Form.Item>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="isVirtual"
              label={
                <span style={{ fontWeight: "bold" }}>Virtual meeting? </span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.virtual ? "Yes" : "No"}
              </p>
            </Form.Item>
          </div>

          <hr />
          <p className="modalsHeader1">Visit details</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Form.Item
              name="visitStartTime"
              label={
                <span style={{ fontWeight: "bold" }}>Visit start time</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {formatDate(siteVisitCard?.visit?.startTime)}
              </p>
            </Form.Item> */}
            <Form.Item
              name="dateOfPlannedVisit"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Date of Planned Visit
                </span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {formatDateAndTime(siteVisitCard?.visit?.plannedVisit, "time")}
              </p>
            </Form.Item>
          </div>

          {filteredContacts?.siteContacts.length > 0 && (
            <>
              <hr />
              <p className="modalsHeader1 mt-4">Site Contacts</p>
              <div className="container mt-4">
                <div className="contactsWrapper">
                  {filteredContacts.siteContacts.map((contact, index) => (
                    <div className="contactsInner">
                      <h1>Site contact {index + 1}</h1>
                      <div className="contactDetails">
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Contact Name
                          </span>
                          <p>{contact.name}</p>
                        </div>

                        {contact?.email && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>Email</span>
                            <p className="previewtext">{contact.email}</p>
                          </div>
                        )}

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Role</span>
                          <p>{contact.role}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Organization
                          </span>
                          <p>{contact.organization}</p>
                        </div>

                        {contact?.phone && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>Phone</span>
                            <p>{contact.phone}</p>
                          </div>
                        )}

                        {contact?.skype && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>
                              Name of the tool
                            </span>
                            <p>{contact.skype}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          <hr />
          <p className="modalsHeader1  mt-4">Point of contacts</p>
          <div className="container  mt-4">
            <div className="contactsWrapper">
              {filteredContacts?.pointOfContacts &&
                filteredContacts.pointOfContacts.map((contact, index) => (
                  <div className="contactsInner  mb-4">
                    <div className="contactDetails ">
                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Contact Name</span>
                        <p>{contact.name}</p>
                      </div>

                      {contact?.email && (
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Email</span>
                          <p className="previewtext">{contact.email}</p>
                        </div>
                      )}

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Role</span>
                        <p>{contact.role}</p>
                      </div>

                      <div className="textWrapper">
                        <span style={{ fontWeight: "bold" }}>Organization</span>
                        <p>{contact.organization}</p>
                      </div>

                      {contact?.phone && (
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Phone</span>
                          <p>{contact.phone}</p>
                        </div>
                      )}

                      {contact?.skype && (
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Name of the tool
                          </span>
                          <p>{contact.skype}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <p className="modalsHeader1 mt-4">Sample size</p>
          <div className="textWrapper">
            <span style={{ fontWeight: "bold" }}>Beneficiaries</span>
            <p>{siteVisitCard?.visit?.interviewDetails?.beneficiariesCount}</p>
          </div>
          <div className="textWrapper">
            <span style={{ fontWeight: "bold" }}>KIIs</span>
            <p>{siteVisitCard?.visit?.interviewDetails?.clientsCount}</p>
          </div>
          {filteredContacts?.KIIcontacts.length > 0 && (
            <div className="mb-4">
              <hr />
              <p className="modalsHeader1 mt-4">KII Contacts</p>
              <div className="container mt-4">
                <div className="contactsWrapper">
                  {filteredContacts.KIIcontacts.map((contact, index) => (
                    <div className="contactsInner">
                      <h1>KII contact {index + 1}</h1>
                      <div className="contactDetails">
                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Contact Name
                          </span>
                          <p>{contact.name}</p>
                        </div>

                        {contact?.email && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>Email</span>
                            <p className="previewtext">{contact.email}</p>
                          </div>
                        )}

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>Role</span>
                          <p>{contact.role}</p>
                        </div>

                        <div className="textWrapper">
                          <span style={{ fontWeight: "bold" }}>
                            Organization
                          </span>
                          <p>{contact.organization}</p>
                        </div>

                        {contact?.phone && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>Phone</span>
                            <p>{contact.phone}</p>
                          </div>
                        )}

                        {contact?.skype && (
                          <div className="textWrapper">
                            <span style={{ fontWeight: "bold" }}>
                              Name of the tool
                            </span>
                            <p>{contact.skype}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="textWrapper">
            <span style={{ fontWeight: "bold" }}>Observation checklist</span>
            <p>
              {siteVisitCard?.visit?.interviewDetails?.observationChecklist}
            </p>
            {siteVisitCard?.visit?.interviewDetails
              ?.observationChecklistType && (
              <>
                <span style={{ fontWeight: "bold" }}>
                  What type of observation checklist?
                </span>
                <p>
                  {
                    siteVisitCard?.visit?.interviewDetails
                      ?.observationChecklistType
                  }
                </p>
              </>
            )}
          </div>

          <p className="modalsHeader1 mt-4">Media</p>
          <div className="mb-4">
            <div className="container mt-4">
              <div className="contactsWrapper">
                <div className="contactsInner">
                  <div className="contactDetails">
                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Videos?</span>
                      <p>{siteVisitCard?.hasVideos ? "Yes" : "No"}</p>
                    </div>

                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>How many?</span>
                      <p className="previewtext">{siteVisitCard?.videoCount}</p>
                    </div>
                  </div>
                </div>
                <div className="contactsInner">
                  <div className="contactDetails">
                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>Photos?</span>
                      <p>{siteVisitCard?.hasPhotos ? "Yes" : "No"}</p>
                    </div>

                    <div className="textWrapper">
                      <span style={{ fontWeight: "bold" }}>How many?</span>
                      <p className="previewtext">{siteVisitCard?.photoCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {siteVisitCard?.specificsOfOnSiteOperation !== "null" &&
            siteVisitCard?.specificsOfOnSiteOperation !== "undefined" &&
            siteVisitCard?.specificsOfOnSiteOperation !== "" && (
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Specifics of on-site operation
                  </span>
                }
              >
                <p className="previewText">
                  {siteVisitCard?.specificsOfOnSiteOperation}
                </p>
              </Form.Item>
            )}
          {siteVisitCard?.visit?.interviewDetails?.samplingInstructions && (
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Sampling instructions (beneficiaries, sample, profile,
                  selection method, etc)
                </span>
              }
            >
              <p className="previewText">
                {siteVisitCard?.visit?.interviewDetails?.samplingInstructions}
              </p>
            </Form.Item>
          )}

          {siteVisitCard?.visit?.interviewDetails?.clientInstructions ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Instructions for any KIIs
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.clientInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          {siteVisitCard?.visit?.interviewDetails?.otherInstructions ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Any other instructions
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.visit?.interviewDetails?.otherInstructions}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}
          <hr />
          <p className="modalsHeader1">Logistics</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>TPM Coordinator</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.tpmCoordinator?.firstName &&
                siteVisitCard?.logistic?.tpmCoordinator?.lastName
                  ? `${siteVisitCard?.logistic?.tpmCoordinator?.firstName} ${siteVisitCard?.logistic?.tpmCoordinator?.lastName}`
                  : siteVisitCard?.logistic?.tpmCoordinator?.email
                  ? ` ${siteVisitCard?.logistic?.tpmCoordinator?.email}`
                  : ""}
              </p>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>Coordination Type</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {formatCoordinationType(
                  siteVisitCard?.logistic?.coordinationType
                )}
              </p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>
                  Account/Data collection platform
                </span>
              }
              style={{ width: "100%" }}
            >
              <a href={formattedUrl} target="_blank" rel="noopener noreferrer">
                {formattedUrl}
              </a>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={
                <span style={{ fontWeight: "bold" }}>Activation Date</span>
              }
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {formatDateAndTime(siteVisitCard?.logistic?.activationDate)}
              </p>
            </Form.Item>
          </div>
          <hr />

          <p className="modalsHeader1">Team</p>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Team name</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.team?.name}
              </p>
            </Form.Item>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Team is local</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.isTeamLocal ? "Yes" : "No"}
              </p>
            </Form.Item>
          </div>
          <div className="teamDetails mb-5">
            <Card title="Team Details" className="teamDetailsCard">
              {siteVisitCard?.logistic?.team?.teamLead && (
                <div>
                  <h1 style={{ fontSize: "16px", margin: "0" }}>
                    {siteVisitCard?.logistic?.team?.teamLead?.firstName &&
                    siteVisitCard?.logistic?.team?.teamLead?.lastName
                      ? `${siteVisitCard?.logistic?.team?.teamLead?.firstName} ${siteVisitCard?.logistic?.team?.teamLead?.lastName}`
                      : siteVisitCard?.logistic?.team?.teamLead?.email}
                  </h1>
                  <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                    Team Leader
                  </p>
                </div>
              )}
              <Divider />
              {siteVisitCard?.logistic?.team?.teamCoordinator && (
                <div>
                  <h1 style={{ fontSize: "16px", margin: "0" }}>
                    {siteVisitCard?.logistic?.team?.teamCoordinator
                      ?.firstName &&
                    siteVisitCard?.logistic?.team?.teamCoordinator?.lastName
                      ? `${siteVisitCard?.logistic?.team?.teamCoordinator?.firstName} ${siteVisitCard?.logistic?.team?.teamCoordinator?.lastName}`
                      : siteVisitCard?.logistic?.team?.teamCoordinator?.email}
                  </h1>
                  <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                    Field Coordinator
                  </p>
                </div>
              )}

              {siteVisitCard?.logistic?.team?.drivers?.map((user, index) => (
                <div key={index}>
                  <h1 style={{ fontSize: "16px", margin: "0" }}>
                    {user?.name}
                  </h1>
                  <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                    Driver
                  </p>
                </div>
              ))}
              <Divider />
              {siteVisitCard?.logistic?.team?.enumerators?.map(
                (user, index) => (
                  <div key={index}>
                    <h1 style={{ fontSize: "16px", margin: "0" }}>
                      {user?.firstName && user?.lastName
                        ? `${user?.firstName} ${user?.lastName}`
                        : user?.email}
                    </h1>
                    <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                      Enumerator
                    </p>
                  </div>
                )
              )}
            </Card>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="observationList"
              label={<span style={{ fontWeight: "bold" }}>Postponed</span>}
              style={{ width: "100%" }}
            >
              <p className="previewText">
                {siteVisitCard?.logistic?.isPostponed ? "Yes" : "No"}
              </p>
            </Form.Item>
            {siteVisitCard?.logistic?.isPostponed ? (
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>Postponed reason</span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.logistic?.postponedReason}
                </p>
              </Form.Item>
            ) : (
              ""
            )}
          </div>
          {siteVisitCard?.logistic?.isPostponed ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>Postponed details</span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">
                  {siteVisitCard?.logistic?.postponedDetails}
                </p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <Form.Item
            name="siteVisitStatus"
            label={
              <span style={{ fontWeight: "bold" }}>Site Visit Status</span>
            }
            style={{ width: "100%" }}
          >
            <p className="previewText implementationPage">
              {checkSiteVisitStatus(siteVisitCard?.siteVisitStatus)}
            </p>
          </Form.Item>
          {siteVisitCard?.logistic?.notes ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="observationList"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Any other notes (Logistics/Coordination)
                  </span>
                }
                style={{ width: "100%" }}
              >
                <p className="previewText">{siteVisitCard?.logistic?.notes}</p>
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <hr />

          {siteVisitCard?.siteVisitFiles?.length > 0 && (
            <p className="modalsHeader1">Resources</p>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {hasCategoryReportFiles && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "48%",
                }}
              >
                <span style={{ fontWeight: "bold" }} className="mb-2">
                  Site visit report documents
                </span>
                {siteVisitCard?.siteVisitFiles
                  ?.filter((item) => item.fileCategory === 1)
                  .map((file, index) => (
                    <a
                      type="button"
                      onClick={(e) => previewFiles(e, file.filePath, file.id)}
                      key={index}
                      rel="noopener noreferrer"
                      className="resourcesText mb-4"
                    >
                      {file?.fileName}
                    </a>
                  ))}
              </div>
            )}
            {hasCategoryOtherFiles && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "48%",
                }}
              >
                <span style={{ fontWeight: "bold" }} className="mb-2">
                  Other documents
                </span>
                {siteVisitCard?.siteVisitFiles
                  ?.filter((item) => item.fileCategory === 2)
                  .map((file, index) => (
                    <a
                      key={index}
                      type="button"
                      onClick={(e) => previewFiles(e, file.filePath, file.id)}
                      rel="noopener noreferrer"
                      className="resourcesText mb-4"
                    >
                      {file?.fileName}
                    </a>
                  ))}
              </div>
            )}
          </div>
          {hasCategoryVideoFiles && (
            <div className="mb-5" style={{ marginTop: "20px", width: "48%" }}>
              <span style={{ fontWeight: "bold" }} className="mb-2">
                Site video file
              </span>{" "}
              {siteVisitCard?.siteVisitFiles
                ?.filter(
                  (item) => item.fileCategory === 3 || item.fileCategory === 4
                )
                .map((file, index) => (
                  <div
                    key={index}
                    onClick={(e) =>
                      file?.fileName?.endsWith("mp4")
                        ? openVideoModal(e, file.filePath, file.id)
                        : previewFiles(e, file.filePath, file.id)
                    }
                    // onClick={(e) => previewFiles(e, file.filePath, file.id)}
                    style={{ cursor: "pointer" }}
                    className="resourcesText mb-4"
                  >
                    {file?.fileName}
                  </div>
                ))}
            </div>
          )}
          {siteVisitCard.tracking && (
            <>
              <p className="modalsHeader1">Tracking</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="reportingYear"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting year</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.reportingYear}
                  </p>
                </Form.Item>
                <Form.Item
                  name="reportingMonth"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting month</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {renderMonth(siteVisitCard?.tracking?.reportingMonth)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="trainingIsCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Training Completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.trainingIsCompleted
                      ? "Yes"
                      : "No"}
                  </p>
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="trainingDate"
                  label={
                    <span style={{ fontWeight: "bold" }}>Training date</span>
                  }
                  style={{ width: "33%" }}
                >
                  <p className="previewText">
                    {formatDateAndTime(siteVisitCard?.tracking?.trainingDate)}
                  </p>
                </Form.Item>
              </div>
              {siteVisitCard?.logistic?.team?.enumerators?.length > 0 && (
                <>
                  <p className="modalsHeader1" style={{ fontSize: "14px" }}>
                    Training score info
                  </p>
                  <div className="w-75 d-flex flex-column mb-5">
                    <div className="d-flex flex-nowrap flex-row justify-content-between px-3 me-5">
                      <p
                        style={{
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                        }}
                        className="modalsHeader1"
                      >
                        Enumerator
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                        }}
                        className="modalsHeader1 me-4"
                      >
                        Score
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      {siteVisitCard?.logistic?.team?.enumerators?.map(
                        (enumerator) => (
                          <div
                            key={enumerator?.id}
                            style={{
                              display: "flex",
                              padding: "10px 16px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "24px",
                              borderRadius: "8px",
                              border: "1px solid var(--5, #F5B0D2)",
                              background: "var(--7, #FFF5FA)",
                            }}
                            className="d-flex flex-row justify-content-start"
                          >
                            <p
                              className="m-0 p-0 w-75"
                              style={{
                                color: "var(--Neutrals-Black, #3A3737)",
                                fontFamily: "Lato",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "22px",
                              }}
                            >
                              {enumerator?.firstName && enumerator?.lastName
                                ? enumerator?.firstName +
                                  " " +
                                  enumerator?.lastName
                                : enumerator?.email}
                            </p>
                            <p
                              className="m-0 p-0"
                              style={{
                                color: "var(--Neutrals-Black, #3A3737)",
                                fontFamily: "Lato",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "22px",
                              }}
                            >
                              10
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}

              <p className="modalsHeader1" style={{ fontSize: "14px" }}>
                % of data collection completed
              </p>
              <div className="w-75 d-flex flex-column mb-5">
                <div className="d-flex flex-nowrap flex-row justify-content-between px-3">
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1"
                  >
                    Enumerator
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1 me-3"
                  >
                    % completed
                  </p>
                </div>
                <div className="d-flex flex-column gap-3">
                  {siteVisitCard?.logistic?.team?.enumerators?.map(
                    (enumerator) => (
                      <div
                        key={enumerator?.id}
                        style={{
                          display: "flex",
                          padding: "10px 16px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "24px",
                          borderRadius: "8px",
                          border: "1px solid var(--5, #F5B0D2)",
                          background: "var(--7, #FFF5FA)",
                        }}
                        className="d-flex flex-row justify-content-start"
                      >
                        <p
                          className="m-0 p-0 w-75"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          {enumerator?.firstName && enumerator?.lastName
                            ? enumerator?.firstName + " " + enumerator?.lastName
                            : enumerator?.email}
                        </p>
                        <p
                          className="m-0 p-0"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          10%
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Form.Item
                  name="fieldTeamReportingStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting Status</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText implementationPage ">
                    {checkReportingStatus(
                      siteVisitCard?.tracking?.fieldTeamReportingStatus
                    )}
                  </p>
                </Form.Item>
              </div>
            </>
          )}

          <button
            onClick={() => setShowHistoryModal(true)}
            className="historyButton"
          >
            View Site Visit History{" "}
            <img src={downArrow} alt="View history details..." />
          </button>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>

      <PreviewVideoModal
        visible={videoModalVisible}
        handleCancel={() => setVideoModalVisible(false)}
        fileName={currentVideo.videoName}
        videoUrl={currentVideo.videoUrl}
      />
    </Modal>
  );
};

export default Preview;
