import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Tooltip,
  Space,
  Divider,
  Spin,
  Progress,
} from "antd";
import "./style.scss";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import FormItem from "antd/es/form/FormItem";
import PlanningIcon from "../../../../Assets/svg/iconMenu2.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../Assets/svg/exclamationMark.svg";
import { httpService } from "../../../../services/httpService.service";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { PlusOutlined } from "@ant-design/icons";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";
import dayjs from "dayjs";
import axios from "axios";
import { debounce } from "lodash";
import { useFilePreview } from "../../completed/ReportingList/Download";

const { Option } = Select;
const EditDraftsModal = ({
  visible,
  onClose,
  setMessage,
  onEdit,
  record,
  onOk,
}) => {
  const [loading, setLoading] = useState(false);
  const [ipPlanInputId, setIpPlanInputId] = useState("");
  const [form] = Form.useForm();
  const projectID = localStorage.getItem("currentProjectID");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const inputRef = useRef(null);
  const [clusters, setClusters] = useState([]);
  const [newCluster, setNewCluster] = useState("");
  const [addingCluster, setAddingCluster] = useState(false);
  const [selectedCityIds, setSelectedCityIds] = useState();
  const [users, setUsers] = useState();
  const [implementingPartners, setImplementingPartners] = useState();
  const [initialValues, setInitialValues] = useState(null);
  const startYear = 2024;
  const endYear = 2034;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );
  const [selectedStateId, setSelectedStateId] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [selectedIp, setSelectedIp] = useState({});
  const [savingProgress, setSavingPorgres] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [filesToRemoveKeys, setFilesToRemoveKeys] = useState([]);
  const { previewFiles } = useFilePreview();

  const deleteFilesFromDB = async (fileKeys) => {
    await axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      })
      .finally(() => setFilesToRemoveKeys([]));
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((files) => {
    const newFiles = files.reduce((acc, file) => {
      if (!fileList.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingFiles(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingFiles([]);
        setFileList((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingFiles((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, 300);

  const handleFileChange = ({ fileList }) => {
    setLoading(true);
    debouncedHandleUploadChange(fileList);
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    onClose();
    setInitialValues(null);
    setCities([]);
    setFileList([]);
    setExistingFiles([]);
    setIpPlanInputId("");
    setFilesToRemoveKeys([]);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    setLoading(true);
    if (record && visible) {
      httpService
        .get(
          `/api/Draft/getDraftById?id=${record}`,
          (res) => {
            const ipPlan = JSON.parse(res?.data?.jsonData);
            console.log("ipPlanObject:", ipPlan);

            // Handle cities and states based on IP data
            const implementingPartner = ipPlan?.implementingPartner;
            const cities = ipPlan?.cityId;
            const stateIds = ipPlan?.StateIds;

            if (implementingPartner) {
              handleIpChange(implementingPartner?.id);
              setCities(implementingPartner?.cities);
              setStates(implementingPartner?.states);
            } else {
              handleIpChange(null);
            }

            setSelectedCityIds(cities || []);
            setSelectedStateId(stateIds || []);

            setIpPlanInputId(ipPlan?.ipPlanId);
            const initialData = {
              id: ipPlan?.id,
              ipPlanId: ipPlan?.ipPlanId,
              name: ipPlan?.name || undefined,
              ImplementingPartnerId:
                ipPlan?.implementingPartner?.id || undefined,
              description: ipPlan?.description || undefined,
              programDescription: ipPlan?.programDescription || undefined,
              state: stateIds || [],
              city: ipPlan?.cityId || undefined,
              AssignedTo: ipPlan?.assignedTo?.id || undefined,
              cluster: ipPlan?.clusterId?.map((cluster) => cluster.id) || [],
              generalInfo: ipPlan?.generalInfo || undefined,
              contactSkype: ipPlan?.pointOfContact?.skype || undefined,
              contactEmail: ipPlan?.pointOfContact?.email || undefined,
              contactName: ipPlan?.pointOfContact?.name || undefined,
              contactOrganization:
                ipPlan?.pointOfContact?.organisation || undefined,
              contactRole: ipPlan?.pointOfContact?.role || undefined,
              contactPhone: ipPlan?.pointOfContact?.phone || undefined,
              postponeReason: ipPlan?.reasonOfPostponed || undefined,
              Month: ipPlan?.month || undefined,
              Year: ipPlan?.year || undefined,
              currentIpStatus:
                (ipPlan?.currentIpStatus && Number(ipPlan?.currentIpStatus)) ||
                undefined,
              ipDonorReportingStatus: ipPlan?.ipDonorReportingEnum || undefined,
              InitialMeeting: ipPlan?.initialMeeting
                ? dayjs(ipPlan.initialMeeting)
                : null,
            };

            setExistingFiles(ipPlan?.fileList);
            setInitialValues(initialData);
            form.setFieldsValue(initialData);
          },
          (error) => {
            console.error("Error fetching data:", error);
            // Provide user feedback here if needed
            setMessage({
              trigger: true,
              type: "danger",
              title: "Error Fetching Data",
              text: "There was an error retrieving the draft data. Please try again.",
            });
          }
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [record, form, visible]);

  useEffect(() => {
    fetchClusterData();
  }, []);

  const fetchClusterData = () => {
    setLoading(true);
    httpService.get(
      "/api/Site/getAllClusters",
      (res) => setClusters(res.data),
      (err) => console.log(err)
    );
    setLoading(false);
  };

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      setLoading(true);
      httpService.post(
        "/api/Site/createCluster",
        {
          name: newCluster,
        },
        (response) => {
          const message =
            response.message ||
            `A new cluster has been created and added to your database!`;
          fetchClusterData();
          setNewCluster("");
          setAddingCluster(false);
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: `Cluster added successfully!`,
            text: message,
          });
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            `We had a problem adding this cluster to your database, please try again.`;
          console.error(`Error adding new item to:`, error);
          setMessage({
            trigger: true,
            type: "danger",
            icon: ExclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      );
      setLoading(false);
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClusterSelectChange = () => {
    setAddingCluster(false);
  };

  useEffect(() => {
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => setUsers(res.data),
      (error) => console.log(error)
    );

    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (err) => console.log(err)
    );
  }, []);

  const handleIpChange = (implementingPartnerId) => {
    setSelectedIp({});
    setSelectedStateId([]);
    setSelectedCityIds([]);
    setCities([]);

    form.setFieldsValue({
      city: undefined,
      state: undefined,
    });

    setLoadingStates(true);
    if (implementingPartnerId) {
      const selectedIp = implementingPartners.find(
        (ip) => ip.id === implementingPartnerId
      );
      setSelectedIp(selectedIp);
      const states = selectedIp?.states;
      setStates(states);
      const cities = selectedIp?.cities;
      if (selectedIp?.cities?.length > 0) {
        setCities(cities);
      }
    }
    setTimeout(() => {
      setLoadingStates(false);
    }, 500);
  };

  const handleCityChange = (cityIds) => {
    setSelectedCityIds(cityIds);
  };

  const handleResourceRemove = (file) => {
    deleteFilesFromDB([file.fileKey]);
    const updatedFileList = fileList?.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handlIpPlanResourceRemove = (fileToRemove) => {
    const fileKey = fileToRemove?.fileKey;
    setFilesToRemoveKeys([...filesToRemoveKeys, fileKey]);
    setExistingFiles((prevFiles) =>
      prevFiles.filter((item) => item.fileKey !== fileToRemove.fileKey)
    );
  };

  const handleDelete = async () => {
    if (record) {
      const requestBody = [
        {
          id: record,
        },
      ];
      const url = `/api/Draft/deleteDrafts`;
      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append("Id", record);
        if (values.ipPlanId) {
          formData.append("IPPLanCode", values.ipPlanId);
        }
        formData.append("ImplementingPartnerId", values.ImplementingPartnerId);

        selectedStateId?.forEach((stateId) => {
          formData.append("stateIds", stateId);
        });
        selectedCityIds?.forEach((cityId) => {
          formData.append("CityIds", cityId);
        });
        formData.append("ProjectId", projectID);
        formData.append("AssignedTo", values.AssignedTo);
        values.cluster?.forEach((cluster, index) =>
          formData.append(`Clusters[${index}]`, cluster)
        );
        formData.append(
          "PlanningRound.ProgramDescription",
          values.programDescription
        );
        formData.append("generalInfo", values.generalInfo || "");
        formData.append(
          "InitialMeeting",
          values.InitialMeeting
            ? values.InitialMeeting.format("YYYY-MM-DD")
            : null
        );
        if (values.Month) formData.append("PlanningRound.Month", values.Month);
        if (values.Year) formData.append("PlanningRound.Year", values.Year);
        if (values.contactName)
          formData.append("PointOfContact.Name", values.contactName);
        if (values.contactEmail)
          formData.append("PointOfContact.Email", values.contactEmail);
        if (values.contactRole)
          formData.append("PointOfContact.Role", values.contactRole);
        if (values.contactPhone)
          formData.append("PointOfContact.Phone", values.contactPhone);
        if (values.contactSkype)
          formData.append("PointOfContact.Skype", values.contactSkype);
        if (values.contactOrganization)
          formData.append(
            "PointOfContact.Organization",
            values.contactOrganization
          );

        formData.append("PointOfContact.ContactType", 2);
        formData.append("reasonOfPostponed", values.postponeReason || "");
        formData.append("CurrentIPStatus", values.currentIpStatus || "");
        formData.append(
          "IPDonorReportingEnum",
          values.ipDonorReportingStatus || 1
        );

        existingFiles &&
          existingFiles?.forEach((file, index) => {
            formData.append(`Files[${index}].fileName`, file?.fileName);
            formData.append(`Files[${index}].fileKey`, file?.fileKey);
            formData.append(`Files[${index}].fileSize`, file?.fileSize);
            formData.append(`Files[${index}].FileStatus`, 1);
          });

        fileList &&
          fileList?.forEach((file, index) => {
            const newIndex = existingFiles?.length || 0 + index;
            formData.append(`Files[${newIndex}].fileName`, file?.name);
            formData.append(`Files[${newIndex}].fileKey`, file?.fileKey);
            formData.append(`Files[${newIndex}].fileSize`, file?.size);
            formData.append(`Files[${newIndex}].FileStatus`, 1);
          });

        httpService.upload(
          "/api/IPPlan",
          formData,
          (response) => {
            if (filesToRemoveKeys?.length) {
              deleteFilesFromDB(filesToRemoveKeys);
            }
            const message =
              response.message ||
              "A new project has been created and added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: PlanningIcon,
              title: "Project added successfully!",
              text: message,
            });
            setTimeout(() => {
              setMessage({ trigger: false });
            }, 3000);
            handleDelete();
            setTimeout(() => {
              onEdit();
            }, 1000);
            handleLeave();
            setLoading(false);
          },
          (error) => {
            console.error("There was an error adding the project!", error);
            let errorMessage =
              error?.message ||
              error?.errors?.[0]?.message ||
              "We had a problem adding this IP Plan Card from your database, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: PlanningIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({ trigger: false });
            }, 3000);
            setLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: PlanningIcon,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo?.errorFields?.length && errorInfo?.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
        }, 3000);
        setLoading(false);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields?.[0];
    if (firstErrorField) {
      form?.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  // const handleResourceIpPlan = (fileToRemove) => {
  //   setExistingFiles((prevFiles) =>
  //     prevFiles.filter((file) => file?.fileName !== fileToRemove?.fileName)
  //   );
  // };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const handleSaveProgress = () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: PlanningIcon,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    const filesData =
      fileList?.map((file) => ({
        fileName: file?.name,
        fileKey: file?.fileKey,
        fileSize: file?.size,
        fileStatus: 1,
      })) || [];

    let selectedIp;
    if (values?.ImplementingPartnerId) {
      selectedIp = implementingPartners?.find(
        (ip) => ip.id === values?.ImplementingPartnerId
      );
    }

    let selectedClusters;
    if (values.cluster) {
      selectedClusters = clusters?.filter((cluster) =>
        values.cluster.includes(cluster.id)
      );
    }

    let slectedUsers;
    if (values.AssignedTo) {
      slectedUsers = users?.find((user) => user.id === values.AssignedTo);
    }

    const ipPlanDraftData = {
      implementingPartner: selectedIp,
      ...(values.ipPlanId && {
        ipPlanId: values.ipPlanId,
      }),
      ...(selectedStateId &&
        selectedStateId.length && {
          StateIds: selectedStateId,
        }),
      ...(selectedCityIds && {
        cityId: selectedCityIds,
      }),
      ...(slectedUsers && {
        assignedTo: slectedUsers,
      }),
      ...(selectedClusters && {
        clusterId: selectedClusters,
      }),
      ...(values.generalInfo && { generalInfo: values.generalInfo }),
      ...(values?.programDescription && {
        programDescription: values.programDescription,
      }),
      ...(values.InitialMeeting && { initialMeeting: values.InitialMeeting }),
      ...(values.Month && { month: values.Month }),
      ...(values.Year && { year: values.Year }),
      ...(values.contactName && {
        pointOfContact: {
          name: values.contactName,
          ...(values.contactRole && { role: values.contactRole }),
          ...(values.contactEmail && { email: values.contactEmail }),
          ...(values.contactPhone && {
            organisation: values.contactPhone,
            phone: values.contactPhone,
          }),
          ...(values.contactSkype && { skype: values.contactSkype }),
        },
      }),
      ...(values.currentIpStatus && {
        currentIpStatus: values.currentIpStatus,
      }),
      ...(filesData?.length || existingFiles?.length
        ? { fileList: [...(filesData || []), ...(existingFiles || [])] }
        : {}),
    };

    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(ipPlanDraftData);
    const payload = {
      id: record,
      ProjectId: projectId,
      jsonData: jsonData,
      type: 2,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          if (filesToRemoveKeys?.length) {
            deleteFilesFromDB(filesToRemoveKeys);
          }
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: PlanningIcon,
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          setFileList([]);
          form.resetFields();
          onOk();
          handleLeave();
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: PlanningIcon,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 1000);
      });
  };

  const handleStateChange = (stateIds) => {
    setSelectedStateId(stateIds);
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      destroyOnClose={false}
      open={visible}
      closable={false}
      width={800}
      onCancel={handleCancel}
      footer={[
        <Form.Item>
          <Button
            key="back"
            loading={loading || savingProgress}
            onClick={handleSaveProgress}
            className="inviteButtonTPM"
          >
            Save progress
          </Button>
        </Form.Item>,
        <Form.Item>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            className="addButtonTPM"
            loading={loading || savingProgress}
          >
            Add IP Plan
          </Button>
        </Form.Item>,
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Add IP Plan Card</h2>
      <Spin spinning={!initialValues}>
        <div className="draftHeaderText">
          <p>
            This is a draft of the IP plan card. Once you finish editing, click
            'Add IP plan card' to add it as a new IP plan card, or save your
            progress.
          </p>
        </div>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="IP Plan Card Identifier" name="identifier">
            <div className="form-control-plaintext">
              <p
                style={{
                  border: "2px solid #d9d9d9",
                  borderRadius: "10px",
                  padding: "8px 12px",
                  fontSize: "14px",
                  color: "#495057",
                }}
              >
                <Tooltip title="This number will be generated automatically!">
                  <span>00X - </span>
                </Tooltip>
                <Tooltip title="Selected Implementing Partner!">
                  <span>
                    {selectedIp?.name !== undefined
                      ? selectedIp?.name
                      : "Implementing Partner"}
                  </span>
                </Tooltip>
                <Tooltip title="Created date!">
                  <span>{`- ${formattedDate}`}</span>
                </Tooltip>
              </p>
            </div>
          </Form.Item>
          <Form.Item
            label="IP Project Code (optional)"
            name="ipPlanId"
            rules={[
              {
                maxLength: 10,
                message: "Input must be maximum length 10 characters long!",
              },
            ]}
          >
            <Input
              style={{ width: "140px" }}
              onChange={(e) => setIpPlanInputId(e.target.value)}
              maxLength={10}
              placeholder="###"
            />
          </Form.Item>
          <h3>General details</h3>
          <Form.Item
            name="ImplementingPartnerId"
            label="Implementing Partner"
            rules={[{ required: true, message: "Select an IP" }]}
          >
            <Select
              placeholder="Select an IP"
              showSearch
              allowClear
              onChange={handleIpChange}
            >
              {implementingPartners &&
                implementingPartners?.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="firstSection">
            <div className="row">
              {isEmptyObject(selectedIp) ? (
                ""
              ) : (
                <Spin spinning={loadingStates}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      name="country"
                      label="Country"
                      placeholder="Select a country"
                      style={{ width: "48%" }}
                    >
                      <p className="previewText my-2">
                        {selectedIp?.states?.[0]?.country?.stateName}
                      </p>
                    </Form.Item>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please select one or more states",
                        },
                      ]}
                      tooltip={
                        cities?.length < 1
                          ? "Cities selected for this implementing partner!"
                          : "States selected for this implementing partner!"
                      }
                      label={
                        cities?.length < 1
                          ? "City/Disctrict/Municipality"
                          : "Governorate/State"
                      }
                      style={{ width: "48%" }}
                    >
                      <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) === 0
                        }
                        placeholder="Select one or more states"
                        onChange={handleStateChange}
                        disabled={states && !states?.length}
                      >
                        {states.map((state) => (
                          <Option key={state.id} value={state.id}>
                            {state.stateName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="city"
                    label="City/Disctrict/Municipality"
                    className={cities?.length > 0 ? "" : "d-none"}
                    rules={[
                      {
                        required: cities?.length,
                        message: "Please select a city",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      disabled={cities?.length < 1}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) === 0
                      }
                      placeholder="Select a city"
                      onChange={handleCityChange}
                    >
                      {cities?.map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Spin>
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="AssignedTo"
                  label="Assigned to a field coordinator"
                  rules={[
                    {
                      required: true,
                      message: "Select a person to assign the plan",
                    },
                  ]}
                  style={{ width: "48%" }}
                >
                  <Select
                    placeholder="Select a person to assign the plan"
                    allowClear
                    showSearch
                  >
                    {users &&
                      users?.map((user) => (
                        <Option key={user.id} value={user.id}>
                          {user.userDetails?.firstName &&
                          user.userDetails?.lastName
                            ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                            : user.userName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                label="Clusters"
                name="cluster"
                rules={[
                  {
                    required: true,
                    message: "Please select a cluster",
                  },
                ]}
              >
                <Select
                  width={100}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select an item"
                  mode="multiple"
                  // disabled={loading}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: "8px 0" }} />
                      {addingCluster ? (
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Enter new cluster name..."
                            ref={inputRef}
                            value={newCluster}
                            onChange={(e) => setNewCluster(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            type="text"
                            loading={loading}
                            icon={<PlusOutlined />}
                            onClick={addCluster}
                          >
                            Add cluster
                          </Button>
                        </Space>
                      ) : (
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            cursor: "pointer",
                            color: "var(--2, #AA1A5F)",
                          }}
                          onClick={() => setAddingCluster(true)}
                        >
                          <PlusOutlined /> Add new cluster
                        </Space>
                      )}
                    </>
                  )}
                  onChange={handleClusterSelectChange}
                  onDropdownVisibleChange={(open) => {
                    !open && setAddingCluster(false);
                    setNewCluster();
                  }}
                  options={clusters?.map((cluster) => ({
                    label: cluster.name,
                    value: cluster.id,
                  }))}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="generalInfo"
            label="General information about on-site operations"
            rules={[
              { required: true, message: "General information is required" },
            ]}
          >
            <Input.TextArea placeholder="Enter general information about operations" />
          </Form.Item>
          <Form.Item
            name="programDescription"
            label="IP Program Activities Description"
          >
            <Input.TextArea placeholder="Enter a description of the program" />
          </Form.Item>

          <div className="secondSection">
            <div className="row">
              <div className="column">
                <Form.Item
                  name="InitialMeeting"
                  label="Initial Meeting"
                  rules={[
                    { required: true, message: "Initial Meeting is required" },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="assignedToRoundLabel py-3 pb-2">
            <span>Assigned to round</span>
            <Tooltip title="Information about assignment to rounds">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div className="row">
            <div className="column">
              <Form.Item
                name="Month"
                label="Month"
                rules={[{ required: true, message: "Month is required" }]}
              >
                <Select
                  placeholder="Select month"
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option value={1}>January</Option>
                  <Option value={2}>February</Option>
                  <Option value={3}>March</Option>
                  <Option value={4}>April</Option>
                  <Option value={5}>May</Option>
                  <Option value={6}>June</Option>
                  <Option value={7}>July</Option>
                  <Option value={8}>August</Option>
                  <Option value={9}>September</Option>
                  <Option value={10}>October</Option>
                  <Option value={11}>November</Option>
                  <Option value={12}>December</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item
                name="Year"
                label="Year"
                rules={[{ required: true, message: "Year is required" }]}
              >
                <Select
                  showSearch
                  placeholder="Select year"
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  allowClear
                >
                  {years?.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="assignedToRoundLabel">
            <span>IP Point of contact</span>
            <Tooltip title="Information about assignment to rounds">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="column">
                <Form.Item
                  name="contactName"
                  label="Contact Name"
                  rules={[
                    { required: true, message: "Contact Name is required" },
                  ]}
                >
                  <Input placeholder="Enter your name" />
                </Form.Item>
                <Form.Item name="contactEmail" label="Email">
                  <Input placeholder="Enter your email" />
                </Form.Item>
              </div>

              <div className="column">
                <Form.Item
                  name="contactRole"
                  label="Role"
                  rules={[{ required: true, message: "Please enter role" }]}
                >
                  <Input placeholder="Enter role" />
                </Form.Item>
                <Form.Item
                  name="contactOrganization"
                  label="Organization"
                  rules={[
                    { required: true, message: "Organization is required" },
                  ]}
                >
                  <Input placeholder="Enter your organization" />
                </Form.Item>
              </div>
              <div className="column">
                <Form.Item
                  name="contactPhone"
                  label="Phone"
                  rules={[
                    { required: true, message: "Phone number is required" },
                  ]}
                >
                  <Input placeholder="Enter your phone number" />
                </Form.Item>
              </div>
              <div className="column">
                <Form.Item name="contactSkype" label="Name of the tool">
                  <Input placeholder="Enter your Name of the tool" />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="column">
              <FormItem
                name="currentIpStatus"
                label="Current IP status"
                rules={[{ required: true, message: "Field is required" }]}
              >
                <Select
                  placeholder="Select an IP status"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Field is required" }]}
                  allowClear
                >
                  <Option value={1}>Discussion</Option>
                  <Option value={2}>Document Review</Option>
                  <Option value={3}>Pre Planning Phase</Option>
                  <Option value={4}>Data Collection</Option>
                  <Option value={5}>Closed</Option>
                </Select>
              </FormItem>
            </div>
          </div>

          <div className="assignedToRoundLabel py-3">
            <span>Security plan</span>
            <Tooltip title="Information about assignment to rounds"></Tooltip>
          </div>
          <div className="row">
            <div className="column">
              <Form.Item name="files" label="Upload files">
                <Upload.Dragger
                  multiple
                  listType="text"
                  accept=".jpg, png"
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={(file) => handleFileSize(file)}
                >
                  <div className="d-flex justify-content-center pb-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText ">
                    <span className="span3">JPG or PNG</span>
                    <span className="span4">Max file size: 5MB</span>
                  </div>
                </Upload.Dragger>

                <div className="uploadsContainer">
                  {loadingFiles?.map((file) => (
                    <div className="uploadContentLoading" key={file.uid}>
                      <div className="d-flex flex-row gap-3">
                        <Progress
                          className="uploadIcon"
                          type="circle"
                          width={44}
                          percent={file.percent || 0}
                          format={(percent) =>
                            percent < 100 ? (
                              `${percent}%`
                            ) : (
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 48 }}
                                    spin
                                  />
                                }
                              />
                            )
                          }
                        />
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row gap-2">
                            {file.percent < 100 && (
                              <h1
                                style={{ color: "red" }}
                                className="loading-text"
                              >
                                Loading file's
                                <span className="dots">
                                  <span>.</span>
                                  <span>.</span>
                                  <span>.</span>
                                </span>
                              </h1>
                            )}
                            {file.percent === 100 && (
                              <h1
                                style={{ color: "green" }}
                                className="loading-text"
                              >
                                Saving the file's to the server
                                <span className="dots">
                                  <span>.</span>
                                  <span>.</span>
                                  <span>.</span>
                                </span>
                              </h1>
                            )}
                          </div>
                          {file.size && (
                            <p className="m-0 p-0">
                              {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                              MB
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {fileList &&
                    fileList?.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            type="circle"
                            percent={100}
                            className="uploadIcon"
                            width={44}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1 className="loading-text">{file.name}</h1>
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
                <div className="uploadsContainer">
                  {existingFiles &&
                    existingFiles?.map((file, index) => (
                      <div className="uploadContentLoading" key={file?.fileKey}>
                        <a
                          className="d-flex flex-row gap-3"
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            previewFiles(e, file.fileKey, file.id)
                          }
                        >
                          <img
                            className="uploadIconExistingFiles"
                            src={uploadIcon}
                            alt={file?.fileName}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1>{file?.fileName}</h1>
                            </div>
                            <p className="m-0 p-0">
                              {(
                                parseInt(file?.fileSize) /
                                (1024 * 1024)
                              ).toFixed(1)}{" "}
                              MB
                            </p>
                          </div>
                        </a>
                        <img
                          onClick={() => handlIpPlanResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="trackingElement">
            <Form.Item label="" className="trackingLabel"></Form.Item>
          </div>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditDraftsModal;
