import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Upload,
  Spin,
  Progress,
} from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import dayjs from "dayjs";
import { httpService } from "../../../../../services/httpService.service";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import { formatDateAndTime } from "../../../../../services/helpFunctions/formatDateAndTime";
import { useFilePreview } from "../../../completed/ReportingList/Download";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Not addressed" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const Edit = ({
  visible,
  onClose,
  record,
  setMessage,
  fetchRedFlags,
  pagination,
}) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState();
  const [users, setUsers] = useState();
  const [form] = Form.useForm();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [RedFlagFiles, setRedFlagFiles] = useState([]);
  const [initialName, setInitialName] = useState("");
  const [lastUpdated, setlastUpdated] = useState("");
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [filesToRemoveKeys, setFilesToRemoveKeys] = useState([]);
  const { previewFiles } = useFilePreview();

  useEffect(() => {
    setPageLoading(true);
    if (record?.key) {
      const redFlagId = record?.key;

      httpService
        .get(`/api/RedFlag/getRedFlag?id=${redFlagId}`, (res) => {
          const redFlag = res?.data;
          const implementingPartner = redFlag?.implementingPartner;

          const filesUploaded =
            redFlag?.redFlagFiles?.map((file) => file) || [];
          if (redFlag?.updatedDate) {
            const updatedDate = redFlag?.updatedDate.split("T")[0];
            setlastUpdated(updatedDate);
          }
          setRedFlagFiles(filesUploaded);
          setInitialName(redFlag?.name);
          form.setFieldsValue({
            name: redFlag?.name,
            ImplementingPartner: implementingPartner?.name,
            description: redFlag?.description,
            siteVisitCard: redFlag?.siteVisitIdentifier,
            dateOfSiteVisit: dayjs(redFlag?.dateOfSiteVisit),
            city: implementingPartner?.cities?.map((city) => city.id),
            state: implementingPartner?.states?.map((state) => state.id),
            office: redFlag?.office?.id,
            dueDate: dayjs(redFlag?.dueDate),
            assignedTo: redFlag?.assignedTo?.id,
            flagStatus: redFlag?.flagStatus,
            followUpNotes: redFlag?.followUpNotes,
          });

          setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, [record, form]);

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((files) => {
    const newFiles = files.reduce((acc, file) => {
      if (!fileList.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingFiles(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingFiles([]);
        setFileList((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingFiles((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, 300);

  const handleFileChange = ({ fileList }) => {
    setLoading(true);
    debouncedHandleUploadChange(fileList);
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const deleteFilesFromDB = async (fileKeys) => {
    await axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      })
      .finally(() => setFilesToRemoveKeys([]));
  };

  const handleLeave = () => {
    const fileKeys = fileList?.map((file) => file.fileKey) || [];
    deleteFilesFromDB(fileKeys);
    form.resetFields();
    setFileList([]);
    setRedFlagFiles([]);
    setFilesToRemoveKeys([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleOk = async () => {
    const contractId = localStorage.getItem("currentContractId");
    try {
      setLoading(true);
      const values = await form.validateFields();
      const dateOfSiteVisit = formatDateAndTime(values.dateOfSiteVisit);
      const dueDate = formatDateAndTime(values.dueDate);
      const formData = new FormData();
      formData.append("Id", record?.key);
      if (values.name !== initialName) {
        formData.append("Name", values.name);
      }

      formData.append("ContractId", contractId);
      formData.append("Description", values.description);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);

      RedFlagFiles?.forEach((file, index) => {
        formData.append(`UpdateFiles[${index}].id`, file.id);
        formData.append(`UpdateFiles[${index}].fileName`, file?.fileName);
        formData.append(`UpdateFiles[${index}].fileKey`, file?.fileKey);
        formData.append(`UpdateFiles[${index}].fileSize`, file?.fileSize);
      });

      fileList?.forEach((file, index) => {
        const newIndex = RedFlagFiles.length + index;
        formData.append(`UpdateFiles[${newIndex}].fileName`, file?.name);
        formData.append(`UpdateFiles[${newIndex}].fileKey`, file?.fileKey);
        formData.append(`UpdateFiles[${newIndex}].fileSize`, file?.size);
      });

      await httpService.put(
        "/api/RedFlag",
        formData,
        (response) => {
          if (filesToRemoveKeys?.length) {
            deleteFilesFromDB(filesToRemoveKeys);
          }
          const message =
            response.message ||
            `A new red flag has been created and added to your database!`;
          setMessage({
            trigger: true,
            type: "success",
            icon: AddMemberIcon,
            title: `Red flag edited successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          form.resetFields();
          onClose();
          setRedFlagFiles([]);
          setFileList([]);
          fetchRedFlags(pagination.current, pagination.pageSize);
        },
        (error) => {
          const messageError =
            error?.errors?.[0]?.message ||
            `We had a problem adding this red flag to your database, please try again!`;
          setMessage({
            trigger: true,
            type: "danger",
            icon: AddMemberIcon,
            title: "Oops! A problem has occurred!",
            text: messageError,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
        },
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl",
          },
        }
      );
    } catch (errorInfo) {
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Validation Error",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
    } finally {
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      setLoading(false);
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleResourceRemove = (file) => {
    const fileKey = file?.fileKey;
    deleteFilesFromDB([fileKey]);
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleResourceRedFlagRemove = (fileToRemove) => {
    const fileToRemovekey = fileToRemove?.fileKey;
    setFilesToRemoveKeys((prevFileKeys) => [...prevFileKeys, fileToRemovekey]);
    setRedFlagFiles((prevFiles) =>
      prevFiles.filter((file) => file.fileKey !== fileToRemove.fileKey)
    );
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: exclamationMark,
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {lastUpdated && (
          <span>Last update:  {formatDateAndTime(lastUpdated)}</span>
        )}
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Red flag card</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical">
          {" "}
          <Form.Item
            className="fullWidthInput"
            name="name"
            label="Name (type of red flag)"
            rules={[
              { required: true, message: "Enter the name of the red flag" },
            ]}
          >
            <Input
              placeholder="Enter the name of the red flag"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="siteVisitCard"
            label="Select the site visit card"
            disabled
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="<###> - SV - <Sitename> - <Location 2> - <date created>"
            />
          </Form.Item>
          <Form.Item
            name="ImplementingPartner"
            label="Implementing Partner"
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="Implementing Partner of the selected site visit card"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Enter the description of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter the description of the flag"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dateOfSiteVisit"
              label="Date of the red flag"
              rules={[{ required: true, message: "Pick date of red flag" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Enter text here" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dueDate"
              label="Due date for action"
              rules={[{ required: true, message: "Pick due date" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Select date"></DatePicker>
            </Form.Item>
          </div>
          <Form.Item
            name="assignedTo"
            label="Assigned to a field coordinator"
            rules={[
              {
                required: true,
                message: "Select assigned to a field coordinator",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select assigned to a field coordinator">
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.userDetails?.firstName && user.userDetails?.lastName
                      ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                      : user.userName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="flagStatus"
            label="Flag status"
            rules={[
              { required: true, message: "Select the status of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select the status of the flag">
              {flagStatuses &&
                flagStatuses.map((flagStatus) => (
                  <Option key={flagStatus.id} value={flagStatus.id}>
                    {flagStatus.status}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label="Follow-up notes"
            rules={[{ required: true, message: "Enter your follow up notes" }]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter your follow up notes" />
          </Form.Item>
          <div className="resourcesElement">
            <div className="resourcesLabel">
              <div className="resourcesUploadElement">
                <Form.Item
                  name="files"
                  label={
                    <div>
                      <p
                        className="modalsHeader1"
                        style={{
                          color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "144%",
                        }}
                      >
                        Evidence
                      </p>
                      <p
                        style={{
                          color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                        }}
                      >
                        Please attach any relevant evidence if needed
                      </p>
                    </div>
                  }
                >
                  <Upload.Dragger
                    multiple
                    listType="text"
                    accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={(file) => handleFileSize(file)}
                  >
                    <div className="d-flex justify-content-center pb-3">
                      <img src={uploadIcon} alt="" />
                    </div>
                    <Button className="uploadButton">
                      Drag and drop files here, or click to select files
                    </Button>
                    <div className="d-flex justify-content-center bottomText ">
                      <span className="span3">
                        PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                      </span>
                      <span className="span4">Max file size: 5MB</span>
                    </div>
                  </Upload.Dragger>
                  <div className="uploadsContainer">
                    {loadingFiles?.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            className="uploadIcon"
                            type="circle"
                            width={44}
                            percent={file.percent || 0}
                            format={(percent) =>
                              percent < 100 ? (
                                `${percent}%`
                              ) : (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 48 }}
                                      spin
                                    />
                                  }
                                />
                              )
                            }
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              {file.percent < 100 && (
                                <h1
                                  style={{ color: "red" }}
                                  className="loading-text"
                                >
                                  Loading file's
                                  <span className="dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                  </span>
                                </h1>
                              )}
                              {file.percent === 100 && (
                                <h1
                                  style={{ color: "green" }}
                                  className="loading-text"
                                >
                                  Saving the file's to the server
                                  <span className="dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                  </span>
                                </h1>
                              )}
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {fileList.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            type="circle"
                            percent={100}
                            className="uploadIcon"
                            width={44}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1 className="loading-text">{file.name}</h1>
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                    {RedFlagFiles.map((file, index) => (
                      <div className="uploadContentLoading" key={file?.fileKey}>
                        <a
                          className="d-flex flex-row gap-3"
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            previewFiles(e, file.fileKey, file.id)
                          }
                        >
                          <img
                            className="uploadIconExistingFiles"
                            src={uploadIcon}
                            alt={file?.fileName}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1>{file?.fileName}</h1>
                            </div>
                            <p className="m-0 p-0">
                              {(
                                parseInt(file?.fileSize) /
                                (1024 * 1024)
                              ).toFixed(1)}{" "}
                              MB
                            </p>
                          </div>
                        </a>
                        <img
                          onClick={() => handleResourceRedFlagRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Edit;
