import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Spin } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../services/httpService.service";
import ProjectIcon from "../../../Assets/svg/iconMenu1.svg";
import showConfirm from "../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;
const { TextArea } = Input;

const EditProject = ({ visible, onClose, project, onSubmit, setMessage }) => {
  const [allCountries, setAllCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    if (project) {
      setComponentLoading(true);
      const contractId = localStorage.getItem("currentContractId");
      const apiCountries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;
      const countryId = project?.projectDetails?.country?.id;
      const stateIds = project?.projectDetails?.states.map((state) => state.id);

      const params = new URLSearchParams();
      stateIds.forEach((id) => params.append("stateIds", id));
      const apiCalls = [
        httpService.get(
          `/api/Contract?id=${contractId}`,
          (res) => setClients(res.data.clients),
          (error) => console.log(error)
        ),

        httpService.get(
          apiCountries,
          (res) => setAllCountries(res.data),
          (error) => console.log(error)
        ),

        httpService.get(
          `/api/GeoData/states?countryIds=${countryId}`,
          (res) => setStates(res.data),
          (error) => console.log(error)
        ),

        httpService.get(
          `/api/GeoData/cities?${params}`,
          (res) => setCities(res.data),
          (error) => console.log(error)
        ),
      ];

      Promise.all(apiCalls).finally(() => setComponentLoading(false));

      form.setFieldsValue({
        projectName: project?.projectName,
        projectNumber: project?.projectNumber,
        countryId: project?.projectDetails?.country?.id,
        state: stateIds,
        cityId: project?.projectDetails?.locations?.map((city) => city.id),
        clients: project?.clients?.map((client) => client.id),
        clientFocalPoint: project?.projectDetails?.clientFocalPoint,
        clientFocalPointEmail: project?.projectDetails?.clientFocalPointEmail,
        projectDescription: project?.description,
        referenceDocuments: project?.projectDetails?.referenceDocuments,
        linkToSharedDrive: project?.projectDetails?.linkToSharedDrive,
      });
    }
  }, [visible]);

  const handleCountryChange = (countryId) => {
    form.setFieldsValue({
      state: [],
    });
    setStates([]);
    setCities([]);
    if (countryId) {
      setLoadingCities(true);
      httpService.get(
        `/api/GeoData/states?countryIds=${countryId}`,
        (res) => {
          setStates(res.data);
          setLoadingCities(false);
        },
        (error) => {
          console.log(error);
          setLoadingCities(false);
        }
      );
    }
  };

  const handleStateChange = (stateIds) => {
    form.setFieldsValue({
      cityId: undefined,
    });
    setCities([]);
    if (stateIds?.length > 0) {
      setLoadingCities(true);

      const params = new URLSearchParams();
      stateIds.forEach((id) => params.append("stateIds", id));
      httpService.get(
        `/api/GeoData/cities?${params}`,
        (res) => {
          setCities(res.data);
          setLoadingCities(false);
        },
        (error) => {
          console.log(error);
          setLoadingCities(false);
        }
      );
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const requestBody = {
          id: project?.id,
          projectName: values.projectName,
          projectNumber: values.projectNumber,
          description: values.projectDescription || "",
          projectDetails: {
            countryId: values.countryId,
            contractId: localStorage.getItem("currentContractId"),
            stateIds: values.state,
            cityIds: values.cityId,
            clientFocalPoint: values.clientFocalPoint,
            clientFocalPointEmail: values.clientFocalPointEmail.toLowerCase(),
            referenceDocuments: values.referenceDocuments || "",
            linkToSharedDrive: values.linkToSharedDrive,
          },
          clients: values.clients.map((clientId) => {
            const client = clients.find((c) => c.id === clientId);
            return { id: client.id, name: client.name };
          }),
        };

        httpService.put(
          `/api/projects`,
          requestBody,
          (response) => {
            form.resetFields();
            onSubmit();
            const message =
              response.message ||
              "This project was correctly updated to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: ProjectIcon,
              title: "Project updated successfully!",
              text: message,
            });
            handleLeave();
            setLoading(false);
          },
          (error) => {
            console.error("There was an error updating the project!", error);
            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem updating this project, please try again.";
            setMessage({
              trigger: true,
              type: "danger",
              icon: ProjectIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });

    setMessage({
      trigger: false,
      type: "",
      icon: null,
      title: "",
      text: "",
    });
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Project Round</h2>
      <Spin spinning={componentLoading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            projectName: project?.projectName,
            projectNumber: project?.projectNumber,
            countryId: project?.projectDetails?.country?.id,
            cityId: project?.projectDetails?.locations?.map((city) => city.id),
            clients: project?.clients?.map((client) => client.id),
            clientFocalPoint: project?.projectDetails?.clientFocalPoint,
            clientFocalPointEmail:
              project?.projectDetails?.clientFocalPointEmail,
            projectDescription: project?.description,
            referenceDocuments: project?.projectDetails?.referenceDocuments,
            linkToSharedDrive: project?.projectDetails?.linkToSharedDrive,
          }}
        >
          <p className="modalsHeader1">Project Details</p>
          <Form.Item
            name="projectName"
            label="Project Round Name"
            rules={[
              { required: true, message: "Please enter project round name" },
            ]}
          >
            <Input placeholder="Enter project round Name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="projectNumber"
            label="Project number"
            rules={[{ required: true, message: "Please enter project number" }]}
          >
            <Input placeholder="Enter Project number" autoComplete="off" />
          </Form.Item>

          <Spin spinning={loadingCities}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="countryId"
                tooltip="The list of countries displayed here comes from the countries you selected when creating the contract. The country you select now will be shown in this project."
                label="Country"
                rules={[{ required: true, message: "Please select country" }]}
                style={{ width: "48%" }}
              >
                <Select
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {allCountries &&
                    allCountries.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="state"
                tooltip="The list of states you select now will be shown in this project."
                label="Governorate/State"
                className={states.length < 1 ? "d-none" : ""}
                rules={[
                  {
                    required: true,
                    message: `${
                      cities.length < 1
                        ? "Please select governorate/state or city"
                        : "Please select governorate/state"
                    }`,
                  },
                ]}
                style={{ width: "48%" }}
              >
                <Select
                  mode="multiple"
                  placeholder="Select governorate or state"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                  allowClear
                  onChange={handleStateChange}
                >
                  {states.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.stateName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="cityId"
                label="City/Disctrict/Municipality:"
                tooltip="The list of cities you select now will be shown in this project."
                className={cities.length < 1 ? "d-none" : ""}
                rules={[
                  {
                    required: cities.length,
                    message: "Please select one or more cities",
                  },
                ]}
                style={{ width: "48%" }}
              >
                <Select
                  placeholder="Select one or more cities"
                  disabled={cities.length < 1}
                  showSearch
                  allowClear
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {cities.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Spin>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="clients"
              label="Clients"
              rules={[{ required: true, message: "Please select a client" }]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Clients"
                mode="multiple"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {clients &&
                  clients.map((client) => (
                    <Option key={client.id} value={client.id}>
                      {client.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="clientFocalPoint"
              label="Client Focal Point"
              style={{ width: "48%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a client focal point",
                },
              ]}
            >
              <Input placeholder="example" autoComplete="off" />
            </Form.Item>
          </div>
          <Form.Item
            name="clientFocalPointEmail"
            label="Client Focal Point Email"
            rules={[
              {
                required: true,
                message: "Please enter a client focal point email",
              },
            ]}
          >
            <Input placeholder="example" autoComplete="off" />
          </Form.Item>
          <Form.Item name="projectDescription" label="Project Description">
            <TextArea placeholder="Project Description" rows={4} />
          </Form.Item>
          <Form.Item
            name="referenceDocuments"
            label="Links to reference documents (communications doc link, etc.)"
          >
            <TextArea placeholder="Links to reference documents" rows={4} />
          </Form.Item>
          <Form.Item
            name="linkToSharedDrive"
            label="Link to Shared drive"
            tooltip="Please give a valid link ex: http://... or http://... or www..."
            rules={[
              {
                required: false,
                message: "Please enter a Link to Shared drive",
              },
              {
                pattern: new RegExp(
                  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
                ),
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input
              placeholder="http://www.link-shared-drive.com"
              autoComplete="off"
            />
          </Form.Item>

          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
              disabled={loading}
            >
              Edit Project Round
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditProject;
