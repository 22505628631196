import { Form, Upload, Button, Progress, Spin } from "antd";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import { useSiteVisitStore } from "./store/siteVisitStore";
import { extractName } from "../../../../../services/helpFunctions/extractNameOfFile";
import { useEffect, useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { useFilePreview } from "../../../../pages/completed/ReportingList/Download";

const ResourcesDetails = ({
  existingFiles,
  setExistingFiles,
  setMessage,
  form,
  filesToSave,
  setFilesToSave,
  setSubmitLoader,
  setFilesToDelete,
}) => {
  const { otherDocuments, setOtherDocuments } = useSiteVisitStore();
  const [loadingDocs, setLoadingDocs] = useState([]);
  const { previewFiles } = useFilePreview();

  useEffect(() => {
    form.setFieldsValue({ other_files: filesToSave });
  }, [filesToSave]);

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => setMessage({ trigger: false }), 3000);
    }
    return isValidSize;
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((file, fileList) => {
    const newFiles = fileList.reduce((acc, file) => {
      if (!otherDocuments.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingDocs(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingDocs((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingDocs([]);
        setOtherDocuments(fileList, ...otherDocuments);
        setFilesToSave((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingDocs((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setSubmitLoader(false);
      });
  }, 300);

  const handleUploadChange = ({ file, fileList }) => {
    setSubmitLoader(true);
    debouncedHandleUploadChange(file, fileList);
  };

  const handleDeleteOtherDocuments = (itemToRemove) => {
    const newFiles = otherDocuments?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    setOtherDocuments(newFiles);

    setFilesToSave((prevFiles) =>
      prevFiles.filter((item) => item?.uid !== itemToRemove?.uid)
    );
    //this one down here has the fileKey
    const fileToRemove = filesToSave.find(
      (item) => item?.uid == itemToRemove?.uid
    );
    deleteFilesFromDB(fileToRemove?.fileKey);
  };

  const deleteFilesFromDB = (fileKeys) => {
    axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: [fileKeys],
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      });
  };

  const handleDeleteExistingOtherDocuments = (file) => {
    setExistingFiles((prevFiles) =>
      prevFiles?.filter((item) => item.fileKey !== file.fileKey)
    );

    const fileKey = file.fileKey;
    setFilesToDelete((prevFiles) => [...prevFiles, fileKey]);
  };

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Form.Item
        name="other_files"
        label="Upload other document(s) - Security plan"
        style={{ fontWeight: "500" }}
      >
        <Upload.Dragger
          listType="text"
          accept=".pdf,.doc,.docx,.xls,.xlsx"
          fileList={otherDocuments}
          multiple={true}
          onChange={handleUploadChange}
          beforeUpload={(file) => {
            const isFileSizeValid = handleFileSize(file);
            if (!isFileSizeValid) {
              return Upload.LIST_IGNORE;
            }
          }}
        >
          <div className="d-flex justify-content-center pb-3">
            <img src={uploadIcon} alt="" />
          </div>
          <Button className="uploadButton">
            Drag and drop other document here, or click to select files
          </Button>
          <div className="d-flex justify-content-center bottomText">
            <span className="span3">
              Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
            </span>
            <span className="span4">Max file size: 5MB</span>
          </div>
        </Upload.Dragger>

        <div className="uploadsContainer">
          {loadingDocs?.map((file) => (
            <div className="uploadContentLoading" key={file.uid}>
              <div className="d-flex flex-row gap-3">
                <Progress
                  className="uploadIcon"
                  type="circle"
                  width={44}
                  percent={file.percent || 0}
                  format={(percent) =>
                    percent < 100 ? (
                      `${percent}%`
                    ) : (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 48 }} spin />
                        }
                      />
                    )
                  }
                />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row gap-2">
                    {file.percent < 100 && (
                      <h1 style={{ color: "red" }} className="loading-text">
                        Loading file's
                        <span className="dots">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </h1>
                    )}
                    {file.percent === 100 && (
                      <h1 style={{ color: "green" }} className="loading-text">
                        Saving the file's to the server
                        <span className="dots">
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </span>
                      </h1>
                    )}
                  </div>
                  {file.size && (
                    <p className="m-0 p-0">
                      {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
          {otherDocuments?.map((file) => (
            <div className="uploadContentLoading" key={file.uid}>
              <div className="d-flex flex-row gap-3">
                <Progress
                  type="circle"
                  percent={100}
                  className="uploadIcon"
                  width={44}
                />
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row gap-2">
                    <h1 className="loading-text">{file.name}</h1>
                  </div>
                  {file.size && (
                    <p className="m-0 p-0">
                      {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                    </p>
                  )}
                </div>
              </div>
              <img
                onClick={() => handleDeleteOtherDocuments(file)}
                src={RemoveIcon}
                alt="RemoveIcon"
              />
            </div>
          ))}

          {existingFiles?.length
            ? existingFiles?.map((file, index) => (
                <div className="uploadContentLoading" key={file?.fileKey}>
                  <a
                    className="d-flex flex-row gap-3"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                  >
                    <img
                      className="uploadIconExistingFiles"
                      src={uploadIcon}
                      alt={file?.fileName}
                    />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row gap-2">
                        <h1>{file?.fileName}</h1>
                      </div>
                      <p className="m-0 p-0">
                        {(parseInt(file?.fileSize) / (1024 * 1024)).toFixed(1)}{" "}
                        MB
                      </p>
                    </div>
                  </a>
                  <img
                    onClick={() => handleDeleteExistingOtherDocuments(file)}
                    src={RemoveIcon}
                    alt="RemoveIcon"
                  />
                </div>
              ))
            : ""}
        </div>
      </Form.Item>
    </div>
  );
};
export default ResourcesDetails;
