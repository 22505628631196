import React, { useEffect, useState } from "react";
import { Tabs, Pagination } from "antd";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import { httpService } from "../../../../services/httpService.service";

function MonitoringRoundOverview() {
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ignorePagination, setIgnorePagination] = useState(false);
  const projectID = localStorage.getItem("currentProjectID");
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: [],
    State: [],
    SiteVisitStatus: [],
    ClusterId: [],
    FieldTeamReportingStatus: "",
    Month: [],
    Year: [],
    Governates: [],
    Cities: [],
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    totalItems: 0,
  });

  const onChange = (key) => {
    if (key === "1") {
      setIgnorePagination(false);
    } else if (key === "2") {
      setIgnorePagination(true);
    }
    fetchSiteVisitCards();
  };

  useEffect(() => {
    fetchSiteVisitCards();
  }, [filters, pagination.current, pagination.pageSize]);

  const fetchSiteVisitCards = async () => {
    setLoading(true);

    const params = {
      ProjectId: projectID,
      IncludeOnlyWithTracking: true,
      Search: filters.Search,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters.SiteVisitStatus,
      FieldTeamReportingStatus: filters.FieldTeamReportingStatus,
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      IgnorePagination: ignorePagination,
      Governates: filters.Governates,
      Cities: filters.Cities,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    };

    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const sitevisitcardsData = response?.data;
        const metaData = response.metaData;
        setSiteVisitCards(sitevisitcardsData);
        setLoading(false);
        if (!ignorePagination) {
          setPagination((prev) => ({
            ...prev,
            totalItems: metaData.totalItemCount,
          }));
        }
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const items = [
    {
      key: "1",
      label: "Kanban View",
      children: (
        <KanbanView
          siteVisitCards={siteVisitCards}
          setSiteVisitCards={setSiteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          pagination={pagination}
          setPagination={setPagination}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Calendar View",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Monitoring Round Overview</span>
        <div className="tableViewHeaderButtons"></div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default MonitoringRoundOverview;
