import React from "react";
import HorizontalBarChart from "./HorizontalBarChart";

const VisitDataSummary = () => {
  return (
    <div className="VisitDataSummaryContainer w-100">
      <div className="containerOfVisits w-100">
        <div className="siteVisit w-100">
          <div className="siteVisitTitle">
            <p>Visits Planning & Execution</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="#6A113C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="siteVisitDetails w-100">
            <div className="chartsContainer">
              <div
                className="chart"
                style={{
                  minWidth: "200px",
                  minHeight: "300px",
                  padding: "0px 24px",
                }}
              >
                <HorizontalBarChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitDataSummary;
