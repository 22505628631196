import React, { useState, useEffect } from "react";
import { Table, Input, Button, Select } from "antd";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import { httpService } from "../../../services/httpService.service";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import SearchIcon from "../../../Assets/svg/searchIcon.svg";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import EditDraftsModal from "./modals/EditDraftsModal";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";

const { Option } = Select;

const IpPlanDraftsTable = ({
  data,
  setData,
  loading,
  setLoading,
  draftsPagination,
  setDraftsPagination,
  fetchData,
}) => {
  const [isEditDraftModalVisible, setEditDraftModalVisable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [draftsPagination.current, draftsPagination.pageSize]);

  const handleTableChange = (draftsPagination) => {
    setDraftsPagination((prevPagination) => ({
      ...prevPagination,
      current: draftsPagination.current,
      pageSize: draftsPagination.pageSize,
    }));
  };

  const handleDelete = (record) => {
    setCurrentRecord(record.key); // Set the current record to be deleted
    setShowDeleteModal(true); // Show the delete modal
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (currentRecord) {
      const requestBody = [
        {
          id: currentRecord,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(
        url,
        JSON.stringify(requestBody),
        (response) => {
          const message =
            response.message ||
            "This draft has been deleted successfully from the database!";
          setMessage({
            trigger: true,
            type: "success",
            icon: deleteIcon,
            title: "Draft deleted successfully!",
            text: message,
          });
          setShowDeleteModal(false);
          setCurrentRecord(null);
          fetchData();
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setLoading(false);
        },
        (error) => {
          const errorMessage =
            error.response?.data?.errors[0]?.message ||
            "We had a problem deleting this draft, please try again later!";

          setMessage({
            trigger: true,
            type: "danger",
            icon: deleteIcon,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setShowDeleteModal(false);
          setCurrentRecord(null);
          fetchData();
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setLoading(false);
        }
      );
    }
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setEditDraftModalVisable(true);
  };

  const handleEditOk = () => {
    setEditDraftModalVisable(false);
    fetchData();
  };

  const handleCancel = () => {
    setEditDraftModalVisable(false);
  };

  const columns = [
    {
      title: <span className="styledTitle">Last updated</span>,
      dataIndex: "lastUpdateDate",
      key: "lastUpdateDate",
      sorter: (a, b) => a.lastUpdateDate.localeCompare(b.lastUpdateDate),
      render: (text) => {
        return <span className="styledDataIndex3">{text}</span>;
      },
    },
    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "ip",
      key: "ip",
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP Project Code</span>,
      dataIndex: "ipPlanId",
      key: "ipPlanId",
      sorter: (a, b) => a.ip.localeCompare(b.ipPlanId),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },

    {
      title: <span className="styledTitle">Assigned To</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Clusters</span>,
      dataIndex: "clusters",
      key: "clusters",
      sorter: (a, b) => a.clusters - b.clusters,
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Current IP status</span>,
      dataIndex: "currentIpStatus",
      key: "currentIpStatus",
      sorter: (a, b) => a.currentIpStatus - b.currentIpStatus,
      render: (text) => {
        const statusMap = {
          0: "No status",
          1: "Discussion",
          2: "Document Review",
          3: "Pre Planning Phase",
          4: "Data Collection",
          5: "Closed",
        };
        const statusText = statusMap[text] || "No status";
        let className;
        let fill;

        if (statusText === "Discussion") {
          className = "discussion";
          fill = "var(--system-yellow-warning, #AA1A5F)";
        } else if (statusText === "No status") {
          className = "noStatus";
          fill = "rgba(172, 12, 12, 0.777)";
        } else if (statusText === "Document Review") {
          className = "documentReview";
          fill = "var(--system-yellow-warning, #FC9403)";
        } else if (statusText === "Pre Planning Phase") {
          className = "prePlanningPhase";
          fill = "var(--Primary-Blueberry-700, #325899)";
        } else if (statusText === "Data Collection") {
          className = "dataCollection";
          fill = "#3B81FE";
        } else if (statusText === "Closed") {
          className = "closed";
          fill = "#555555";
        }

        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{statusText}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={editIcon}
            onClick={() => handleEdit(record)}
            style={{ cursor: "pointer" }}
          />
          <img
            src={deleteIcon}
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(record)}
          ></img>
        </div>
      ),
    },
  ];
  return (
    <>
      {" "}
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="page-content">
        {isAuthorized([
          RolesEnum.SystemAdmin,
          RolesEnum.ProjectManager,
          RolesEnum.FieldCoordinator,
          RolesEnum.FieldLogistics,
        ]) && (
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            className="tableTPM"
            style={{ marginTop: "20px" }}
            pagination={{
              current: draftsPagination.current,
              pageSize: draftsPagination.pageSize,
              total: draftsPagination.total,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        )}
        <EditDraftsModal
          visible={isEditDraftModalVisible}
          onOk={handleEditOk}
          onClose={handleCancel}
          setMessage={setMessage}
          record={currentRecord?.key}
          onEdit={fetchData}
        />
        <DeleteComponent
          visible={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          text="You're about to delete this project. This action can’t be undone."
          header="Delete Project"
          onConfirm={handleConfirmDelete}
          loading={loading}
        />
      </div>
    </>
  );
};

export default IpPlanDraftsTable;
