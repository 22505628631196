import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Upload,
  Spin,
  Progress,
} from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import dayjs from "dayjs";
import { httpService } from "../../../../../services/httpService.service";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";
import Done from "../../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import { useFilePreview } from "../../../completed/ReportingList/Download";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Not addressed" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const EditDraft = ({
  visible,
  onClose,
  record,
  setMessage,
  fetchDraftData,
  fetchRedFlags,
}) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState();
  const [users, setUsers] = useState();
  const [form] = Form.useForm();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existingFiles, setExistingFiles] = useState("");
  const [savingPorgres, setSavingPorgres] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [filesToRemoveKeys, setFilesToRemoveKeys] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const { previewFiles } = useFilePreview();

  const handleDelete = async () => {
    if (record) {
      const requestBody = [
        {
          id: record?.key,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  useEffect(() => {
    setPageLoading(true);
    if (record?.key) {
      const redFlagId = record?.key;

      httpService
        .get(`/api/Draft/getDraftById?id=${redFlagId}`, (res) => {
          const redFlag = JSON.parse(res?.data?.jsonData);
          console.log("redFlag2222", redFlag);

          fetchSiteVisits();
          if (res?.data?.updatedDate) {
            const lastUpdatedDate = res?.data?.updatedDate?.split("T")?.[0];
            setLastUpdatedDate(lastUpdatedDate);
          }
          const initialData = {
            ImplementingPartner: redFlag?.ImplementingPartner || undefined,
            name: redFlag?.Name || undefined,
            description: redFlag?.Description || undefined,
            siteVisitCard: redFlag?.SiteVisitId || undefined,
            dateOfSiteVisit: dayjs(redFlag?.DateOfSiteVisit) || undefined,
            dueDate: dayjs(redFlag?.DueDate) || undefined,
            assignedTo: redFlag?.AssignedTo?.id || undefined,
            flagStatus: redFlag?.FlagStatus || undefined,
            followUpNotes: redFlag?.FollowUpNotes || undefined,
          };
          setExistingFiles(redFlag?.fileList);
          setInitialValues(initialData);
          form.setFieldsValue(initialData);
          setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, [record, form]);

  const handleSaveProgress = () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    const filesData = fileList.map((file) => ({
      fileName: file?.name,
      fileKey: file?.fileKey,
      fileSize: file?.size,
    }));

    let slectedUsers;
    if (values.assignedTo) {
      slectedUsers = users?.find((user) => user.id === values.assignedTo);
    }

    const RedFlagDraftData = {
      Name: values.name,
      ImplementingPartner: values.ImplementingPartner,
      Description: values.description,
      SiteVisitId: values?.siteVisitCard,
      ProjectId: localStorage.getItem("currentProjectID"),
      DateOfSiteVisit: values?.dateOfSiteVisit,
      DueDate: values?.dueDate,
      AssignedTo: slectedUsers,
      FlagStatus: values.flagStatus,
      FollowUpNotes: values.followUpNotes,
      ...(filesData?.length || existingFiles?.length
        ? { fileList: [...(filesData || []), ...(existingFiles || [])] }
        : {}),
    };
    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(RedFlagDraftData);
    const payload = {
      id: record.key,
      ProjectId: projectId,
      jsonData: jsonData,
      type: 3,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          fetchDraftData();
          fetchRedFlags();
          form.resetFields();
          setFileList([]);
          onClose();
          setFileList([]);
          setFilesToRemoveKeys([]);
          setSavingPorgres(false);
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: ExclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setSavingPorgres(false);
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 1000);
      });
  };

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";

    return date.format("YYYY-MM-DD");
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((files) => {
    const newFiles = files.reduce((acc, file) => {
      if (!fileList.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingFiles(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingFiles([]);
        setFileList((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingFiles((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, 300);

  const handleFileChange = ({ fileList }) => {
    setLoading(true);
    debouncedHandleUploadChange(fileList);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
      const dueDate = formatDate(values.dueDate);
      const projectId = localStorage.getItem("currentProjectID");

      const formData = new FormData();
      formData.append("Name", values.name);
      formData.append("Description", values.description);
      formData.append("SiteVisitId", values.siteVisitCard);
      formData.append("ProjectId", projectId);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);

      existingFiles?.forEach((file, index) => {
        formData.append(`Files[${index}].fileName`, file?.fileName);
        formData.append(`Files[${index}].fileKey`, file?.fileKey);
        formData.append(`Files[${index}].fileSize`, file?.fileSize);
      });

      fileList?.forEach((file, index) => {
        const newindex = existingFiles.length + index;

        formData.append(`Files[${newindex}].fileName`, file?.name);
        formData.append(`Files[${newindex}].fileKey`, file?.fileKey);
        formData.append(`Files[${newindex}].fileSize`, file?.size);
      });

      await httpService.upload(
        "/api/RedFlag",
        formData,
        (response) => {
          const message =
            response.message ||
            `A new red flag has been created and added to your database!`;
          setMessage({
            trigger: true,
            type: "success",
            icon: AddMemberIcon,
            title: `Red flag added successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          handleDelete();
          form.resetFields();
          onClose();
          setExistingFiles([]);

          setFileList([]);
          setTimeout(() => {
            fetchDraftData();

            fetchRedFlags();
          }, 2000);
        },
        (error) => {
          const messageError =
            error?.errors?.[0]?.message ||
            `We had a problem adding this red flag to your database, please try again!`;
          setMessage({
            trigger: true,
            type: "danger",
            icon: AddMemberIcon,
            title: "Oops! A problem has occurred!",
            text: messageError,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: ExclamationMark,
              title: "",
              text: "",
            });
          }, 3000);
        }
      );
    } catch (errorInfo) {
      console.log(errorInfo);
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Validation Error",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
    } finally {
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      setLoading(false);
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields?.[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name?.[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const deleteFilesFromDB = async (fileKeys) => {
    await axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      })
      .finally(() => setFilesToRemoveKeys([]));
  };

  const handleLeave = () => {
    const fileKeys = fileList?.map((file) => file.fileKey) || [];
    if (fileKeys?.length) {
      deleteFilesFromDB(fileKeys);
    }
    form.resetFields();
    setFileList([]);
    onClose();
    setFilesToRemoveKeys([]);
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleResourceRemove = (file) => {
    const fileKey = file?.fileKey;
    deleteFilesFromDB([fileKey]);
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleResourceRedFlagRemove = (fileToRemove) => {
    const fileKey = fileToRemove.fileKey;
    setFilesToRemoveKeys([...filesToRemoveKeys, fileKey]);
    setExistingFiles((prevFiles) =>
      prevFiles.filter((item) => item.fileDataId !== fileToRemove.fileDataId)
    );
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  const handleSiteVisitChange = (id) => {
    const siteVisit = siteVisits?.find((siteVisit) => siteVisit?.id === id);
    const ipOfSiteVisit = siteVisit?.ipPlan?.implementingPartner?.name;
    form.setFieldValue("ImplementingPartner", ipOfSiteVisit);
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {lastUpdatedDate && <span>Last update: {lastUpdatedDate}</span>}
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Draft</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          {" "}
          <Form.Item
            className="fullWidthInput"
            name="name"
            label="Name (type of red flag)"
            rules={[
              { required: true, message: "Enter the name of the red flag" },
            ]}
          >
            <Input
              placeholder="Enter the name of the red flag"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="siteVisitCard"
            label="Select the site visit card"
            rules={[{ required: true, message: "Select the site visit card" }]}
            style={{ width: "100%" }}
          >
            <Select
              onChange={handleSiteVisitChange}
              placeholder="<###> - SV - <Sitename> - <Location 2> - <date created>"
            >
              {siteVisits &&
                siteVisits.map((siteVisit) => (
                  <Option key={siteVisit.id} value={siteVisit.id}>
                    {siteVisit.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="ImplementingPartner"
            label="Implementing Partner"
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="Implementing Partner of the selected site visit card"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Enter the description of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter the description of the flag"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dateOfSiteVisit"
              label="Date of red flag"
              rules={[{ required: true, message: "Pick date of red flag" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Enter text here" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dueDate"
              label="Due date for action"
              rules={[{ required: true, message: "Pick due date" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Select date"></DatePicker>
            </Form.Item>
          </div>
          <Form.Item
            name="assignedTo"
            label="Select a person to assign the flag"
            rules={[
              { required: true, message: "Select a person to assign the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select a person to assign the flag">
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.userDetails?.firstName && user.userDetails?.lastName
                      ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                      : user.userName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="flagStatus"
            label="Flag status"
            rules={[
              { required: true, message: "Select the status of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select the status of the flag">
              {flagStatuses &&
                flagStatuses.map((flagStatus) => (
                  <Option key={flagStatus.id} value={flagStatus.id}>
                    {flagStatus.status}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label="Follow-up notes"
            rules={[{ required: true, message: "Enter your follow up notes" }]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter your follow up notes" />
          </Form.Item>
          <div className="resourcesElement">
            <div className="resourcesLabel">
              <div className="resourcesUploadElement">
                <Form.Item
                  name="files"
                  label={
                    <div>
                      <p
                        className="modalsHeader1"
                        style={{
                          color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "144%",
                        }}
                      >
                        Evidence
                      </p>
                      <p
                        style={{
                          color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                        }}
                      >
                        Please attach any relevant evidence if needed
                      </p>
                    </div>
                  }
                >
                  <Upload.Dragger
                    multiple
                    listType="text"
                    accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={(file) => handleFileSize(file)}
                  >
                    <div className="d-flex justify-content-center pb-3">
                      <img src={uploadIcon} alt="" />
                    </div>
                    <Button className="uploadButton">
                      Drag and drop files here, or click to select files
                    </Button>
                    <div className="d-flex justify-content-center bottomText ">
                      <span className="span3">
                        PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                      </span>
                      <span className="span4">Max file size: 5MB</span>
                    </div>
                  </Upload.Dragger>
                  <div className="uploadsContainer">
                    {loadingFiles?.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            className="uploadIcon"
                            type="circle"
                            width={44}
                            percent={file.percent || 0}
                            format={(percent) =>
                              percent < 100 ? (
                                `${percent}%`
                              ) : (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 48 }}
                                      spin
                                    />
                                  }
                                />
                              )
                            }
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              {file.percent < 100 && (
                                <h1
                                  style={{ color: "red" }}
                                  className="loading-text"
                                >
                                  Loading file's
                                  <span className="dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                  </span>
                                </h1>
                              )}
                              {file.percent === 100 && (
                                <h1
                                  style={{ color: "green" }}
                                  className="loading-text"
                                >
                                  Saving the file's to the server
                                  <span className="dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                  </span>
                                </h1>
                              )}
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {fileList.map((file) => (
                      <div className="uploadContentLoading" key={file.uid}>
                        <div className="d-flex flex-row gap-3">
                          <Progress
                            type="circle"
                            percent={100}
                            className="uploadIcon"
                            width={44}
                          />
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2">
                              <h1 className="loading-text">{file.name}</h1>
                            </div>
                            {file.size && (
                              <p className="m-0 p-0">
                                {(parseInt(file.size) / (1024 * 1024)).toFixed(
                                  1
                                )}{" "}
                                MB
                              </p>
                            )}
                          </div>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="uploadsContainer">
                    {existingFiles &&
                      existingFiles?.map((file, index) => (
                        <div
                          className="uploadContentLoading"
                          key={file?.fileKey}
                        >
                          <a
                            className="d-flex flex-row gap-3"
                            type="button"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              previewFiles(e, file.fileKey, file.id)
                            }
                          >
                            <img
                              className="uploadIconExistingFiles"
                              src={uploadIcon}
                              alt={file?.fileName}
                            />
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row gap-2">
                                <h1>{file?.fileName}</h1>
                              </div>
                              <p className="m-0 p-0">
                                {(
                                  parseInt(file?.fileSize) /
                                  (1024 * 1024)
                                ).toFixed(1)}{" "}
                                MB
                              </p>
                            </div>
                          </a>
                          <img
                            onClick={() => handleResourceRedFlagRemove(file)}
                            src={RemoveIcon}
                            alt="RemoveIcon"
                          />
                        </div>
                      ))}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleSaveProgress}
              loading={loading || savingPorgres}
            >
              Save Progress
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading || savingPorgres}
            >
              Add red flag
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditDraft;
