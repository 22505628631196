export const extractName = (formattedPath) => {
  const parts = formattedPath?.split("/");
  const filePart = parts[parts?.length - 1];

  const lastDashIndex = filePart?.indexOf("-", 36);

  const filename = filePart?.substring(lastDashIndex + 1);

  return filename;
};
