import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, Spin, message } from "antd";
import "./styles.scss";
import { httpService } from "../../../services/httpService.service";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import Done from "../../../Assets/svg/done.svg";
import ExclamationIcon from "../../../Assets/svg/exclamationMark.svg";

const { Option } = Select;

const FirstTimeSignIn = () => {
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const fetchLanguages = () => {
      httpService.get(
        "/api/GeoData/languages",
        (response) => setLanguages(response.data),
        (error) => console.error("Error fetching languages:", error)
      );
    };

    fetchLanguages();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: parseInt(values.gender),
      socialDetails: {
        skypeId: values.skypeId,
      },
      languages: values.languageIds.map((id) => ({
        languageId: id,
      })),
    };

    httpService.put(
      "/api/User/updateUserDetails",
      formData,
      (response) => {
        localStorage.setItem(
          "username",
          `${formData.firstName} ${formData.lastName}`
        );

        const message = response.message;
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Confirmation!",
          text: message || "Profile updated successfully!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        navigate("/dashboard/contracts");

        setLoading(false);
      },
      (error) => {
        console.error("Error updating profile:", error);
        const errorMessage =
          error?.errors?.[0]?.message || "Profile update failed!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationIcon,
          title: "Oops! A pr oblem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <h2>You have been added to TPMready!</h2>
            <p>
              Please fill out the following form to complete your profile and
              set up your account:
            </p>

            <Form
              layout="vertical"
              onFinish={onFinish}
              style={{ width: "100%" }}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  { required: true, message: "Please select your gender!" },
                ]}
              >
                <Select>
                  <Option value="1">Male</Option>
                  <Option value="2">Female</Option>
                  <Option value="3">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Name of the tool" name="skypeId">
                <Input />
              </Form.Item>

              <Form.Item
                label="Language"
                name="languageIds"
                rules={[
                  { required: true, message: "Please select a language!" },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Select languages"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {languages.map((language) => (
                    <Option key={language.id} value={language.id}>
                      {language.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className="addButtonTPM"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? <Spin /> : "Continue"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeSignIn;
