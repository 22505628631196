import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { httpService } from "../../../../../services/httpService.service";

const { MonthPicker } = DatePicker;

export const VisitCardFilters = ({ filters, setFilters }) => {
  const contractId = localStorage.getItem("currentContractId");
  const handleLocation = (state) => {
    setFilters((prevFilters) => ({ ...prevFilters, state }));
    // onFilterChange({ ...filters, state });
  };

  const handleMonthYearChange = (date, dateString) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      monthYear: date,
    }));
    // onFilterChange({ ...filters, monthYear: date });
  };

  const [allCountries, setAllCountries] = useState([]);

  useEffect(() => {
    if (contractId) {
      const apiCountries = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;
      httpService.get(
        apiCountries,
        (res) => setAllCountries(res.data),
        (error) => console.log(error)
      );
    }
  }, []);

  const { Option } = Select;
  return (
    <div className="filterCards">
      <div className="filterCardInput">
        <label htmlFor="">Location</label>
        <Select
          className="cardInputs"
          placeholder="Select Location"
          onChange={handleLocation}
          allowClear
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {allCountries.map((country) => (
            <Option key={country.id} value={country.stateName}>
              {country.stateName}
            </Option>
          ))}
        </Select>
      </div>
      <div className="filterCardInput">
        <label htmlFor="date">Date</label>
        <MonthPicker
          id="monthYear"
          placeholder="Select Month and Year"
          onChange={handleMonthYearChange}
          className="cardInputs"
          format="YYYY-MM"
        />
      </div>
    </div>
  );
};
