import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Spin, Form, message } from "antd";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationIcon from "../../../../Assets/svg/exclamationMark.svg";

const PreviewDataset = ({ visible, onClose, record, onSubmit, setMessage }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (visible && record) {
      setLoading(true);
      httpService.get(
        `/api/Site/getSiteVisit?id=${record.id}`,
        (res) => {
          setLoading(false);
          form.setFieldsValue({
            fulcrumAccount: res?.data?.logistic?.fulcrumAccount || "",
            siteVisitId: res?.data?.identifier || "",
          });
        },
        (error) => {
          console.error("Fetch Error:", error);
          setLoading(false);
        }
      );
    }
  }, [visible, record, form]);

  const validateURL = (_, value) => {
    const regex = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Please enter a valid link (https, http, or www).")
    );
  };

  const handleSubmit = async () => {
    setSubmitting(true); // Show loader when submitting

    await form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        formData.append("Id", record.id);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount);

        httpService.put(
          "/api/Site/updateSiteVisit",
          formData,
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              icon: Done,
              title: "Site visit card updated successfully!",
              text: "You have successfully modified the site visit.",
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            form.resetFields();
            setSubmitting(false);
            onSubmit();
            onClose();
          },
          (error) => {
            let errorMessage =
              error?.errors?.[0]?.message ||
              "There was a problem updating this site visit card!";

            setMessage({
              trigger: true,
              type: "danger",
              icon: ExclamationIcon,
              title: "Failed to update this site visit card",
              text: errorMessage,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            setSubmitting(false); // Hide loader after failure
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Submission Failed:", errorInfo);

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Failed to update this site visit card",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);

        setSubmitting(false); // Hide loader on form validation error
      });
  };

  const handleOpenPreviewModal = () => {
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onClose}
      footer={false}
      width={660}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onClose}
          alt="Cancel"
        />
      </div>
      <h2 className="modalHeader">Data Collection Results</h2>
      <Spin spinning={loading || submitting}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item label="Site Visit Card Identifier">
            <div
              className="dataCollectionMain"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Input
                value={form.getFieldValue("siteVisitId")}
                disabled
                style={{ flex: 1, marginRight: 8 }}
              />
              <Button
                className="dataCollectionButton"
                onClick={handleOpenPreviewModal}
              >
                See site visit card
              </Button>
            </div>
          </Form.Item>
          <Form.Item
            label="Link to Data Collection Results"
            name="fulcrumAccount"
            rules={[
              {
                required: true,
                message: "Link to Data Collection Results is required.",
              },
              { validator: validateURL },
            ]}
          >
            <div className="dataCollectionPreviewMainDiv">
              <div className="dataCollectionPreviewTextDiv">
                {form.getFieldValue("fulcrumAccount") || "No data available"}
              </div>
              <Button
                className="dataCollectionLinkButton"
                onClick={() => {
                  const url = form.getFieldValue("fulcrumAccount");
                  const validUrl =
                    url.startsWith("http://") || url.startsWith("https://")
                      ? url
                      : `http://${url}`;
                  window.open(validUrl, "_blank");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M13.5681 15.0006V17.8577C13.5681 18.2366 13.4176 18.6 13.1497 18.8678C12.8818 19.1357 12.5184 19.2863 12.1395 19.2863H2.13951C1.76062 19.2863 1.39727 19.1357 1.12936 18.8678C0.861448 18.6 0.710938 18.2366 0.710938 17.8577V2.14342C0.710938 1.76453 0.861448 1.40117 1.12936 1.13326C1.39727 0.865354 1.76062 0.714844 2.13951 0.714844H12.1395C12.5184 0.714844 12.8818 0.865354 13.1497 1.13326C13.4176 1.40117 13.5681 1.76453 13.5681 2.14342V5.00056M9.28237 10.0006H19.2824M19.2824 10.0006L16.4252 7.14342M19.2824 10.0006L16.4252 12.8577"
                    stroke="#3A3737"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </Form.Item>

          <div className="buttonsModal" style={{ marginTop: "20px" }}>
            <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
      <Preview
        visible={showPreviewModal}
        onClose={handleClosePreviewModal}
        siteVisitCardId={record?.id}
      />
    </Modal>
  );
};

export default PreviewDataset;
