import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  Divider,
  Checkbox,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";
import IconStrategy from "../../../../../Assets/svg/task.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";
import ExclamationMark from "../../../../../Assets/svg/exclamationMark.svg";

const { Option } = Select;

const EditTaskModal = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);
  const [siteVisits, setSiteVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [showEnumerators, setShowEnumerators] = useState(false);
  const projectId = localStorage.getItem("currentProjectID");
  const contractId = localStorage.getItem("currentContractId");
  const [selectedTaskType, setSelectedTaskType] = useState(undefined);
  const [usersList, setUsersList] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(false);

  const onClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    setShowEditModal(false);
    setTeam(null);
    setSelectedTaskType(undefined);
    setUsersList([]);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleSubmit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();

      const payload = {
        id: taskId,
        name: values.taskName,
        deadline: values.deadline,
        collectionMethod: values.collectionMethod,
        type: values.taskType,
        otherType: values.otherType,
        description: values.description,
        numberOfInterviews: values.numberOfInterviews,
        contactInformation: values.contactInformation,
        additionalNotes: values.additionalNotes,
        status: values.taskStatus,
        siteVisitId: values.siteIds,
        ...(selectedTaskType != 2 && selectedTaskType != undefined
          ? { assigneeIds: values.assignedToList }
          : { teamId: team?.id }),
      };

      httpService.put(
        "/api/Tasks/updateTask",
        payload,
        (response) => {
          const message =
            response.data.message ||
            "This task was correctly updated to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: IconStrategy,
            title: "Task updated successfully!",
            text: message,
          });
          handleLeave();
          fetchData();
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setButtonLoading(false);
        },
        (error) => {
          console.error("Failed to submit task:", error);
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem updating this task, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: IconStrategy,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setButtonLoading(false);
        }
      );
    } catch (errorInfo) {
      console.error("Form validation failed:", errorInfo);
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Oops! A problem has occurred!",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 3000);
      // Handle form validation errors if needed
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchTeamBySiteVisit = async (siteVisitId) => {
    setLoadingMembers(true);
    setTeam(null);
    const queryString = `siteVisitIds[0]=${encodeURIComponent(siteVisitId)}`;

    httpService.get(
      `/api/Site/getTeamBySiteVisits?${queryString}`,
      (response) => {
        const teamOfSiteVisit = response.data[0];

        setTeam(teamOfSiteVisit);
        setLoadingMembers(false);
      },
      (error) => {
        console.error("Failed to fetch team by site visit:", error);
        setLoadingMembers(false);
      }
    );
  };

  const fetchTaskData = async () => {
    try {
      if (taskId && visible) {
        setLoading(true);

        const promises = [];

        const taskPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Tasks/getTask?id=${taskId}`,
            (response) => {
              if (response && response.data) {
                const task = response.data;
                const assignees =
                  task?.assignees?.map((assignee) => assignee?.id) || [];
                // Set form fields with task data
                form.setFieldsValue({
                  taskIdentifier: task?.identifier,
                  taskName: task?.name,
                  deadline: dayjs(task.deadline),
                  siteIds: task?.taskSite?.id,
                  ...(selectedTaskType != 2 &&
                    selectedTaskType != undefined && {
                      assignedToList: assignees,
                    }),
                  collectionMethod: task?.collectionMethod,
                  description: task?.description,
                  numberOfInterviews: task?.numberOfInterviews,
                  contactInformation: task?.contactInformation,
                  additionalNotes: task?.additionalNotes,
                  taskType: task?.type,
                  taskStatus: task?.status,
                });

                setSelectedTaskType(task?.type);
                if (task?.type !== 2) {
                  getUsersList();
                }
                resolve(task); // Resolve the task data
              } else {
                reject(new Error("Task data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching task data: " + error.message));
            }
          );
        });

        promises.push(taskPromise);

        // Wait for both task and locations data to be fetched
        const [task] = await Promise.all(promises);

        // Fetch site visits based on the countryName
        const siteVisitsPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Site/getAllSiteVisits?ProjectId=${projectId}`,
            (res) => {
              if (res && res.data) {
                setSiteVisits(res.data);
                resolve(res.data); // Resolve the site visits data
              } else {
                reject(new Error("Site visits data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching site visits: " + error.message));
            }
          );
        });

        promises.push(siteVisitsPromise);

        // Fetch team data based on the site visit ID
        const siteVisitId = task?.taskSite?.id;
        const queryString = `siteVisitIds[0]=${encodeURIComponent(
          siteVisitId
        )}`;

        const teamPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Site/getTeamBySiteVisits?${queryString}`,
            (res) => {
              if (res && res.data) {
                setTeam(res?.data[0]);
                resolve(res?.data[0]); // Resolve the team data
              } else {
                reject(new Error("Team data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching team data: " + error.message));
            }
          );
        });

        promises.push(teamPromise);

        // Wait for all promises to resolve before setting loading to false
        await Promise.all(promises);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false); // Set loading to false once all requests are done
    }
  };

  useEffect(() => {
    setLoading(true);

    if (taskId) {
      httpService.get(
        `/api/Site/getAllSiteVisits?IgnorePagination=true&ProjectId=${projectId}`,
        (res) => {
          setSiteVisits(res.data);
        },
        (error) => {
          console.log(error);
        }
      );

      fetchTaskData();
    }
  }, [taskId, form, visible]);

  if (!context) {
    return <></>;
  }
  const { fetchData } = context;

  const handleSiteVisitChange = (value) => {
    setTeam(null);
    setUsersList([]);

    if (selectedTaskType === 2) {
      fetchTeamBySiteVisit(value);
    } else {
      getUsersList();
    }
  };

  const getUsersList = () => {
    setLoadingMembers(true);
    httpService.get(
      `/api/User/getAllusers?IgnorePagination=true&ContractIds=${contractId}`,
      (res) => {
        console.log(res.data);
        setUsersList(res?.data);
        setLoadingMembers(false);
      },
      (error) => {
        console.log(error);
        setLoadingMembers(false);
      }
    );
  };

  const userOptions = usersList?.map((user) => ({
    label: (
      <div>
        <h1 style={{ fontSize: "16px", margin: "0" }}>
          {user?.userDetails?.firstName && user?.userDetails?.lastName
            ? `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
            : user?.email}
        </h1>
        <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
          {user?.userContractRoles?.[0]?.role?.roleName || "No role specified"}
        </p>
      </div>
    ),
    value: user?.id,
  }));

  return (
    <Modal
      closable={false}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit Task Card</h2>
        </>
      }
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button className="inviteButtonTPM" key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task Card Identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task Name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the Task Name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select
                placeholder="Select an option"
                defaultValue={selectedTaskType}
                value={selectedTaskType}
                onChange={(type) => {
                  setUsersList([]);
                  setShowEnumerators(false);
                  setTeam(null);
                  form.setFieldsValue({
                    siteIds: undefined,
                    assignedToList: [],
                  });
                  setSelectedTaskType(type);
                }}
              >
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please select a site visit card!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                onChange={handleSiteVisitChange}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {siteVisits &&
                  siteVisits?.map((visit) => (
                    <Option key={visit.id} value={visit.id}>
                      {`${visit?.identifier} - ${visit?.ipPlan?.identifier}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {team != null &&
              selectedTaskType === 2 &&
              form.getFieldValue("siteIds") && (
                <Form.Item
                  className="mb-0"
                  label="Assigned to (selected site visit team)"
                  name="assignedTo"
                >
                  <div className="form-control-plaintext">
                    <p
                      style={{
                        border: "2px solid #d9d9d9",
                        borderRadius: "10px",
                        padding: "8px 12px",
                        fontSize: "14px",
                        color: "#495057",
                      }}
                    >
                      <span>
                        {team != null || team || undefined
                          ? team?.name
                          : "Pick a site visit card to show the team"}
                      </span>
                    </p>

                    {team?.members && (
                      <button
                        style={{
                          marginTop: "0",
                          marginBottom: "15px",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "var(--2, #AA1A5F)",
                          fontWeight: "500",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowEnumerators(!showEnumerators);
                        }}
                      >
                        {showEnumerators
                          ? "Hide team members"
                          : "Show team members"}
                        <img
                          src={DownArrow}
                          style={
                            showEnumerators
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                          alt="toggle"
                        />
                      </button>
                    )}
                  </div>
                </Form.Item>
              )}
            {showEnumerators && selectedTaskType === 2 && (
              <div className="userListContainer d-flex flex-column gap-5 mb-4">
                {team?.members?.map((member) => (
                  <div key={member.id}>
                    <h1 style={{ fontSize: "16px", margin: "0" }}>
                      {member?.firstName && member?.lastName
                        ? `${member?.firstName} ${member?.lastName}`
                        : member?.username}
                    </h1>
                    <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                      {member.roleName || "No role specified"}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {usersList?.length > 0 &&
              selectedTaskType != 2 &&
              form.getFieldValue("siteIds") &&
              selectedTaskType != undefined && (
                <div className="userListContainer d-flex flex-column gap-5 mb-4">
                  <div
                    style={{
                      borderRadius: "6px",
                      padding: "1px 0px 1px 10px",
                      gap: "10px",
                      background: "#FADCEA",
                    }}
                  >
                    <h1
                      style={{
                        color: "var(--2, #AA1A5F)",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: 700 }}> Note: </span>Below is a
                      list of{" "}
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        users associated with this contract
                      </span>
                      . Select the user you want to assign to this task.
                    </h1>
                  </div>
                  <Form.Item name="assignedToList">
                    <Checkbox.Group options={userOptions} />
                  </Form.Item>
                </div>
              )}
            {selectedTaskType == 2 && (
              <Form.Item label="Collection method" name="collectionMethod">
                <Input
                  placeholder="Enter the collection method"
                  // onChange={(e) =>
                  //   handleInputChange("collectionMethod", e.target.value)
                  // }
                />
              </Form.Item>
            )}
          </Col>

          <Form.Item label="Task Description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the Task Description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter the number of interviews!",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("Number must be 0 or greater!")),
              },
            ]}
            label="Number of interviews"
            name="numberOfInterviews"
          >
            <Input
              span={6}
              min={0}
              type="number"
              defaultValue={0}
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
          {/* <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item> */}
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the Task Status" },
            ]}
          >
            <Select placeholder="Select the Task Status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditTaskModal;
