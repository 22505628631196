import { Table } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../components/DeleteComponent/DeleteComponent";
import Edit from "./modals/Edit";
import Preview from "./modals/Preview";
import Filters from "./Filters";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import AddMemberIcon from "../../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";
import { formatDate } from "../../../../services/helpFunctions/formatDateAndTime";

function TableView({
  redFlags,
  fetchRedFlags,
  loading,
  setLoading,
  pagination,
  setPagination,
  onPageChange,
  filters,
  setFilters,
  handleFilterChange,
}) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const projectID = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function isUUID(str) {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  }

  const itemToPreview = localStorage.getItem("entityId");
  useEffect(() => {
    if (itemToPreview && isUUID(itemToPreview)) {
      setSelectedRowData({ key: itemToPreview });
      setShowPreviewModal(true);
      setTimeout(() => {
        localStorage.setItem("entityId", "");
      }, 300);
    }
  }, [itemToPreview]);

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleToggleEditModal = (record) => {
    setSelectedRowData(record);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleDeleteModal = (record) => {
    setSelectedRowData(record);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    setLoading(true);
    const payload = [
      {
        id: selectedRowData.key,
        isDeleted: false,
      },
    ];
    httpService.delete(
      "/api/RedFlag/deleteRedFlags",
      payload,
      () => {
        setShowDeleteModal(false);
        fetchRedFlags();
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Red flag deleted successfully!`,
          text: `This red flag has been deleted and removed from your database!`,
        });
        // Clear the message after 3 seconds
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem removing this red flag from your database, please try again!`;
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const columns = [
    {
      title: <span className="styledTitle">Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP tag</span>,
      dataIndex: "ipTag",
      key: "ipTag",
      sorter: (a, b) => a.ipTag.localeCompare(b.ipTag),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Description</span>,
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site Visit Identifier</span>,
      dataIndex: "siteVisitID",
      key: "siteVisitID",
      sorter: (a, b) => a.siteVisitID.localeCompare(b.siteVisitID),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Date of site visit</span>,
      dataIndex: "dateOfSiteVisit",
      key: "dateOfSiteVisit",
      sorter: (a, b) => a.dateOfSiteVisit.localeCompare(b.dateOfSiteVisit),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Due date</span>,
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Status</span>,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => {
        let className;
        let fill;
        if (text === "Not addressed") {
          className = "notAddressed";
          fill = "#AA1A5F";
        } else if (text === "Archived") {
          className = "archived";
          fill = "var(--Neutrals-Gray, #555)";
        } else if (text === "Resolved") {
          className = "resolved";
          fill = "#045B46";
        } else if (text === "On Hold") {
          className = "onHold";
          fill = "#D46600";
        } else if (text === "In progress") {
          className = "inProgress";
          fill = "var(--Primary-Blueberry-700, #325899);";
        }
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && redFlags.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
              alt="previewIcon"
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
              alt="editIcon"
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteModal(record)}
              alt="deleteIcon"
            />
          )}
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };
  const [checkStrictly, setCheckStrictly] = useState(false);

  const formatStatus = (status) => {
    if (status === 1) {
      return "Not addressed";
    } else if (status === 2) {
      return "In progress";
    } else if (status === 3) {
      return "On Hold";
    } else if (status === 4) {
      return "Resolved";
    } else if (status === 5) {
      return "Archived";
    }
  };
  const formatData = (redFlag) => {
    return {
      key: redFlag?.id,
      name: redFlag?.name,
      ipTag: redFlag?.implementingPartnerName,
      description: redFlag?.description,
      siteVisitID: redFlag?.siteVisitIdentifier,
      dateOfSiteVisit: redFlag?.dateOfSiteVisit,
      office: redFlag?.office?.name,
      dueDate: redFlag?.dueDate,
      assignedTo:
        redFlag.assignedTo?.firstName && redFlag.assignedTo?.lastName
          ? `${redFlag.assignedTo.firstName} ${redFlag.assignedTo.lastName}`
          : redFlag.assignedTo?.username,

      status: formatStatus(redFlag?.flagStatus),
    };
  };

  const formattedData = redFlags.map(formatData);

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Filters
        onChange={handleFilterChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        fetchRedFlags={fetchRedFlags}
      />
      <div>
        <Table
          // rowSelection={{ ...rowSelection, checkStrictly }}
          dataSource={formattedData}
          columns={columns}
          className="tableTPM"
          loading={loading}
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
        />
      </div>
      <Edit
        visible={showEditModal}
        onClose={handleToggleEditModal}
        record={selectedRowData}
        setMessage={setMessage}
        pagination={filters}
        fetchRedFlags={fetchRedFlags}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        record={selectedRowData}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. You can revert it from deleted. "}
        header={"Delete "}
        onConfirm={handleDelete}
        loading={loading}
      />
    </>
  );
}

export default TableView;
