import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Button, Flex, Spin, Tabs, Tooltip } from "antd";
import edit from "../../../Assets/svg/table-action-edit.svg";
import preview from "../../../Assets/svg/table-action-preview.svg";
import icon1 from "../../../Assets/svg/projectDashboardIcon1.svg";
import icon2 from "../../../Assets/svg/projectDashboardIcon2.svg";
import icon3 from "../../../Assets/svg/projectDashboardIcon3.svg";
import action1 from "../../../Assets/Images/quickAction1.png";
import action2 from "../../../Assets/Images/quickAction2.png";
import action3 from "../../../Assets/Images/quickAction3.png";
import action4 from "../../../Assets/Images/quickAction4.png";
import TpmSummary from "./TPMsummary/TpmSummary";
import IpSummary from "./IPsummary/IpSummary";
import SiteVisitSummary from "./SiteVisitSummary/SiteVisitSummary";
import EditProject from "../projects/EditProject";
import PreviewProject from "../projects/PreviewProject";
import { httpService } from "../../../services/httpService.service";
import AddSiteVisitCardModal from "../../pages/planning/VisitBoard/Modals/AddSiteVisitCardModal";
import AddNewTaskModal from "../planning/TasksAssignments/Modals/AddNewTaskModal";
import AddIpPlan from "../planning/modals/AddModal";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import AddRedFlagCardModal from "../Flags/Modals/AddRedFlagCardModal";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import { formatDateAndTime } from "../../../services/helpFunctions/formatDateAndTime";
import VisitDataSummary from "./VisitDataSummary/VisitDataSummary";

const { TabsProps } = Tabs;

function Dashboard() {
  const [activeComponent, setActiveComponent] = useState("SiteVisitSummary");
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showPreviewProjectModal, setShowPreviewProjectModal] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [reloadKey, setReloadKey] = useState(0); // State variable to trigger re-render
  const [isModalVisibleSiteVisit, setIsModalVisibleSiteVisit] = useState(false);
  const [isModalVisibleAddTask, setIsModalVisibleAddTask] = useState(false);
  const [isModalVisibleAddFlag, setIsModalVisibleAddFlag] = useState(false);
  const [isModalVisibleAddModal, setIsModalVisibleAddModal] = useState(false);
  const [loadingProject, setLoadingProject] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const showModalSiteVist = () => {
    setIsModalVisibleSiteVisit(true);
  };

  const showModalAddFlag = () => {
    setIsModalVisibleAddFlag(true);
  };
  const showModalEditModal = () => {
    setIsModalVisibleAddModal(true);
  };

  const showModalAddTask = () => {
    setIsModalVisibleAddTask(true);
  };

  const handleOk = () => {
    setIsModalVisibleSiteVisit(false);
  };

  const handleCancel = () => {
    setIsModalVisibleSiteVisit(false);
    setIsModalVisibleAddTask(false);
    setIsModalVisibleAddFlag(false);
    setIsModalVisibleAddModal(false);
  };

  const openActiveComponent = (collector) => {
    setActiveComponent(collector);
  };

  const handleAddedIpPlan = () => {
    //reload the dashboard
  };
  const fetchProjectData = async () => {
    const projectID = localStorage.getItem("currentProjectID");
    if (projectID) {
      setLoadingProject(true);
      httpService.get(
        `/api/Projects?id=${projectID}`,
        (response) => {
          setProjectData(response.data);
          setLoadingProject(false);
        },
        (error) => {
          console.error("Error fetching project data:", error);
          setLoadingProject(false);
        }
      );
    }
  };
  const handleSubmit = () => {
    fetchProjectData();
  };

  useEffect(() => {
    fetchProjectData();
  }, [reloadKey]);

  const truncatedDescription = isExpanded
    ? projectData?.description
    : `${projectData?.description?.substring(0, 80)}...`;

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {!projectData ? (
        <div
          style={{
            height: "calc(100vh - 130px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <MessagePopUp
            trigger={message.trigger}
            type={message.type}
            icon={message.icon}
            messageTitle={message.title}
            messageText={message.text}
          />
          <Spin spinning={loadingProject}>
            <div className="projectName">
              <div className="projectContent">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Tooltip
                    className="text-truncate"
                    title={`Project Round Name: ${projectData.projectName}`}
                  >
                    <h2 className="projectNameText">
                      {projectData.projectName} - Dashboard
                    </h2>
                  </Tooltip>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      className="projectDashboardButton"
                      onClick={() => {
                        setShowPreviewProjectModal(true);
                      }}
                    >
                      <img src={preview}></img>
                    </Button>
                    {isAuthorized([
                      RolesEnum.ProjectManager,
                      RolesEnum.SystemAdmin,
                    ]) && (
                      <Button
                        className="projectDashboardButton"
                        onClick={() => {
                          setShowEditProjectModal(true);
                        }}
                      >
                        <img src={edit}></img>
                      </Button>
                    )}
                  </div>
                </div>
                {projectData?.description && (
                  <Tooltip title={`Project Description`}>
                    <p className="projectNameDescription">
                      {truncatedDescription}
                      {projectData?.description?.length > 80 && (
                        <span
                          onClick={toggleDescription}
                          style={{
                            paddingLeft: "5px",
                            color: "#aa1a5f",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          {isExpanded ? "See Less" : "See More"}
                        </span>
                      )}
                    </p>
                  </Tooltip>
                )}
              </div>
              <div className="projectTags">
                <div className="projectTagDesign">
                  <Tooltip
                    title={`Clients: ${projectData?.clients
                      .map((client) => client.name)
                      .join(", ")}`}
                  >
                    <img
                      src={icon1}
                      alt={`userIcon`}
                      style={{ marginRight: "8px" }}
                    />
                    <span
                      className="text-truncate"
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        verticalAlign: "middle",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {projectData?.clients.map((client, index) => (
                        <React.Fragment key={client.id}>
                          {client.name}
                          {index !== projectData.clients.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    </span>
                  </Tooltip>
                </div>
                <div className="projectTagDesign">
                  <Tooltip
                    title={`Project Round Name: ${projectData.projectName}`}
                  >
                    <img
                      src={icon2}
                      alt="Icon 2"
                      style={{ marginRight: "8px" }}
                    />
                    <span
                      className="text-truncate"
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        verticalAlign: "middle",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {projectData.projectName}
                    </span>
                  </Tooltip>
                </div>
                <div className="projectTagDesign">
                  <Tooltip
                    title={`Updated Date: ${
                      projectData.updatedDate
                        ? formatDateAndTime(projectData.updatedDate)
                        : formatDateAndTime(projectData.createdDate)
                    }`}
                  >
                    <img
                      src={icon3}
                      alt="Icon 3"
                      style={{ marginRight: "8px" }}
                    />
                    <span
                      className="text-truncate"
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        verticalAlign: "middle",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {projectData.updatedDate
                        ? formatDateAndTime(projectData.updatedDate)
                        : formatDateAndTime(projectData.createdDate)}
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div style={{ padding: "24px", background: "#F6F6F6" }}>
              <div className="actionsDiv">
                <h2>Quick Actions</h2>
                <div className="quickActions">
                  {isAuthorized([
                    RolesEnum.ProjectManager,
                    RolesEnum.SystemAdmin,
                  ]) && (
                    <Button
                      className="quickActionsButton"
                      onClick={showModalEditModal}
                    >
                      <img src={action2}></img> Create IP Planning Card
                    </Button>
                  )}
                  {isAuthorized([
                    RolesEnum.ProjectManager,
                    RolesEnum.SystemAdmin,
                  ]) && (
                    <Button
                      className="quickActionsButton"
                      onClick={showModalSiteVist}
                    >
                      <img src={action4}></img> Create Site Visit Card
                    </Button>
                  )}
                  <Button
                    className="quickActionsButton"
                    onClick={showModalAddTask}
                  >
                    <img src={action3}></img> Create Task Card
                  </Button>

                  <Button
                    className="quickActionsButton"
                    onClick={showModalAddFlag}
                  >
                    <img src={action1}></img>Create Red Flag Card
                  </Button>

                  <AddSiteVisitCardModal
                    visible={isModalVisibleSiteVisit}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    setMessage={setMessage}
                  />

                  <AddNewTaskModal
                    setMessage={setMessage}
                    visible={isModalVisibleAddTask}
                    setIsModalVisible={handleCancel}
                    updateData={() => {}}
                  />
                  <AddRedFlagCardModal
                    visible={isModalVisibleAddFlag}
                    onClose={handleCancel}
                    setMessage={setMessage}
                    fetchRedFlags={handleCancel}
                    fetchDraftData={() => {}}
                    setFetchDraftData={() => {}}
                  />
                  <AddIpPlan
                    visible={isModalVisibleAddModal}
                    onClose={() => setIsModalVisibleAddModal(false)}
                    setMessage={setMessage}
                    setFetchDraftData={() => {}}
                    onAdd={handleAddedIpPlan}
                  />
                </div>
              </div>
            </div>{" "}
          </Spin>
          <div style={{ background: "#F6F6F6" }}>
            <div
              className="tab-container"
              style={{
                borderTop: "2px solid #DFE6FF",
                borderBottom: "none",
                background: "white",
              }}
            >
              <div>
                <label
                  className={
                    activeComponent === "SiteVisitSummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="SiteVisitSummary"
                    checked={activeComponent === "SiteVisitSummary"}
                    onChange={() => openActiveComponent("SiteVisitSummary")}
                  />
                  Site Visit Summary
                </label>
              </div>
              <div>
                <label
                  className={
                    activeComponent === "TPMsummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="TPMsummary"
                    checked={activeComponent === "TPMsummary"}
                    onChange={() => openActiveComponent("TPMsummary")}
                  />
                  TPM Summary
                </label>
              </div>
              <div>
                <label
                  className={
                    activeComponent === "IPsummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="IPsummary"
                    checked={activeComponent === "IPsummary"}
                    onChange={() => openActiveComponent("IPsummary")}
                  />
                  IP Summary
                </label>
              </div>
              <div>
                <label
                  className={
                    activeComponent === "VisitDataSummary"
                      ? "tab-nav-active"
                      : "tab-nav-not-active"
                  }
                >
                  <input
                    type="radio"
                    value="VisitDataSummary"
                    checked={activeComponent === "VisitDataSummary"}
                    onChange={() => openActiveComponent("VisitDataSummary")}
                  />
                  Visit Data Summary
                </label>
              </div>
            </div>
          </div>
          {activeComponent === "TPMsummary" && <TpmSummary />}
          {activeComponent === "IPsummary" && <IpSummary />}
          {activeComponent === "SiteVisitSummary" && <SiteVisitSummary />}
          {activeComponent === "VisitDataSummary" && <VisitDataSummary />}
          <EditProject
            visible={showEditProjectModal}
            onClose={() => setShowEditProjectModal(false)}
            project={projectData}
            onSubmit={handleSubmit}
            setMessage={setMessage}
          />
          <PreviewProject
            visible={showPreviewProjectModal}
            onClose={() => setShowPreviewProjectModal(false)}
            project={projectData}
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
