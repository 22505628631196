import { Button, Form, Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { httpService } from "../../../services/httpService.service";
import SearchIcon from "./../../../Assets/svg/searchIcon.svg";
import { debounce } from "lodash";

const { Option } = Select;

const TableFilters = ({ filters, setFilters, setPagination }) => {
  const [countries, setCountries] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [form] = Form.useForm();
  const [inputSearch, setInputSearch] = useState(filters.search);

  const handleCountry = (CountryId) => {
    setFilters((prevFilters) => ({ ...prevFilters, CountryId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));

    if (!CountryId) {
      form.setFieldsValue({ ["city"]: undefined, ["state"]: undefined });
      setCities([]);
      setStates([]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        CityId: null,
        StateId: null,
      }));
    }
    httpService.get(
      `/api/GeoData/states?countryIds=${CountryId}`,
      (res) => {
        if (res?.data < 1) {
          form.setFieldsValue({ ["state"]: undefined });
          setStates([]);
        } else {
          setStates(res.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: query,
      }));
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    }, 500),
    []
  );

  const handleStateChange = (stateId) => {
    setFilters((prevFilters) => ({ ...prevFilters, StateId: stateId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));

    if (!stateId) {
      form.setFieldsValue({ ["city"]: undefined });
      setCities([]);
      setFilters((prevFilters) => ({
        ...prevFilters,
        CityId: null,
      }));
    }
    httpService.get(
      `/api/GeoData/cities?stateIds=${stateId}`,
      (res) => {
        if (res?.data < 1) {
          form.setFieldsValue({ ["city"]: undefined });
          setCities([]);
        } else {
          setCities(res.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    httpService.get(
      apiCountries,
      (res) => setCountries(res.data),
      (error) => console.log(error)
    );
  }, []);

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      search: "",
      StateId: "",
      CityId: "",
      CountryId: "",
    });
    form.setFieldsValue({ ["state"]: undefined });
    form.setFieldsValue({ ["city"]: undefined });
    form.setFieldsValue({ ["country"]: undefined });
    setStates([]);
    setCities([]);
  };

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setInputSearch(search);
    debouncedSearchHandler(search);
  };

  const handleCityChange = (CityId) => {
    setFilters((prevFilters) => ({ ...prevFilters, CityId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  return (
    <div className="filtersWrapper mb-3">
      <Form form={form} className="w-100">
        <div className="containerFilters w-100">
          <div className="d-flex align-items-center justify-content-between w-100 gap-2">
            <Input
              value={inputSearch}
              placeholder="Search by IP name..."
              onChange={handleSearchChange}
              style={{ width: "100%" }}
              suffix={<img src={SearchIcon} alt="Search" />}
            />
            <Button className="me-2 clearButton" onClick={handleClearAll}>
              Clear All
            </Button>
            <Button onClick={handleToggleFilters} className="toggleButton">
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className="filtersContainer ms-1 mt-4">
            <div>
              <p className="filterText">Country</p>
              <Form.Item name="country">
                <Select
                  value={filters.CountryId}
                  allowClear
                  showSearch
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a country"
                  onChange={handleCountry}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {countries &&
                    countries.map((country) => (
                      <Option key={country.id} value={country.name}>
                        {country.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            {states?.length ? (
              <div>
                <p className="filterText">
                  {cities?.length
                    ? "Governorate/State"
                    : "Governorate/State or City"}
                </p>

                <Form.Item name="state">
                  <Select
                    value={filters.StateId}
                    showSearch
                    allowClear
                    onChange={handleStateChange}
                    style={{ width: 200, marginBottom: 20 }}
                    placeholder={
                      states?.length < 1
                        ? "Select country first!"
                        : "Select governorate or state"
                    }
                    disabled={states?.length < 1 ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .startsWith(input.toLowerCase())
                    }
                  >
                    {states &&
                      states.map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.stateName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            ) : (
              ""
            )}
            {cities?.length ? (
              <div>
                <p className="filterText">City</p>

                <Form.Item name="city">
                  <Select
                    value={filters.CityId}
                    showSearch
                    allowClear
                    onChange={handleCityChange}
                    style={{ width: 200, marginBottom: 20 }}
                    placeholder={
                      cities?.length < 1
                        ? "Select state first!"
                        : "Select a city"
                    }
                    disabled={cities?.length < 1 ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .startsWith(input.toLowerCase())
                    }
                  >
                    {cities &&
                      cities.map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

export default TableFilters;
