import React, { useEffect, useState } from "react";
import ".././Flags.scss";
import { Button, Tabs, Tooltip } from "antd";
import AddButton from "../../../../Assets/svg/addButton.svg";
import TableView from "./TableView/TableView";
import PostVisitCardModal from ".././Modals/PostVisitCardModal";
import AddRedFlagCardModal from ".././Modals/AddRedFlagCardModal";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import { httpService } from "../../../../services/httpService.service";
import DeletedModal from ".././Modals/DeletedModal";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";

function PostVisitCard() {
  // Handle show/hide Modal for "RedFlagCardModal" button
  const [showRedFlagModal, setshowRedFlagModal] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redFlags, setRedFlags] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    Search: "",
    State: "",
    ImplementingPartnerId: "",
    Month: "",
    Year: "",
  });

  const handleShowDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const handleShowRedFlagModal = () => {
    setshowRedFlagModal(!showRedFlagModal);
  };

  const handleCloseRedFlagModal = () => {
    setshowRedFlagModal(false);
  };

  const [showVisitCardModal, setshowVisitCardModal] = useState(false);

  const handleShowVisitCard = () => {
    setshowVisitCardModal(!showVisitCardModal);
  };

  const handleCloseVisitCardModal = () => {
    setshowVisitCardModal(false);
    fetchRedFlags();
  };

  const onChange = (key) => {
    // if(key===1){
    //   const projectID = localStorage.getItem("currentProjectID");
    //   fetchRedFlags(
    //     projectID,
    //     pagination.pageSize,
    //     pagination.current
    //   );
    // }else{
    //   const projectID = localStorage.getItem("currentProjectID");
    //   fetchRedFlags(
    //     projectID,
    //   );
    // }
  };

  const fetchRedFlags = () => {
    setLoading(true);

    const params = {
      Search: filters.search,
      State: filters.country,
      ImplementingPartnerId: filters.implementingPartnerId,
      Month: filters.month,
      Year: filters.year,
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    };
    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    httpService.get(
      `/api/RedFlag/postSiteVisits?${queryString}`,

      (response) => {
        const redFlagsData = response?.data;

        if (redFlagsData.length > 0) {
          setRedFlags(redFlagsData);
        } else {
          setRedFlags([]);
        }
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching red flags:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  // This will return which page is going to be rendered
  const items = [
    {
      key: "1",
      label: "Table View",
      children: (
        <TableView
          redFlags={redFlags}
          setRedFlags={setRedFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
  ];

  return (
    <div className="flagsPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      {/* <div className="banner-container">
        <div className="banner-text">Flags</div>
        <div className="banner-photo"></div>
      </div> */}
      <div className="tableViewcontainer">
        <div className="tableHeader">
          <span className="tableViewHeaderText">Post-Visit Card board</span>
          <div className="tableViewHeaderButtons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldTeamLeader,
            ]) && (
              <Button
                className="exportButtonTPM addButtonTPM text-white"
                onClick={handleShowVisitCard}
                fetchRedFlags={fetchRedFlags}
              >
                <img src={AddButton} alt="AddButton" />
                Add new post-visit card
              </Button>
            )}

            <PostVisitCardModal
              visible={showVisitCardModal}
              onClose={handleCloseVisitCardModal}
              setMessage={setMessage}
              fetchRedFlags={fetchRedFlags}
            />
            <AddRedFlagCardModal
              visible={showRedFlagModal}
              onClose={handleCloseRedFlagModal}
              setMessage={setMessage}
              fetchRedFlags={fetchRedFlags}
            />
            <DeletedModal
              visible={isDeletedModalVisible}
              onCancel={() => {
                setIsDeletedModalVisible(false);
              }}
              onOk={handleShowDeletedModal}
              fetchRedFlags={fetchRedFlags}
            />
          </div>
        </div>
        <div className="tabsHeader">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="customTabs"
          />
        </div>
      </div>
    </div>
  );
}

export default PostVisitCard;
