import React, { useEffect, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Button, Divider, Form, Modal, Spin, Upload } from "antd";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import "./index.scss";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import Done from "../../../../Assets/svg/done.svg";
import exclamationMark from "../../../../Assets/svg/exclamationMark.svg";

const EditProfilePicture = ({ visible, onClose }) => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const userId = localStorage.getItem("userID");
  const userEmail = localStorage.getItem("userEmail");
  const userName = localStorage.getItem("username");
  const profilePicture = localStorage.getItem("profilePicture");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  }, [showCropper]);

  useEffect(() => {
    if (profilePicture && !image) {
      setImage(profilePicture);
    }
  }, [profilePicture]);

  const handleCancel = () => {
    onClose();
  };

  const handleFileChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setShowCropper(true);
        };
        reader.readAsDataURL(file);
        setFileList(info.fileList);
      }
    }
  };

  const beforeUpload = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: exclamationMark,
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 3000);
      return Promise.reject("File too large");
    }
    return false; // Prevent automatic upload
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              console.error("Canvas is empty");
              return reject(new Error("Canvas is empty"));
            }
            // Create a File from the blob
            const file = new File([blob], "cropped_image.jpeg", {
              type: "image/jpeg",
            });
            resolve(file);
          },
          "image/jpeg",
          1
        );
      };
      image.onerror = (error) => reject(error);
    });
  };

  const handleCrop = async () => {
    try {
      console.log("Cropping started...");
      const croppedFile = await getCroppedImg(image, croppedAreaPixels);
      console.log("Cropping completed, file: ", croppedFile);

      // Use the File directly
      setFileList([croppedFile]);

      // If you need to display the image immediately
      const imageUrl = URL.createObjectURL(croppedFile);
      setImage(imageUrl);
    } catch (error) {
      console.error("Cropping failed:", error);
    } finally {
      setShowCropper(false);
    }
  };

  const removeProfilePicture = () => {
    setFileList([]);
    setImage("");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("userId", userId);
    if (image) {
      formData.append("ProfilePicture", fileList[0]);
    } else {
      formData.append("RemoveProfilePicture", true);
    }

    setLoadingPage(true);

    httpService.post(
      "/api/User/manageProfilePicture",
      formData,
      (response) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: "Profile picture updated successfully!",
          text: "Your profile picture has been updated.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
          onClose();
        }, 3000);
        setLoadingPage(false);
        const profilePictureUrl = response?.message;
        localStorage.setItem("profilePicture", profilePictureUrl);
        // window.location.reload();
      },
      (error) => {
        setMessage({
          trigger: true,
          type: "danger",
          icon: exclamationMark,
          title: "Oops! A problem has occurred!",
          text: "Failed to upload profile picture. Please try again.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
        }, 3000);
        setLoadingPage(false);
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit profile picture</h2>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Form form={form} autoComplete="off" layout="vertical">
        <Spin spinning={loadingPage}>
          <div className="userInfo mb-3">
            {showCropper ? (
              <div className="d-flex flex-column gap-5 w-100 text-center">
                <div className="cropper-container border border-2">
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
                <h2>Get your perfect profile picture with our crop tool!</h2>
                <Button onClick={handleCrop} className="addButtonTPM">
                  Save
                </Button>
              </div>
            ) : (
              <>
                {!image ||
                image === "Profile picture removed successfully" ||
                image === "" ? (
                  <div className="logo">
                    <span>
                      {userName ? userName.charAt(0) : userEmail.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div className="profilePicture">
                    <img src={image} alt="Profile Picture" />
                    <p onClick={removeProfilePicture}>Remove</p>
                  </div>
                )}
                <h2>Upload a file to change your profile picture</h2>
              </>
            )}
          </div>
          {!showCropper && (
            <>
              <Form.Item name="files" style={{ cursor: "pointer" }}>
                <Upload
                  listType="picture"
                  accept=".png,.jpeg,.jpg"
                  beforeUpload={beforeUpload}
                  fileList={fileList}
                  onChange={handleFileChange}
                  multiple={false}
                >
                  <div className="w-100 d-flex justify-content-center my-3">
                    <img src={uploadIcon} alt="" />
                  </div>
                  <Button className="uploadButton">
                    Drag and drop files here, or click to select files
                  </Button>
                  <div className="d-flex justify-content-center bottomText mb-3">
                    <span className="span3 text-bold">JPG or PNG</span>
                    <span className="span4">Max file size: 20MB</span>
                  </div>
                </Upload>
              </Form.Item>
              <Divider />
              <div className="buttonsModal">
                <Button
                  className="inviteButtonTPM"
                  key="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="addButtonTPM"
                  key="submit"
                  type="primary"
                  onClick={handleSubmit}
                  disabled={fileList?.length < 1 && image}
                >
                  Change profile picture
                </Button>
              </div>
            </>
          )}
        </Spin>
      </Form>
    </Modal>
  );
};

export default EditProfilePicture;
