import { LocalStorageService } from "./local-storage.service";
import { SessionService } from "./session.service";
import { jwtDecode } from "jwt-decode";

const AuthService = {
  getAccessToken() {
    return LocalStorageService.getItem("token") || null;
  },

  setAccessToken(aToken) {
    LocalStorageService.setItem("token", aToken);
  },

  getTokenExpire() {
    LocalStorageService.getItem("tokenExpire");
  },

  isAuthenticated() {
    const token = this.getAccessToken();
    if (token) {
      let decodedToken = token && jwtDecode(token);
      let currentDate = new Date();
      return !(decodedToken?.exp * 1000 < currentDate.getTime() || token);
    }
    return false;
  },

  getUser() {
    if (this.isAuthenticated()) {
      return LocalStorageService.getItem("user");
    } else {
      return null;
    }
  },

  setUser(user) {
    LocalStorageService.setItem("user", user);
  },

  logout() {
    LocalStorageService.removeItem("atoken");
    LocalStorageService.removeItem("user");
    LocalStorageService.clear();
    SessionService.clear();
    localStorage.clear();
    window.location.href = "/login";
  },
};

export default AuthService;
