import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import { Select, Input, Button } from "antd";
import { httpService } from "../../../../services/httpService.service";
import debounce from "lodash/debounce";

const { Option } = Select;

//months
const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

const startYear = 2024;
const endYear = 2034;
const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

const Filters = ({ filters, setFilters }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState();
  const contractId = localStorage.getItem("currentContractId");

  //Filters
  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const apiStates = `/api/GeoData/getCountriesOfContract?contractId=${contractId}`;

    httpService.get(
      apiStates,
      (res) => setCountries(res.data),
      (error) => console.log(error)
    );

    const apiClusters = "/api/Site/getAllClusters";

    httpService.get(
      apiClusters,
      (res) => setClusters(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleClearAll = () => {
    setFilters({
      current: 1,
      pageSize: 10,
      Search: "",
      ImplementingPartnerId: [],
      State: [],
      SiteVisitStatus: [],
      ClusterId: [],
      FieldTeamReportingStatus: "",
      Month: [],
      Year: [],
      Governates: [],
      Cities: [],
    });
    setCities([]);
    setStates([]);
  };

  const debouncedSearch = useCallback(
    debounce((Search) => {
      setFilters((prevFilters) => ({ ...prevFilters, Search, current: 1 }));
    }, 500),
    []
  );

  const handleSearch = (event) => {
    const Search = event.target.value.toLowerCase();
    debouncedSearch(Search);
  };

  const handleIp = (ip) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ImplementingPartnerId: ip,
      current: 1,
    }));
  };

  const handleCountryChange = (value, countries) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Governates: [],
      Cities: [],
    }));
    setStates([]);
    setCities([]);
    const countryIds = countries
      .filter((country) => country?.key)
      .map((country) => country.key);

    const url =
      `/api/GeoData/states?` +
      countryIds.map((id) => `countryIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setStates(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({
      ...prevFilters,
      State: value,
      current: 1,
    }));
  };

  const handleStateChange = (value, governates) => {
    setCities([]);
    setFilters((prevFilters) => ({ ...prevFilters, Cities: [], current: 1 }));
    const stateIds = governates
      .filter((state) => state?.key)
      .map((state) => state.key);

    const url =
      `/api/GeoData/cities?` + stateIds.map((id) => `stateIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setCities(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({ ...prevFilters, Governates: value }));
  };

  const handleCityChange = (Cities) => {
    setFilters((prevFilters) => ({ ...prevFilters, Cities, current: 1 }));
  };

  const handleClusterChange = (ClusterId) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ClusterId,
      current: 1,
    }));
  };

  const handleStatusChange = (status) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SiteVisitStatus: status,
      current: 1,
    }));
  };

  const handleReportingStatusChange = (FieldTeamReportingStatus) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      FieldTeamReportingStatus,
      current: 1,
    }));
  };

  const handleMonth = (Month) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Month,
      current: 1,
    }));
  };

  const handleYear = (Year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Year,
      current: 1,
    }));
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        {" "}
        <Input
          placeholder="Type something here"
          suffix={<img src={SearchIcon} alt="Search" />}
          onChange={handleSearch}
          value={filters.Search || undefined}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              onChange={handleIp}
              value={filters.ImplementingPartnerId || undefined}
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              onChange={handleClusterChange}
              value={filters.ClusterId || undefined}
            >
              {clusters &&
                clusters.map((cluster) => (
                  <Option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </Option>
                ))}
              {/* Add more gender options as needed */}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Site Visit Status</p>
            <Select
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              showSearch
              onChange={handleStatusChange}
              value={filters.SiteVisitStatus || undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option value={1}>No status</Option>
              <Option value={2}>Pre-planning phase</Option>
              <Option value={3}>Planning</Option>
              <Option value={4}>Data Collection</Option>
              <Option value={5}>Post data collection/Data check</Option>
              <Option value={6}>Visit completed</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Reporting status</p>
            <Select
              showSearch
              allowClear
              onChange={handleReportingStatusChange}
              value={filters.FieldTeamReportingStatus || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select reporting status"
            >
              <Option value={1}>Data collection</Option>
              <Option value={2}>Data check</Option>
              <Option value={3}>Field report writing</Option>
              <Option value={4}>Translation</Option>
              <Option value={5}>Field reporting completed</Option>
              <Option value={6}>Field report approved</Option>
              <Option value={7}>Field report submitted to client</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month</p>
            <Select
              showSearch
              allowClear
              mode="multiple"
              onChange={handleMonth}
              value={filters.Month || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a month"
            >
              {months &&
                months.map((month) => (
                  <Option key={month.id} value={month.name}>
                    {month.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <Select
              showSearch
              mode="multiple"
              allowClear
              onChange={handleYear}
              value={filters.Year || undefined}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a year"
            >
              {years &&
                years.map((year, index) => (
                  <Option key={index} value={year}>
                    {year}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Country</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              showSearch
              mode="multiple"
              allowClear
              onChange={handleCountryChange}
              value={filters.State || undefined}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {countries &&
                countries.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.stateName}
                  </Option>
                ))}
            </Select>
          </div>
          {states?.length ? (
            <div className="filter">
              <p className="filterText">
                {cities?.length
                  ? "Governorate/State"
                  : "Governorate/State or City"}
              </p>
              <Select
                onChange={handleStateChange}
                value={filters.Governates ? filters.Governates : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {states.map((state) => (
                  <Option key={state.id} value={state.stateName}>
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
          {cities?.length ? (
            <div className="filter">
              <p className="filterText">City</p>
              <Select
                onChange={handleCityChange}
                value={filters.Cities ? filters.Cities : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.name}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Filters;
