import React, { useState, useCallback } from "react";
import { Input } from "antd";
import SearchIcon from "../../../../../Assets/svg/searchIcon.svg";

const Filters = ({ fetchDrivers }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      fetchDrivers(value.trim()); // Pass the trimmed search term
    }, 500),
    []
  );

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search by name"
          suffix={<img src={SearchIcon} alt="Search Icon" />}
        />
      </div>
    </div>
  );
};

export default Filters;
