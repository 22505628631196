import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  Card,
  Divider,
  Checkbox,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";
import Done from "../../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../../Assets/svg/exclamationMark.svg";

const { Option } = Select;

const EditDraftTask = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);

  const [taskCreators, setTaskCreators] = useState([]);
  const [siteVisits, setSiteVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [showEnumerators, setShowEnumerators] = useState(false);
  const contractId = localStorage.getItem("currentContractId");
  const [selectedTaskType, setSelectedTaskType] = useState(undefined);
  const [usersList, setUsersList] = useState([]);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    setShowEditModal(false);
    setSelectedTaskType(undefined);
    setUsersList([]);
    setTeam(null);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleSaveProgress = () => {
    setButtonLoading(true);
    const values = form.getFieldsValue();
    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      // setLoading(false);
      return;
    }

    const formattedDeadline = values.deadline
      ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    const formData = {
      name: values.taskName,
      deadline: formattedDeadline,
      collectionMethod: values.collectionMethod,
      type: values.taskType,
      otherType: values.otherType,
      description: values.description,
      numberOfInterviews: values.numberOfInterviews,
      contactInformation: values.contactInformation,
      additionalNotes: values.additionalNotes,
      status: values.taskStatus,
      siteIds: values.siteIds,
      ...(selectedTaskType !== 2
        ? { assigneeIds: values.assignedToList }
        : { teamId: team?.id }),
    };

    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(formData);
    const payload = {
      id: taskId,
      ProjectId: projectId,
      jsonData: jsonData,
      type: 4,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft task card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: "New draft task card added successfully!",
            text: message,
          });
          handleLeave();
          fetchData();
          fetchDraftsData();
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setButtonLoading(false);
        },
        (error) => {
          console.error("There was an error adding the task!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft task card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: ExclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 1000);
      });
  };

  const handleDelete = async () => {
    if (taskId) {
      const requestBody = [
        {
          id: taskId,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  const handleSubmit = async () => {
    const projectId = localStorage.getItem("currentProjectID");

    setButtonLoading(true);
    form
      .validateFields()
      .then((values) => {
        const formattedDeadline = values.deadline
          ? values.deadline.format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : null;

        const payload = {
          projectId,
          name: values.taskName,
          deadline: formattedDeadline,
          collectionMethod: values.collectionMethod,
          type: values.taskType,
          otherType: values.otherType,
          description: values.description,
          numberOfInterviews: values.numberOfInterviews,
          contactInformation: values.contactInformation,
          additionalNotes: values.additionalNotes,
          status: values.taskStatus,
          siteIds: [values.siteIds],
          ...(selectedTaskType !== 2
            ? { assigneeIds: values.assignedToList }
            : { teamId: team?.id }),
        };

        httpService.post(
          "/api/Tasks",
          payload,
          (response) => {
            handleDelete();
            const message =
              response.data?.message ||
              "The task was successfully added to your database.";
            setMessage({
              trigger: true,
              type: "success",
              icon: Done,
              title: "Task added successfully!",
              text: message,
            });

            setTimeout(() => {
              fetchData();
              fetchDraftsData();
            }, 400);
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setButtonLoading(false);
            handleLeave();
          },
          (error) => {
            console.error("Failed to submit task:", error);

            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this task, please try again.";

            setMessage({
              trigger: true,
              type: "danger",
              icon: ExclamationMark,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setButtonLoading(false);
          }
        );
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({ trigger: false });
        }, 3000);
        setButtonLoading(false);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchTeamBySiteVisit = async (siteVisitId) => {
    if (siteVisitId) {
      const queryString = `siteVisitIds[0]=${encodeURIComponent(siteVisitId)}`;

      httpService.get(
        `/api/Site/getTeamBySiteVisits?${queryString}`,
        (response) => {
          const teamOfSiteVisit = response.data[0];

          setTeam(teamOfSiteVisit);
        },
        (error) => {
          console.error("Failed to fetch team by site visit:", error);
        }
      );
    } else {
      setTeam(null);
    }
  };

  const handleSiteVisitChange = (value) => {
    setTeam(null);
    setUsersList([]);

    if (selectedTaskType === 2) {
      fetchTeamBySiteVisit(value);
    } else {
      getUsersList();
    }
  };

  const getUsersList = () => {
    httpService.get(
      `/api/User/getAllusers?IgnorePagination=true&ContractIds=${contractId}`,
      (res) => {
        console.log(res.data);
        setUsersList(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (taskId && visible && form) {
      const fetchData = async () => {
        setLoading(true); // Set loading to true initially

        try {
          // Fetch the task data
          const taskResponse = await new Promise((resolve, reject) => {
            httpService.get(
              `/api/Draft/getDraftById?id=${taskId}`,
              (response) => resolve(response),
              (error) => reject(error)
            );
          });

          const task = JSON.parse(taskResponse.data?.jsonData);

          const countryId = task?.taskLocation?.country?.id;
          const selectedSiteVisit = task?.siteIds || undefined;

          const assignees = task?.assigneeIds;
          // Set form fields
          form.setFieldsValue({
            taskName: task?.name || undefined,
            deadline: dayjs(task.deadline || undefined),
            country: countryId || undefined,
            city: task?.taskLocation?.city?.id || undefined,
            siteIds: selectedSiteVisit,
            district: task?.taskLocation?.district || undefined,
            collectionMethod: task?.collectionMethod || undefined,
            numberOfInterviews: task?.numberOfInterviews || 1,
            description: task?.description || undefined,
            contactInformation: task?.contactInformation || undefined,
            additionalNotes: task?.additionalNotes || undefined,
            taskType: task?.type || undefined,
            taskStatus: task?.status || undefined,
            ...(selectedTaskType != 2 &&
              selectedTaskType != undefined && { assignedToList: assignees }),
          });
          setSelectedTaskType(task?.type);
          // Fetch additional data
          const projectId = localStorage.getItem("currentProjectID");

          await httpService.get(
            `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&IgnorePagination=true`,
            (res) => setSiteVisits(res.data),
            (err) => console.log(err)
          );

          if (task?.type === 2 && selectedSiteVisit) {
            await fetchTeamBySiteVisit(selectedSiteVisit);
          }
          if (task?.type !== 2 && task?.type != undefined) {
            await getUsersList();
          }
          // Fetch task creators (team) for the site visit
          const teamResponse = await new Promise((resolve, reject) => {
            httpService.get(
              `/api/Site/getTeamBySiteVisits?siteVisitIds=${task?.taskSite?.id}`,
              (response) => resolve(response),
              (error) => reject(error)
            );
          });
          setTaskCreators(teamResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false); // Ensure loading is stopped once all data is fetched
        }
      };

      fetchData();
    }
  }, [taskId, form, visible]);

  if (!context) {
    return <></>;
  }
  const { fetchData, fetchDraftsData } = context;

  const userOptions = usersList?.map((user) => ({
    label: (
      <div>
        <h1 style={{ fontSize: "16px", margin: "0" }}>
          {user?.userDetails?.firstName && user?.userDetails?.lastName
            ? `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
            : user?.email}
        </h1>
        <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
          {user?.userContractRoles?.[0]?.role?.roleName || "No role specified"}
        </p>
      </div>
    ),
    value: user?.id,
  }));

  return (
    <Modal
      closable={false}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit Task Card</h2>
        </>
      }
      open={visible}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button
          className="inviteButtonTPM"
          key="back"
          onClick={handleSaveProgress}
          loading={buttonLoading}
        >
          Save progress
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Add New Task
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task Card Identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task Name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the Task Name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select
                placeholder="Select an option"
                defaultValue={selectedTaskType}
                value={selectedTaskType}
                onChange={(type) => {
                  setUsersList([]);
                  setShowEnumerators(false);
                  setTeam(null);
                  form.setFieldsValue({ siteIds: undefined });
                  setSelectedTaskType(type);
                }}
              >
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please select the site visit!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleSiteVisitChange}
              >
                {siteVisits?.map((visit) => (
                  <Option key={visit.id} value={visit.id}>
                    {visit?.identifier}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {team != null &&
              selectedTaskType === 2 &&
              form.getFieldValue("siteIds") && (
                <Form.Item
                  className="mb-0"
                  label="Assigned to (selected site visit team)"
                  name="assignedTo"
                >
                  <div className="form-control-plaintext">
                    <p
                      style={{
                        border: "2px solid #d9d9d9",
                        borderRadius: "10px",
                        padding: "8px 12px",
                        fontSize: "14px",
                        color: "#495057",
                      }}
                    >
                      <span>
                        {team != null || team || undefined
                          ? team?.name
                          : "Pick a site visit card to show the team"}
                      </span>
                    </p>

                    {team?.members && (
                      <button
                        style={{
                          marginTop: "0",
                          marginBottom: "15px",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "var(--2, #AA1A5F)",
                          fontWeight: "500",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowEnumerators(!showEnumerators);
                        }}
                      >
                        {showEnumerators
                          ? "Hide team members"
                          : "Show team members"}
                        <img
                          src={DownArrow}
                          style={
                            showEnumerators
                              ? { transform: "rotate(180deg)" }
                              : {}
                          }
                          alt="toggle"
                        />
                      </button>
                    )}
                  </div>
                </Form.Item>
              )}
            {showEnumerators && selectedTaskType === 2 && (
              <div className="userListContainer d-flex flex-column gap-5 mb-4">
                {team?.members?.map((member) => (
                  <div key={member.id}>
                    <h1 style={{ fontSize: "16px", margin: "0" }}>
                      {member?.firstName && member?.lastName
                        ? `${member?.firstName} ${member?.lastName}`
                        : member?.username}
                    </h1>
                    <p style={{ fontSize: "12px", margin: "0", color: "gray" }}>
                      {member.roleName || "No role specified"}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {usersList?.length > 0 &&
              selectedTaskType != 2 &&
              form.getFieldValue("siteIds") &&
              selectedTaskType != undefined && (
                <div className="userListContainer d-flex flex-column gap-5 mb-4">
                  <div
                    style={{
                      borderRadius: "6px",
                      padding: "1px 0px 1px 10px",
                      gap: "10px",
                      background: "#FADCEA",
                    }}
                  >
                    <h1
                      style={{
                        color: "var(--2, #AA1A5F)",
                        fontFamily: "Lato",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: 700 }}> Note: </span>Below is a
                      list of{" "}
                      <span style={{ fontWeight: 700 }}>
                        {" "}
                        users associated with this contract
                      </span>
                      . Select the user you want to assign to this task.
                    </h1>
                  </div>
                  <Form.Item name="assignedToList">
                    <Checkbox.Group options={userOptions} />
                  </Form.Item>
                </div>
              )}
            {selectedTaskType == 2 && (
              <Form.Item label="Collection method" name="collectionMethod">
                <Input
                  placeholder="Enter the collection method"
                  // onChange={(e) =>
                  //   handleInputChange("collectionMethod", e.target.value)
                  // }
                />
              </Form.Item>
            )}
          </Col>

          <Form.Item label="Task Description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the Task Description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter the number of interviews!",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject(new Error("Number must be 0 or greater!")),
              },
            ]}
            label="Number of interviews"
            name="numberOfInterviews"
          >
            <Input
              span={6}
              type="number"
              defaultValue={0}
              min={0}
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
          {/* <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item> */}
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task Status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the Task Status" },
            ]}
          >
            <Select placeholder="Select the Task Status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditDraftTask;
