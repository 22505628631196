import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Select, Spin } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";

const { Option } = Select;

const createIcon = (color, number) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="background-color: ${color}; border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; color: white; font-weight: bold; font-size:18px">${number}</div>`,
    iconSize: [40, 40],
  });
};

const WorldMap = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedIP, setSelectedIP] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);

  useEffect(() => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => setAllCountries(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  useEffect(() => {
    fetchSiteVisits();
  }, [selectedCountry, selectedStatus, selectedIP]);

  const fetchSiteVisits = () => {
    setLoading(true);
    const projectId = localStorage.getItem("currentProjectID");
    const params = new URLSearchParams({
      ProjectId: projectId,
      ...(selectedCountry && { State: selectedCountry }),
      ...(selectedStatus && { SiteVisitStatus: selectedStatus }),
      ...(selectedIP && { ImplementingPartnerId: selectedIP }),
    });

    httpService.get(
      `/api/Site/getAllSiteVisits?${params.toString()}&pageSize=1000`,
      (res) => {
        setData(res.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleMarkerClick = (siteVisitCardId) => {
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
    setSelectedSiteVisit(null);
  };

  return (
    <>
      <div className="dashboardLocationFilters">
        <div style={{ width: "100%" }}>
          <p>Country</p>
          <Select
            placeholder="Select Country"
            showSearch
            allowClear
            onChange={(value) => setSelectedCountry(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
            }
          >
            {allCountries.map((country) => (
              <Option key={country?.id} value={country?.stateName}>
                {country.stateName}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div style={{ width: "100%" }}>
          <p>Site Visit Status</p>
          <Select
            placeholder="Select a site visit status"
            onChange={(value) => setSelectedStatus(value)}
            allowClear
          >
            <Option value="1">Monitoring</Option>
            <Option value="2">Data collection in progress</Option>
            <Option value="3">Review</Option>
            <Option value="4">Delivered</Option>
            <Option value="5">Debrief</Option>
          </Select>
        </div> */}
        <div style={{ width: "100%" }}>
          <p>Implementing Partner</p>
          <Select
            placeholder="Select an IP"
            onChange={(value) => setSelectedIP(value)}
            allowClear
          >
            {implementingPartners.map((implementingPartner) => (
              <Option
                key={implementingPartner?.id}
                value={implementingPartner?.id}
              >
                {implementingPartner?.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ position: "relative", height: "450px", width: "100%" }}>
        {loading && (
          <Spin
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          />
        )}
        <MapContainer
          minZoom={1}
          center={[20, 0]}
          zoom={2}
          style={{ height: "100%", width: "100%" }}
          maxBounds={[
            [-90, -180],
            [90, 180],
          ]}
          maxBoundsViscosity={1.0}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {data.map((item, idx) => (
            <Marker
              key={idx}
              position={[
                item?.locationDetails?.latitude || 0,
                item?.locationDetails?.longitude || 0,
              ]}
              icon={createIcon("darkblue", idx + 1)}
              eventHandlers={{
                click: () => handleMarkerClick(item.id),
              }}
            ></Marker>
          ))}
        </MapContainer>
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleClosePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
    </>
  );
};

export default WorldMap;
