import { Button, Empty, Table, Input, Select, Spin, Tabs } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import React, { useEffect, useState, useRef } from "react";
import img1 from "../../../Assets/svg/date.svg";
import img2 from "../../../Assets/svg/budget.svg";
import img3 from "../../../Assets/svg/budget1.svg";
import { httpService } from "../../../services/httpService.service";
import invite from "../../../Assets/svg/export.svg";
import add from "../../../Assets/svg/addButton.svg";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";
import DeleteMemberIcon from "../../../Assets/svg/deleteMemberIcon.svg";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import { render } from "@testing-library/react";
import BudgetSavedTable from "./BudgetSavedTable";
import AddBudget from "./AddBudget";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import { exportToExcel } from "../../components/ExportToExcel/exportToExcel";

Chart.register(ArcElement);

const BudgetSummaryBody = () => {
  const [contracts, setContracts] = useState([]);
  const [showAddBudget, setShowAddBudget] = useState(false);
  const [showEditBudget, setShowEditBudget] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [contractData, setContractData] = useState([]);
  const [loadingContract, setLoadingContract] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});

  // Summary
  const [summaryData, setSummaryData] = useState({});
  const [loadingSummary, setLoadingSummary] = useState(false);

  //SavedTable
  const savedTable = useRef();

  // Tabs
  const tabs = [
    {
      key: "1",
      label: "Saved",
    },
  ];

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleToggleAddBudget = (e) => {
    if (e === "success") {
      setMessage({
        trigger: true,
        type: "success",
        icon: AddMemberIcon,
        title: "Your contract budget has been successfully added!",
        text: "This contract budget was successfully added!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      fetchSummaryData();
      fetchContracts();
      savedTable?.current?.reFetch();
      setShowAddBudget(!showAddBudget);
    } else if (e === "error") {
      setMessage({
        trigger: true,
        type: "danger",
        icon: DeleteMemberIcon,
        title: "Oops! A problem has occurred!",
        text: "We had a problem adding your contract budget, please try again.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    } else {
      setShowAddBudget(!showAddBudget);
    }
  };

  const handleToggleEditBudget = () => {
    setShowEditBudget(!showEditBudget);
  };

  const handleDelete = () => {
    if (!selectedRowData?.id) return;

    httpService.delete(
      "/api/User/deleteTeam",
      { id: selectedRowData?.id },
      () => {
        setShowDeleteModal(false);
        setSelectedRowData(null);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Team card deleted successfully!",
          text: "This team card has been deleted from your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem deleting this team card from your database, please try again!`;
        setMessage({
          trigger: true,
          type: "error",
          icon: "",
          title: "Failed to delete this team card",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      }
    );
  };

  const addContract = () => {
    const newContract = {
      key: contracts.length,
      contractName: `Contract ${contracts.length + 1}`,
      budget: `${(Math.random() * 10000).toFixed(2)} USD`,
    };
    setContracts([...contracts, newContract]);
  };

  const data = {
    labels: ["Total", "Neto"],
    datasets: [
      {
        data: [
          summaryData?.availableBudget,
          summaryData?.averageMonthlySpending,
        ],
        backgroundColor: ["#F5B0D2", "#AA1A5F"],
        hoverBackgroundColor: ["#F5B0D2", "#AA1A5F"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,

    maintainAspectRatio: false,

    cutout: "70%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  const exportContract = () => {
    exportToExcel(
      [summaryData],
      `BudgetSummary_${
        selectedContract?.label ? selectedContract?.label : "All"
      }`
    );
  };

  const fetchContracts = () => {
    setLoadingContract(true);
    httpService.get(
      `/api/Contract/getAll?ignorePagination=true`,
      (res) => {
        setContractData(
          res?.data?.map((el) => ({ value: el?.id, label: el?.name }))
        );
        setLoadingContract(false);
      },
      (err) => {
        setLoadingContract(false);
      }
    );
  };

  const fetchSummaryData = (contractId) => {
    setLoadingSummary(true);
    httpService.get(
      `/api/Contract/getContractBudgetSummary${
        contractId ? `?contractId=${contractId}` : ""
      }`,
      (res) => {
        setSummaryData(res?.data);
        setLoadingSummary(false);
      },
      (err) => {
        setLoadingSummary(false);
      }
    );
  };

  const onContractSelect = (data) => {
    setSelectedContract(data);
    fetchSummaryData(data?.value);
  };

  const callSummary = () => {
    fetchSummaryData();
  };

  useEffect(() => {
    fetchContracts();
    fetchSummaryData();
  }, []);

  return (
    <div className="budgetSummaryBody">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="bodyContent">
        <h3>Budget Summary</h3>
        <div className="budgetButtons">
          <Button className="exportBtn" onClick={exportContract}>
            <img src={invite}></img> Export contract budget
          </Button>
          <Button className="contractBtn" onClick={handleToggleAddBudget}>
            <img src={add}></img> Add contract budget details
          </Button>
        </div>
      </div>
      <div className="contractSelect">
        <label>Select a contract</label>
        <Select
          style={{ maxWidth: "250px" }}
          placeholder="All contracts"
          allowClear
          onChange={(id, data) => onContractSelect(data)}
          options={contractData}
          loading={loadingContract}
        >
          <Select.Option> TEST </Select.Option>
        </Select>
      </div>
      <div className="contractStatistics">
        <h4>Contract statistics - Field team</h4>

        {loadingSummary ? (
          <>
            <Spin
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                padding: "20px 0",
              }}
            />
          </>
        ) : summaryData?.length === 0 ? (
          <div className="noDataToDisplay">
            <Empty description="No data to display" />
          </div>
        ) : (
          <>
            <div className="mainContractDiv">
              <div className="contractDivs">
                <h3>Spending</h3>
                <div style={{ width: "100%", height: "200px" }}>
                  <Doughnut data={data} options={options} />
                </div>
              </div>
              <div className="contractDivs">
                <h3>Field staff budget</h3>
                <p className="numberDiv">
                  ${summaryData?.availableBudget?.toFixed(1)}
                </p>
                <p className="textDiv">Available budget</p>
              </div>
              <div className="contractDivs">
                <h3>Monthly Spending</h3>
                <p className="numberDiv">
                  ${summaryData?.averageMonthlySpending?.toFixed(1)}
                </p>
                <p className="textDiv">Average monthly spending</p>
              </div>
            </div>
            <div className="secondMainDiv">
              <div className="secondNumberDiv">
                {summaryData?.workingDays}{" "}
                <span className="workingDays">working days</span>
              </div>
              <div className="secondDateDiv">
                <img src={img1}></img>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="staffInformation">
        <h4>Staff Information</h4>
        {loadingSummary ? (
          <>
            <Spin
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                padding: "20px 0",
              }}
            />
          </>
        ) : summaryData.length === 0 ? (
          <div className="noDataToDisplay">
            <Empty description="No data to display" />
          </div>
        ) : (
          <>
            <div className="mainStaffDiv">
              <div className="groupDiv">
                <div className="staffDiv">
                  <div className="secondNumberDiv">
                    {summaryData?.fieldStaffMembers}
                    <span className="workingDays">Field staff members</span>
                  </div>
                  <div className="secondDateDiv">
                    <img src={img2}></img>
                  </div>
                </div>
                <div className="staffDiv">
                  <div className="secondNumberDiv">
                    {summaryData?.averageDailyRate
                      ? summaryData?.averageDailyRate?.toFixed(2)
                      : 0}
                    <span className="workingDays">Daily rate</span>
                  </div>
                  <div className="secondDateDiv">
                    <img src={img3}></img>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <BudgetSavedTable ref={savedTable} callSummary={() => callSummary()} />
      <AddBudget
        visible={showAddBudget}
        onClose={(e) => handleToggleAddBudget(e)}
        callSummary={() => callSummary()}
      />

      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. This action can’t be undone."}
        header={"Delete"}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default BudgetSummaryBody;
