import React, { useState, useEffect } from "react";
import "./VisitBoard.scss";
import { Button, Spin, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import AddButton from "../../../../Assets/svg/addButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import TableView from "./TableView/TableView";
import AddSiteVisitCardModal from "./Modals/AddSiteVisitCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import DraftsTable from "./TableView/DraftsTable";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";
import ExportTable from "../../../components/ExportExcel/exportTable";
import ExportSelectedTable from "../../../components/ExportExcel/ExportSelectedExcel/exportSelectedTable";
import KanbanView from "../../planning/VisitBoard/KanbanView/KanbanView";

function VisitBoard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [selectedSiteVisitIds, setSelectedSiteVisitIds] = useState([]);
  const [draftSiteVisitCards, setDraftSiteVisitCards] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draftsLoading, setDraftsLoading] = useState(false);
  const [savingProgress, setSavingProgress] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [draftCount, setDraftCount] = useState(0);
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: [],
    State: [],
    SiteVisitStatus: [],
    ClusterId: [],
    Month: [],
    Year: [],
    Governates: [],
    Cities: [],
  });
  const [draftsPagination, setDraftPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const handleModalCancel = () => {
    setIsExportModalVisible(false);
  };

  const handleExportClick = () => {
    setIsExportModalVisible(true); // Show modal when export button is clicked
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards();
    fetchDraftSiteVisitCards(projectID);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRowSelectionChange = (selectedIds) => {
    setSelectedSiteVisitIds(selectedIds);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const onChange = (key) => {
    if (key === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }
  };

  const fetchDraftSiteVisitCards = async (projectID, current, pageSize) => {
    setDraftsLoading(true);
    setSavingProgress(true);
    httpService.get(
      `/api/Draft/getAll?Type=1&ProjectId=${projectID}&pageNumber=${current}&pageSize=${pageSize}`,
      (response) => {
        const sitevisitcardsData = response?.data;

        setDraftCount(response?.metaData?.totalItemCount);
        setDraftPagination({
          ...draftsPagination,
          current: response?.metaData?.pageNumber,
          pageSize: response?.metaData?.pageSize,
          total: response?.metaData?.totalItemCount,
        });
        if (sitevisitcardsData.length > 0) {
          setDraftSiteVisitCards(sitevisitcardsData);
        } else {
          setDraftSiteVisitCards([]);
        }
        setDraftsLoading(false);
        setSavingProgress(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setDraftsLoading(false);
        setSavingProgress(false);
      }
    );
  };

  const fetchSiteVisitCards = async (ignorePagination) => {
    setLoading(true); // Show loader before request starts
    const projectID = localStorage.getItem("currentProjectID");
    const params = {
      ProjectId: projectID,
      Search: filters.Search,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters.SiteVisitStatus?.length
        ? filters.SiteVisitStatus
        : [2, 3],
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      Governates: filters?.Governates,
      Cities: filters.Cities,
      IgnorePagination: ignorePagination || false,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    };

    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();

    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}&IncludeOnlyWithTracking=false`,
      (response) => {
        const sitevisitcardsData = response?.data;
        setSiteVisitCards(sitevisitcardsData);
        setPagination({
          ...pagination,
          total: response?.metaData?.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ pageSize, current: pageNumber });
  };

  const onDraftPageChange = (page, pageSize) => {
    fetchDraftSiteVisitCards(projectID, page, pageSize);
  };

  const items = [
    {
      key: "1",
      label: "Table View",
      children: (
        <TableView
          onOk={handleOk}
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          onRowSelectionChange={handleRowSelectionChange}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban View",
      children: (
        <KanbanView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          filters={filters}
          setFilters={setFilters}
          setSiteVisitCards={setSiteVisitCards}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar View",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
    {
      key: "4",
      label: (
        <span className="custom-tab-label">
          Site Visit Drafts{" "}
          {loading ? (
            <span className="loader">
              <Spin />
            </span>
          ) : (
            <span className="draft-count">{draftCount}</span>
          )}
        </span>
      ),
      children: (
        <DraftsTable
          onOk={handleOk}
          siteVisitCards={draftSiteVisitCards}
          setSiteVisitCards={setDraftSiteVisitCards}
          fetchDraftSiteVisitCards={fetchDraftSiteVisitCards}
          draftsLoading={draftsLoading}
          setDraftsLoading={setDraftsLoading}
          onDraftPageChange={onDraftPageChange}
          draftsPagination={draftsPagination}
          setDraftPagination={setDraftPagination}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchSiteVisitCards();
  }, [filters, items.key, pagination.pageSize, pagination.current]);

  useEffect(() => {
    fetchDraftSiteVisitCards(projectID, 1, draftsPagination.pageSize);
  }, []);

  return (
    <div className="ip-planning-wrapper">
      <div className="tableViewcontainer visitBoardComponent">
        <div className="tableHeader">
          <MessagePopUp
            trigger={message.trigger}
            type={message.type}
            icon={message.icon}
            messageTitle={message.title}
            messageText={message.text}
          />
          <span className="tableViewHeaderText">Visits Board</span>
          <div className="tableViewHeaderButtons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Button className="inviteButtonTPM" onClick={showDeletedModal}>
                <img src={DeleteButton} alt="DeleteButton" />
                See deleted cards
              </Button>
            )}
            <Button
              className={`addButtonTPM ${removeExportButton ? "d-none" : ""}`}
              onClick={handleExportClick}
            >
              <img src={ExportButton} alt="ExportButton" />
              Export Selected Site Visits
            </Button>
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldLogistics,
            ]) && (
              <Button
                className="exportButtonTPM addButtonTPM"
                onClick={showModal}
              >
                <img src={AddButton} alt="AddButton" />
                Add New Site Visit Card
              </Button>
            )}
            <AddSiteVisitCardModal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              setMessage={setMessage}
              savingProgress={savingProgress}
              setSavingProgress={setSavingProgress}
            />
            <DeletedModal
              visible={isDeletedModalVisible}
              onOk={showDeletedModal}
              onCancel={() => {
                setIsDeletedModalVisible(false);
              }}
              fetchSiteVisitCards={fetchSiteVisitCards}
            />
          </div>
        </div>
        <div className="tab-container">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="tableviewTabs"
          />
        </div>
      </div>
      <ExportSelectedTable
        visible={isExportModalVisible}
        onCancel={handleModalCancel}
        selectedSiteVisitIds={selectedSiteVisitIds}
      />
    </div>
  );
}

export default VisitBoard;
