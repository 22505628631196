import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Button,
  Select,
  Form,
  DatePicker,
  Card,
  Divider,
  Tooltip,
} from "antd";
import { Formik, Form as FormikForm, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import "../styles.scss";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../Assets/svg/exclamationMark.svg";
import { httpService } from "../../../../services/httpService.service";
import { RemoveButton, PlusIcon } from "../../../../Assets/svg/index";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;
const { RangePicker } = DatePicker;

const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  projectCode: Yup.string().required("Please enter a round code"),
  clients: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Please enter a client name"),
      })
    )
    .min(1, "At least one client must be present")
    .required(),
  contractNumber: Yup.string().required("Please enter a contract number"),
  contractDates: Yup.array()
    .of(Yup.date().nullable())
    .required("Please select contract dates")
    .test(
      "required",
      "Please select contract dates",
      (value) => value && value.length === 2
    ),
  referenceDocuments: Yup.string(),
  linkToSharedDrive: Yup.string()
    .required("Please enter a link to shared drive")
    .matches(urlRegex, "Please enter a valid URL"),
  countryId: Yup.array()
    .of(Yup.string().required("Country is required"))
    .min(1, "Please select at least one country"),
});

const AddWorkspaceModal = ({ visible, onClose, onSubmit, setMessage }) => {
  const [country, setCountry] = useState([]);
  const initialValues = {
    description: "",
    projectCode: "",
    clients: [{ name: "" }],
    contractNumber: "",
    contractDates: [],
    referenceDocuments: "",
    linkToSharedDrive: "",
    countryId: [],
  };

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    httpService.get(
      apiCountries,
      (res) => setCountry(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const transformedValues = {
      ...values,
      clientName: values.clients.map((client) => client.name),
      contractStartDate: dayjs(values.contractDates[0]).format("YYYY-MM-DD"),
      contractEndDate: dayjs(values.contractDates[1]).format("YYYY-MM-DD"),
    };
    delete transformedValues.contractDates;

    await httpService.post(
      "/api/Contract",
      transformedValues,
      (response) => {
        const message =
          response.message ||
          `A new contract has been created and added to your database!`;
        onClose();
        onSubmit();
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: `Contract added successfully!`,
          text: message,
        });
        setSubmitting(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "There was a problem adding this contract to the database. Please try again later!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: `Oops! A problem has occurred!`,
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setSubmitting(false);
      }
    );
  };

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      closable={false}
      className="modalAddWorkspaceStyle"
      width={800}
    >
      <img
        src={cancelX}
        alt="BlackXIcon"
        onClick={handleCancel}
        style={{ float: "right", cursor: "pointer" }}
      />
      <h1 className="title-assessment-modal">New contract</h1>
      <div className="admin-workspace">
        <div className="top-form">
          <div className="login-form login-signin" style={{ display: "block" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, { setSubmitting })
              }
            >
              {({ isSubmitting, setFieldValue, values, handleChange }) => (
                <FormikForm>
                  <div
                    style={{
                      fontStyle: "Poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#AA1A5F",
                    }}
                  >
                    Contract Details
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>
                        <span>*</span>Project Round Code
                      </label>
                    </div>
                    <Form.Item>
                      <Input
                        name="projectCode"
                        onChange={handleChange}
                        value={values.projectCode}
                        autoComplete="off"
                        placeholder="Enter project round code"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage
                        name="projectCode"
                        component="div"
                        className="error-message"
                      />
                    </Form.Item>
                  </div>

                  <div>
                    <div className="label-name-assessment-modal">
                      <label>
                        <span>*</span>Contract Number
                      </label>
                    </div>
                    <Form.Item>
                      <Input
                        name="contractNumber"
                        onChange={handleChange}
                        value={values.contractNumber}
                        autoComplete="off"
                        placeholder="Enter contract number"
                        style={{ height: "37px", borderRadius: "4px" }}
                      />
                      <ErrorMessage
                        name="contractNumber"
                        component="div"
                        className="error-message"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <Tooltip title="The list of countries you select now will be shown in this contract.">
                        <label style={{ width: "120px" }}>
                          <span>*</span> Country
                          <InfoCircleOutlined
                            style={{
                              color: "gray",
                              marginLeft: "8px",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                      </Tooltip>
                    </div>
                    <Select
                      name="countryId"
                      showSearch
                      allowClear
                      mode="multiple"
                      placeholder="Select a country"
                      style={{ height: "38%" }}
                      onChange={(value) => setFieldValue("countryId", value)}
                      value={values.countryId}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .startsWith(input.toLowerCase())
                      }
                    >
                      {country.map((c) => (
                        <Option key={c.id} value={c.id}>
                          {c.stateName}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="countryId"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="w-100">
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Contract Dates
                        </label>
                      </div>
                      <Form.Item style={{ width: "100%" }}>
                        <RangePicker
                          name="contractDates"
                          value={values.contractDates}
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          onChange={(value) =>
                            setFieldValue("contractDates", value)
                          }
                        />
                        <ErrorMessage
                          name="contractDates"
                          component="div"
                          className="error-message"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <FieldArray name="clients">
                    {({ remove, push }) => (
                      <Card
                        style={{
                          marginBottom: "10px",
                          background: "#FFF5FA",
                          borderRadius: "8px",
                        }}
                      >
                        {values.clients.map((client, index) => (
                          <div key={index} style={{ marginBottom: "10px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <label
                                style={{
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "#AA1A5F",
                                }}
                              >{`Client ${index + 1}`}</label>
                              {values.clients.length > 1 && (
                                <img
                                  src={RemoveButton}
                                  style={{ cursor: "pointer" }}
                                  alt="RemoveButton"
                                  onClick={() => remove(index)}
                                />
                              )}
                            </div>
                            <Form.Item>
                              <div className="label-name-assessment-modal">
                                <label>
                                  <span>*</span>Enter client name
                                </label>
                              </div>
                              <Input
                                name={`clients[${index}].name`}
                                value={client.name}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="Enter client name"
                                style={{ height: "37px", borderRadius: "4px" }}
                              />
                              <ErrorMessage
                                name={`clients[${index}].name`}
                                component="div"
                                className="error-message"
                              />
                            </Form.Item>
                            {index < values.clients.length - 1 && <Divider />}
                          </div>
                        ))}
                        <Button
                          onClick={() => push({ name: "" })}
                          type="dashed"
                          style={{
                            border: "1px dashed #AA1A5F",
                            borderRadius: "7px",
                            height: "60px",
                            width: "100%",
                            background: "#FADCEA",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "6px",
                              justifyContent: "center",
                            }}
                          >
                            <img src={PlusIcon} alt="PlusIcon" />
                            <h1
                              style={{
                                margin: 0,
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#AA1A5F",
                              }}
                            >
                              Add another client
                            </h1>
                          </div>
                        </Button>
                      </Card>
                    )}
                  </FieldArray>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>Contract description</label>
                    </div>
                    <Form.Item>
                      <Input.TextArea
                        value={values.description}
                        onChange={handleChange}
                        name="description"
                        placeholder="Enter contract description"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label-name-assessment-modal">
                      <label>Links to reference documents</label>
                    </div>
                    <Form.Item>
                      <Input.TextArea
                        name="referenceDocuments"
                        onChange={handleChange}
                        value={values.referenceDocuments}
                        autoComplete="off"
                        placeholder="Enter links to reference documents"
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Tooltip title="Please give a valid link ex: http://... or http://... or www...">
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Link to Shared Drive
                        </label>
                      </div>
                      <Form.Item>
                        <Input
                          name="linkToSharedDrive"
                          value={values.linkToSharedDrive}
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder="http://www.link-shared-drive.com"
                          style={{ height: "37px", borderRadius: "4px" }}
                        />
                        <ErrorMessage
                          name="linkToSharedDrive"
                          component="div"
                          className="error-message"
                        />
                      </Form.Item>
                    </Tooltip>
                  </div>
                  <div className="d-flex justify-content-around">
                    <div className="footer-buttons">
                      <Button
                        className="cancel-workspace-button"
                        key="cancel"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="add-workspace-button"
                        key="success"
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting}
                      >
                        Add new Contract
                      </Button>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddWorkspaceModal;
