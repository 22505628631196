import { Modal, Form, Input, Button, Divider, Typography, Spin } from "antd";
import "./style.scss";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import TrackingDetails from "./TrackingDetails";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import Done from "../../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import dayjs from "dayjs";
import Preview from "../TableView/modals/Preview";

const { TextArea } = Input;

const { Title } = Typography;

const TrackingModal = ({
  visible,
  onCancel,
  onOk,
  siteVisitCardId,
  tracked,
  setMessage,
}) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enumerators, setEnumerators] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showVisitDetails, setShowVisitDetails] = useState(false);
  const [siteVisitId, setSiteVisitId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [siteVisitStatus, setSiteVisitStatus] = useState(null);
  const [treningCompleted, setTreningCompleted] = useState(false);

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    if (siteVisitCardId && visible) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setSiteVisitId(res?.data?.id);
          const teamLeader = res?.data?.logistic?.team?.teamLead;
          const formatedTeamLeaderObj = {
            email: `${teamLeader?.name} - (Team Leader)`,
            firstName: teamLeader?.firstName,
            id: teamLeader?.id,
            lastName: `${teamLeader?.lastName} - (Team Leader)`,
            position: teamLeader?.position,
          };
          const enumeratorsData = [
            formatedTeamLeaderObj,
            ...res?.data?.logistic?.team?.enumerators,
          ];
          if (!tracked) {
            setEnumerators(enumeratorsData);
            const siteVisitStatus = res?.data?.siteVisitStatus;
            const treningCompleted = res?.data?.tracking?.trainingIsCompleted;
            siteVisitStatus == 5
              ? setSiteVisitStatus(5)
              : setSiteVisitStatus(null);
            treningCompleted == true
              ? setTreningCompleted(true)
              : setTreningCompleted(false);
          }

          if (tracked) {
            setTrackingId(res?.data?.tracking?.id);
            const enumerators = res?.data?.tracking?.enumerators;
            setEnumerators(enumerators);
            const enumeratorsProgress = enumerators.reduce(
              (acc, enumerator, index) => {
                acc[`trainingScore-${index}`] = enumerator?.trainingScore || "";
                acc[`percentageCompleted-${index}`] =
                  enumerator?.percentageCompleted || "";
                return acc;
              },
              {}
            );

            const siteVisitStatus = res?.data?.siteVisitStatus;
            const treningCompleted = res?.data?.tracking?.trainingIsCompleted;

            const initialData = {
              ...enumeratorsProgress,

              identifier: res?.data?.identifier,
              actualDateOfVisit: dayjs(res?.data?.tracking?.actualDateOfVisit),
              dateOfReport: dayjs(res?.data?.tracking?.dateOfReport),
              dateOfDebrief: dayjs(res?.data?.tracking?.dateOfDebrief),
              fieldTeamReportingStatus:
                res?.data?.tracking?.fieldTeamReportingStatus,
              percentageOfDataCollectionCompleted:
                res?.data?.tracking?.percentageOfDataCollectionCompleted,
              reportingMonth: res?.data?.tracking?.reportingMonth,
              reportingYear: res?.data?.tracking?.reportingYear,
              siteVisitStatus: siteVisitStatus,
              trainingDate: dayjs(res?.data?.tracking?.trainingDate),
              trainingIsCompleted: treningCompleted,
              trainingScoreInfo: res?.data?.tracking?.trainingScoreInfo,
            };
            siteVisitStatus == 5
              ? setSiteVisitStatus(5)
              : setSiteVisitStatus(null);
            treningCompleted == true
              ? setTreningCompleted(true)
              : setTreningCompleted(false);

            form.setFieldsValue(initialData);
          } else {
            const initialData = {
              identifier: res?.data?.identifier,
            };
            form.setFieldsValue(initialData);
          }

          setLoading(false);
        },
        (err) => {
          console.log("Error: ", err);
          setLoading(false);
        }
      );
    }
  }, [visible]);

  const closeModal = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    onCancel();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = moment(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        const formData = new FormData();

        if (tracked) {
          const data = {
            id: trackingId,
            reportingYear: values.reportingYear,
            reportingMonth: values.reportingMonth,
            trainingDate: trainingDate,
            trainingScoreInfo: "string",
            actualDateOfVisit: actualDateOfVisit,
            dateOfReport: dateOfReport,
            dateOfDebrief: dateOfDebrief,
            percentageOfDataCollectionCompleted: 0,
            siteVisitStatus: values?.siteVisitStatus,
            fieldTeamReportingStatus: values?.fieldTeamReportingStatus,
            trainingIsCompleted: values.trainingIsCompleted || false,
            enumerators: enumerators?.map((enumerator) => ({
              enumeratorId: enumerator?.enumeratorId,
              ...(enumerator?.trainingScore
                ? { trainingScore: enumerator?.trainingScore }
                : {}),
              ...(enumerator?.percentageCompleted
                ? { percentageCompleted: enumerator?.percentageCompleted }
                : {}),
            })),
          };
          console.log("Payload:", data);

          httpService.put(
            "/api/Site/updateTracking",
            data,
            (res) => {
              const message =
                res?.message ||
                "You have successfully updated tracking in site visit";
              setMessage({
                trigger: true,
                type: "success",
                icon: Done,
                title: `Tracking of site visit updated successfully!`,
                text: message,
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
              form.resetFields();
              onOk();
            },
            (error) => {
              const errorMessage =
                error?.errors?.[0]?.message ||
                "Failed to create tracking for site visit. Please try again later.";

              setMessage({
                trigger: true,
                type: "danger",
                icon: ExclamationMark,
                title: "Update Failed",
                text: errorMessage,
              });

              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);

              setSubmitLoader(false);
            },
            {
              headers: {
                "Content-Type": "application/json-patch+json",
              },
            }
          );
        } else {
          formData.append("siteVisitId", siteVisitCardId);
          formData.append("reportingYear", values.reportingYear);
          formData.append("reportingMonth", values.reportingMonth);
          formData.append("trainingDate", trainingDate);
          formData.append("actualDateOfVisit", actualDateOfVisit);
          formData.append("dateOfReport", dateOfReport);
          formData.append("dateOfDebrief", dateOfDebrief);
          formData.append("siteVisitStatus", values?.siteVisitStatus);
          formData.append(
            "fieldTeamReportingStatus",
            values?.fieldTeamReportingStatus
          );
          formData.append(
            "trainingIsCompleted",
            values.trainingIsCompleted || false
          );
          enumerators?.forEach((enumerator, index) => {
            formData.append(
              `enumerators[${index}].enumeratorId`,
              enumerator?.id
            );
            if (enumerator?.trainingScore) {
              formData.append(
                `enumerators[${index}].trainingScore`,
                enumerator?.trainingScore
              );
            }
            if (enumerator?.percentageCompleted != null) {
              formData.append(
                `enumerators[${index}].percentageCompleted`,
                enumerator.percentageCompleted
              );
            }
          });

          httpService.post(
            "/api/Site/createTracking",
            formData,
            (res) => {
              const message =
                res.message || "You have successfully tracked site visit";

              setMessage({
                trigger: true,
                type: "success",
                icon: Done,
                title: `Tracking of site visit updated successfully!`,
                text: message,
              });
              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
              form.resetFields();
              onOk();
            },
            (error) => {
              const errorMessage =
                error?.errors?.[0]?.message ||
                "Failed to create tracking for site visit. Please try again later.";

              setMessage({
                trigger: true,
                type: "danger",
                icon: ExclamationMark,
                title: "Update Failed",
                text: errorMessage,
              });

              setTimeout(() => {
                setMessage({
                  trigger: false,
                });
              }, 3000);
              setSubmitLoader(false);
            }
          );
        }
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };
  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };
  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={closeModal}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site visit card</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal} className="inviteButtonTPM">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          {tracked ? `Edit Tracking` : `Add Tracking`}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <div className="d-flex justify-content-between align-items-center gap-4 w-100">
            <div className="indentifierTracking w-100">
              <Form.Item
                className="w-100"
                label="Site Visit Card Identifier"
                name="identifier"
              >
                <Input disabled />
              </Form.Item>
            </div>

            <Preview
              visible={showVisitDetails}
              onClose={() => setShowVisitDetails(false)}
              siteVisitCardId={siteVisitId}
            />
            <Button
              className="btnSeeSiteDetails d-flex mt-1 justify-content-center"
              onClick={() => setShowVisitDetails(!showVisitDetails)}
            >
              See site visit card
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 12L10 8L6 4"
                  stroke="#AA1A5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
          <Divider />
          <h3 className="m-0 mb-4 p-0">Tracking</h3>
          <TrackingDetails
            form={form}
            enumerators={enumerators}
            setEnumerators={setEnumerators}
            siteVisitStatus={siteVisitStatus}
            setSiteVisitStatus={setSiteVisitStatus}
            treningCompleted={treningCompleted}
            setTreningCompleted={setTreningCompleted}
          />
          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default TrackingModal;
