import React, { useCallback, useEffect, useState } from "react";
import { Select, Input, Button, DatePicker, Spin } from "antd";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import { debounce } from "lodash";

const months = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

const startYear = 2024;
const endYear = 2034;
const years = Array.from(
  { length: endYear - startYear + 1 },
  (_, i) => startYear + i
);
const { Option } = Select;

const Filters = ({ filters, setFilters, setPagination, tracked }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState([]);
  const contractId = localStorage.getItem("currentContractId");

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearAll = () => {
    setFilters({
      Search: "",
      ImplementingPartnerId: [],
      State: [],
      SiteVisitStatus: [],
      ClusterId: [],
      Month: [],
      Year: [],
      Governates: [],
      Cities: [],
      IgnorePagination: false,
    });
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    setStates([]);
    setCities([]);
  };

  const debouncedSearchHandler = useCallback(
    debounce((query) => {
      setFilters({
        ...filters,
        Search: query,
      });
      setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const search = e.target.value;
    debouncedSearchHandler(search);
  };

  const handleIp = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ImplementingPartnerId: value,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleProgressStatus = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      SiteVisitStatus: value,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleLocation = (value, countries) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Governates: [],
      Cities: [],
    }));
    setStates([]);
    setCities([]);
    const countryIds = countries
      .filter((country) => country?.key)
      .map((country) => country.key);

    const url =
      `/api/GeoData/states?` +
      countryIds.map((id) => `countryIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setStates(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({
      ...prevFilters,
      State: value,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleStateChange = (value, governates) => {
    setCities([]);
    setFilters((prevFilters) => ({ ...prevFilters, Cities: [] }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    const stateIds = governates
      .filter((state) => state?.key)
      .map((state) => state.key);

    const url =
      `/api/GeoData/cities?` + stateIds.map((id) => `stateIds=${id}`).join("&");

    httpService.get(
      url,
      (res) => {
        setCities(res?.data);
      },
      (error) => {
        console.log(error);
      }
    );

    setFilters((prevFilters) => ({ ...prevFilters, Governates: value }));
  };

  const handleCityChange = (Cities) => {
    setFilters((prevFilters) => ({ ...prevFilters, Cities }));

    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleCluster = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ClusterId: value,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleMonth = (month) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Month: month,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleYear = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      Year: year,
    }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  useEffect(() => {
    setLoading(true);
    httpService.get(
      `/api/GeoData/getCountriesOfContract?contractId=${contractId}`,
      (res) => {
        setAllCountries(res.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );

    httpService.get(
      "/api/Site/getAllClusters",
      (res) => setClusters(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
          placeholder="Search by site visit identifier"
          value={filters.search}
          onChange={handleSearchChange}
          suffix={<img src={SearchIcon} alt="search icon" />}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              mode="multiple"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              showSearch
              value={filters.ImplementingPartnerId}
              onChange={handleIp}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              suffixIcon={loading ? <Spin size="small" /> : null}
            >
              {implementingPartners.map((partner) => (
                <Option key={partner.id} value={partner.id}>
                  {partner.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Progress Status</p>
            <Select
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a progress status"
              allowClear
              mode="multiple"
              showSearch
              value={filters.SiteVisitStatus}
              onChange={handleProgressStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {/* <Option value={1}>No status</Option> */}
              {!tracked && (
                <>
                  <Option value={2}>Pre-planning phase</Option>
                  <Option value={3}>Planning</Option>
                </>
              )}
              {tracked && (
                <>
                  <Option value={4}>Data Collection</Option>
                  <Option value={5}>Post data collection/Data check</Option>
                </>
              )}
              {/* <Option value={6}>Visit completed</Option> */}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Cluster</p>
            <Select
              onChange={handleCluster}
              value={filters.ClusterId}
              mode="multiple"
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a cluster"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {clusters.map((cluster) => (
                <Option key={cluster.id} value={cluster.id}>
                  {cluster.name}
                </Option>
              ))}
            </Select>
          </div>
          {tracked && (
            <>
              <div className="filter">
                <p className="filterText">Month</p>
                <Select
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select month"
                  onChange={handleMonth}
                  allowClear
                  mode="multiple"
                  showSearch
                  value={filters.Month ? filters.Month : undefined}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {months.map((month) => (
                    <Option key={month.id} value={month.id}>
                      {month.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="filter">
                <p className="filterText">Year</p>
                <Select
                  onChange={handleYear}
                  allowClear
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select year"
                  value={filters.Year ? filters.Year : undefined}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {years.map((year, index) => (
                    <Option key={index} value={year}>
                      {year}
                    </Option>
                  ))}

                  {/* Add more role options as needed */}
                </Select>
              </div>
            </>
          )}
          <div className="filter">
            <p className="filterText">Country</p>
            <Select
              onChange={handleLocation}
              mode="multiple"
              value={filters.State}
              allowClear
              showSearch
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select Country"
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {allCountries.map((country) => (
                <Option key={country.id} value={country.stateName}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </div>
          {states?.length ? (
            <div className="filter">
              <p className="filterText">
                {cities?.length
                  ? "Governorate/State"
                  : "Governorate/State or City"}
              </p>
              <Select
                onChange={handleStateChange}
                value={filters.Governates ? filters.Governates : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {states.map((state) => (
                  <Option key={state.id} value={state.stateName}>
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
          {cities?.length ? (
            <div className="filter">
              <p className="filterText">City</p>
              <Select
                onChange={handleCityChange}
                value={filters.Cities ? filters.Cities : undefined}
                allowClear
                showSearch
                mode="multiple"
                style={{ width: 200, marginBottom: 20 }}
                placeholder="Select Country"
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {cities.map((city) => (
                  <Option key={city.id} value={city.name}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default Filters;
