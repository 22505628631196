import { create } from "zustand";

export const useSiteVisitStore = create((set) => ({
  siteVisitList: [],
  otherDocuments: [],
  siteVideos: [],

  setSiteVisitList: (data) => {
    if (Array.isArray(data)) {
      set((state) => ({ ...state, siteVisitList: data }));
    }
  },

  setOtherDocuments: (data) => {
    if (Array.isArray(data)) {
      set((state) => ({ ...state, otherDocuments: data }));
    }
  },

  setSiteVideos: (data) => {
    if (Array.isArray(data)) {
      set((state) => ({ ...state, siteVideos: data }));
    }
  },

  addSiteVisit: (siteVisit) => {
    set((state) => ({
      siteVisitList: [...state.siteVisitList, siteVisit],
    }));
  },

  addOtherDocument: (document) => {
    set((state) => ({
      otherDocuments: [...state.otherDocuments, document],
    }));
  },

  addSiteVideo: (video) => {
    set((state) => ({
      siteVideos: [...state.siteVideos, video],
    }));
  },

  clearSiteVisitList: () => {
    set((state) => ({ ...state, siteVisitList: [] }));
  },

  clearOtherDocuments: () => {
    set((state) => ({ ...state, otherDocuments: [] }));
  },

  clearSiteVideos: () => {
    set((state) => ({ ...state, siteVideos: [] }));
  },
}));
