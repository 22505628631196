import React, { useEffect, useState } from "react";
import { Timeline, Button, Collapse, Card, Modal, Divider } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import { httpService } from "../../../../../../services/httpService.service";

const { Panel } = Collapse;

const SiteVisitProgress = ({ visible, onClose, siteVisitCard }) => {
  const [siteVisitHistoryData, setSiteVisitHistoryData] = useState([]);

  useEffect(() => {
    if (siteVisitCard?.id) {
      fetchSiteVisitHistory(siteVisitCard.id);
    }
  }, [siteVisitCard, visible]);

  const fetchSiteVisitHistory = (siteVisitId) => {
    httpService.get(
      `/api/Site/getSiteVisitDetails?id=${siteVisitId}&pageNumber=1&pageSize=10`,
      (response) => {
        setSiteVisitHistoryData(response?.data?.data || []);
      },
      (error) => {
        console.error("Error fetching site visit history:", error);
      }
    );
  };

  const extractAndFormatDate = (text) => {
    const dateMatch = text.match(
      /:\s(\d{1,2}\/\d{1,2}\/\d{4}\s\d{1,2}:\d{2}:\d{2}\s(?:AM|PM))/
    );
    const userMatch = text.match(/^([^\s]+\s[^\s]+)/);

    const status = text.match("NoStatus", "Planning");

    if (!dateMatch) return { string: text, date: null };

    const rawDate = dateMatch[1];
    const cleanedString = text.replace(dateMatch[0], "").trim();
    const dateObj = new Date(rawDate);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    }).format(dateObj);

    let finalString = cleanedString;
    const userName = userMatch?.[0];
    if (userMatch) {
      finalString = finalString.replace(userName, ``);
    }

    return {
      userName: userName,
      string: finalString.trim(),
      date: formattedDate,
    };
  };

  const VisitHistoryCard = ({ text, index, total }) => {
    const { userName, string, date } = extractAndFormatDate(text);
    return (
      <React.Fragment key={index}>
        <div className="historyCard">
          <p className="normalText">
            {userName && <strong>{`${userName} - `}</strong>}
            {string}
          </p>
          {date && (
            <small>
              <strong>Time: </strong> {date}
            </small>
          )}
        </div>
        {index < total - 1 && <Divider className="divider" />}
      </React.Fragment>
    );
  };

  return (
    <Modal
      title="Site Visit History"
      visible={visible}
      onCancel={onClose}
      footer={null} // No footer needed
      width={600} // Adjust width as per design
    >
      <div className="historyWrapper w-100">
        {siteVisitHistoryData.length > 0 ? (
          siteVisitHistoryData.map((item, index) => (
            <VisitHistoryCard
              key={index}
              text={item}
              index={index}
              total={siteVisitHistoryData.length}
            />
          ))
        ) : (
          <div className="historyCard w-100 d-flex justify-content-center">
            <strong className="strongText w-100 d-flex justify-content-center">
              No history yet!
            </strong>
          </div>
        )}
      </div>

      <div className="buttonsModal">
        <Button className="inviteButtonTPM" key="cancel" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default SiteVisitProgress;
