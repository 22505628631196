import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Select,
  Divider,
  Spin,
} from "antd";
import "../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../services/httpService.service";
import AddMemberIcon from "./../../../../../Assets/svg/addMemberIcon.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import e from "cors";

const { Option } = Select;

const AddDriverCardModal = ({ visible, onClose, setMessage, fetchDrivers }) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(null);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setStates([]);
    setCities([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/GeoData/getCountriesOfContract?contractId=${contractId}`,
      (response) => {
        const countries = response?.data;
        setCountries(countries);
        if (countries && countries.length === 1) {
          const countryId = countries[0]?.id;
          setSelectedCountryId(countryId);
          form.setFieldsValue({ driverBaseLocation: countryId });
          fetchStates(countryId);
        }
      },
      (error) => {
        console.error("Error fetching teams:", error);
      }
    );
  }, [visible]);

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        const currentContractId = localStorage.getItem("currentContractId");

        const payload = {
          name: values.driverName,
          phoneNumber: values.driverPhoneNumber,
          vehicleInfo: values.vehicleInfo,
          countryId: values.driverBaseLocation,
          cityIds: values.city,
          stateIds: values.state,
          license: 1,
          contractId: currentContractId,
        };

        httpService.post(
          "/api/Driver",
          payload,
          (response) => {
            const message =
              response.message ||
              "A new driver has been created and added to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Driver added successfully!",
              text: message,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            form.resetFields();
            handleLeave();
            fetchDrivers();
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            const messageError = error?.errors?.[0]?.message;
            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text:
                messageError ||
                "We had a problem adding this red flag to your database, please try again.",
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
          }
        );
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Validation Error",
          text: "Please fill in all required fields correctly.",
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const fetchStates = (countryId) => {
    setCitiesLoading(true);
    httpService.get(
      `/api/GeoData/states?countryIds=${[countryId]}`,
      (res) => {
        setStates(res?.data);
        setCitiesLoading(false);
      },
      (error) => {
        console.log(error);
        setCitiesLoading(false);
      }
    );
  };

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "state"]);
    setSelectedCountryId(countryId);
    if (!countryId) {
      setStates([]);
      setCities([]);
    }
  };

  const fetchCities = (stateIds) => {
    setCitiesLoading(true);
    const params = new URLSearchParams();
    stateIds.forEach((id) => params.append("stateIds", id));
    if (stateIds?.length > 0) {
      httpService.get(
        `/api/GeoData/cities?${params}`,
        (res) => {
          const citiesData = res.data;
          setCities(citiesData);
          setCitiesLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setCities([]);
      setCitiesLoading(false);
    }
  };

  const handleStateChange = (stateId) => {
    form.resetFields(["city"]);
    if (stateId) {
      fetchCities(stateId);
    } else {
      setCities([]);
    }
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={600}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Driver card</h2>
      <Form form={form} layout="vertical">
        {" "}
        <p className="modalsHeader1">General details</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="driverName"
            label="Driver Name"
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input placeholder="Enter driver name" autoComplete="off" />
          </Form.Item>
          <Form.Item
            style={{ width: "48%" }}
            name="driverPhoneNumber"
            label="Driver phone number"
            rules={[{ required: true, message: "Enter the phone number" }]}
          >
            <PhoneInput
              onChange={(value, country) => console.log(country)}
              country={"us"}
              international
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="vehicleInfo"
            label="Driver Vehicle Info"
            rules={[{ required: true, message: "Enter the vehicle info" }]}
          >
            <Input placeholder="Enter Name" autoComplete="off" />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="driverBaseLocation"
            label="Driver base country"
            rules={[{ required: true, message: "Select a country" }]}
          >
            <Select
              placeholder="Select a country"
              allowClear
              showSearch
              value={selectedCountryId}
              onChange={handleCountryChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {countries?.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Spin spinning={citiesLoading}>
            <Form.Item
              name="state"
              label={
                (cities?.length ?? 0) < 1
                  ? "Governorate/State or city"
                  : "Governorate/State"
              }
              rules={[
                {
                  required: true,
                  message:
                    cities?.length > 0
                      ? "Please select at least a state"
                      : "Please select at least a state or a city!",
                },
              ]}
            >
              <Select
                placeholder={
                  cities?.length > 0
                    ? "Please select at least a state"
                    : "Please select at least a state or a city!"
                }
                allowClear
                mode="multiple"
                onChange={handleStateChange}
                showSearch
                disabled={states?.length < 1}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {states?.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Spin>
        </div>
        {cities?.length > 0 && (
          <Spin spinning={citiesLoading}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Form.Item
                name="city"
                label="City/Disctrict/Municipality"
                rules={[
                  {
                    required: cities?.length > 0,
                    message: "Enter the location here",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select a City"
                  allowClear
                  showSearch
                  disabled={cities?.length < 1}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {cities?.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Spin>
        )}
        <Divider />
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Add driver card
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddDriverCardModal;
