import React, { useState, useEffect } from "react";
import { message, Spin, Input, Button, Form } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { httpService } from "../../../services/httpService.service";
import "./styles.scss";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import Done from "../../../Assets/svg/done.svg";
import ExclamationMark from "../../../Assets/svg/exclamationMark.svg";

const NewPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const email = location.state?.email;

  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);

  const handleForgotPassword = async (values) => {
    const { newPassword, confirmNewPassword } = values;
    if (newPassword !== confirmNewPassword) {
      message.error("Passwords do not match");
      return;
    }

    setLoading(true);

    httpService.post(
      "/api/account/forgotPassword",
      {
        email,
        newPassword,
        confirmNewPassword,
      },
      (response) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: `Password changed successfully!`,
          text: `Password has been changed successfully!`,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        navigate("/login");
        setLoading(false);
      },
      (error) => {
        const errorMessage = error.response?.data?.errors[0]?.message;
        setMessage({
          trigger: true,
          type: "danger",
          icon: ExclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage || "Failed to change password",
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="col-md-6 right-column">
          <div className="form-wrapper">
            <Form
              form={form}
              onFinish={handleForgotPassword}
              layout="vertical"
              initialValues={{ email }}
            >
              <h2>Reset Password</h2>
              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="addButtonTPM mt-5"
                  disabled={loading}
                >
                  {loading ? <Spin /> : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
