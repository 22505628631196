import React, { useContext, useState } from "react";
import { Button, Spin, Tabs } from "antd";
import TableViewComponent from "./TableView/TableView";
import KanbanView from "./KanbanView/KanbanView";
import AddNewTaskModal from "./Modals/AddNewTaskModal";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import { DataContext, DataProvider } from "./DataContext";
import "./style.scss";
import { PlusOutlined } from "@ant-design/icons";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import DeletedModal from "./Modals/DeletedModal";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";
import DraftsTableView from "./TableView/DraftsTableView";

const TasksAssignments = ({ loadTasks }) => {
  const context = useContext(DataContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDeletedModal, setShowDeletedModal] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleDeletedTasks = () => {
    setShowDeletedModal(true);
  };
  const updateData = () => {
    setDataUpdated(true);
  };

  if (!context) {
    return <></>;
  }
  const { totalItemCount, loading } = context;

  return (
    <div className="ip-planning-wrapper">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="tab-container d-flex flex-column">
        <div className="text-title">
          <h3>Task Allocation</h3>
          <div className="ip-planning-buttons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Button className="inviteButtonTPM" onClick={handleDeletedTasks}>
                <img src={DeleteButton} alt="See deleted cards" />
                See deleted cards
              </Button>
            )}
            {/* {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
              ]) && (
                <Button className="archived-selectedBtn" disabled>
                  Archive selected task
                </Button>
              )} */}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
            ]) && (
              <Button className="add-new-ipBtn" onClick={showModal}>
                <PlusOutlined />
                Assign New Task
              </Button>
            )}
          </div>
        </div>

        <Tabs
          className="tableviewTabs"
          defaultActiveKey="1"
          items={[
            {
              label: "Table View",
              key: "1",
              children: (
                <TableViewComponent
                  dataUpdated={dataUpdated}
                  setDataUpdated={setDataUpdated}
                />
              ),
            },
            {
              label: "Kanban View",
              key: "2",
              children: (
                <KanbanView
                  dataUpdated={dataUpdated}
                  setDataUpdated={setDataUpdated}
                />
              ),
            },
            {
              label: (
                <span className="custom-tab-label">
                  Task drafts{" "}
                  {loading ? (
                    <span className="loader">
                      <Spin />
                    </span>
                  ) : (
                    <span className="draft-count">{totalItemCount}</span>
                  )}
                </span>
              ),
              key: "3",
              children: (
                <DraftsTableView
                  dataUpdated={dataUpdated}
                  setDataUpdated={setDataUpdated}
                />
              ),
            },
          ]}
        />
      </div>
      <AddNewTaskModal
        visible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setMessage={setMessage}
        updateData={updateData}
      />
      <DeletedModal
        visible={showDeletedModal}
        onOk={handleDeletedTasks}
        onCancel={() => setShowDeletedModal(false)}
      />
    </div>
  );
};

export default TasksAssignments;
