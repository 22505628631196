import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
} from "antd";
import React, { useEffect } from "react";

const VisitDetails = ({
  form,
  kills,
  setKills,
  siteContactsRequired,
  setSiteContactsRequired,
  observationList,
  setObservationList,
}) => {
  const handleKillsChange = (value) => {
    console.log(value, typeof value);
    setKills(value);
  };
  useEffect(() => {
    if (form) {
      const currentFields = form.getFieldValue("KIIcontacts") || [];
      const updatedFields = Array.from({ length: kills }).map(
        (_, index) => currentFields[index] || {}
      );
      form.setFieldsValue({ KIIcontacts: updatedFields });
    }
  }, [kills, form]);

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="dateOfPlannedVisit"
            label="Date of Planned Visit"
            rules={[
              {
                required: true,
                message: "Please select the date of the planned visit!",
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <div style={{ marginBottom: 8, paddingTop: "20px" }}>
        <span style={{ fontWeight: "700" }}>IP point of contact</span>
        <Form.Item>
          <div className="siteContactsWrapper">
            <Form.Item
              name={["pointContacts", "id"]}
              style={{ display: "none" }}
            >
              <Input type="hidden" />
            </Form.Item>
            <div className="siteContactForm">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={["pointContacts", "contactName"]}
                    label="Contact Name"
                    rules={[
                      { required: true, message: "Contact Name required" },
                    ]}
                  >
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["pointContacts", "email"]} label="Email">
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={["pointContacts", "role"]}
                    label="Role"
                    rules={[{ required: true, message: "Role required" }]}
                  >
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={["pointContacts", "organization"]}
                    label="Organization"
                    rules={[
                      { required: true, message: "Organization required" },
                    ]}
                  >
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["pointContacts", "phone"]} label="Phone">
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={["pointContacts", "skype"]} label="Name of the tool">
                    <Input placeholder="Enter text here" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Form.Item>

        <div style={{ marginBottom: 8, paddingTop: "20px" }}>
          <Form.Item name="siteContactsRequired" valuePropName="checked">
            <Checkbox
              className="d-flex flex-row justify-items-center align-items-center"
              checked={siteContactsRequired}
              onChange={() => {
                const newChecked = !siteContactsRequired; // Toggle the current state
                setSiteContactsRequired(newChecked);
                form.setFieldsValue({ siteContactsRequired: newChecked });
              }}
            >
              <span style={{ fontWeight: "700" }}>
                Site Contacts (optional)
              </span>
            </Checkbox>
          </Form.Item>

          {siteContactsRequired ? (
            <Form.Item>
              <div className="siteContactsWrapper">
                <Form.Item
                  name={["siteContacts", "id"]}
                  style={{ display: "none" }}
                >
                  <Input type="hidden" />
                </Form.Item>

                <div className="siteContactForm">
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name={["siteContacts", "contactName"]}
                        label="Contact Name"
                        rules={[
                          {
                            required: siteContactsRequired,
                            message: "Contact Name required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={["siteContacts", "email"]} label="Email">
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={["siteContacts", "role"]}
                        label="Role"
                        rules={[
                          {
                            required: siteContactsRequired,
                            message: "Role required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name={["siteContacts", "organization"]}
                        label="Organization"
                        rules={[
                          {
                            required: siteContactsRequired,
                            message: "Organization required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={["siteContacts", "phone"]} label="Phone">
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name={["siteContacts", "skype"]} label="Name of the tool">
                        <Input placeholder="Enter text here" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form.Item>
          ) : null}
        </div>
      </div>

      <h1
        style={{
          flex: "1 0 0",
          color: "var(--Neutrals-Black, #3A3737)",
          fontFamily: "Poppins",
          fontSize: "22px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "32px",
        }}
        className="mt-5"
      >
        Sample size
      </h1>
      <div style={{ marginBottom: 8, paddingTop: "25px" }}>
        <Col span={8}>
          <Form.Item required name="beneficiariesCount" label="Beneficiaries">
            <InputNumber min={0} defaultValue={0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="kills"
            required
            label="KII(s)"
            tooltip="Number of KII(s) to be observed"
          >
            <InputNumber min={0} value={kills} onChange={handleKillsChange} />
          </Form.Item>
        </Col>
        {kills > 0 && (
          <>
            <span style={{ fontWeight: "700" }}>KII contacts (optional)</span>
            <Form.List
              name="KIIcontacts"
              initialValue={Array.from({ length: kills })}
            >
              {(fields) => (
                <>
                  <div className="siteContactsWrapper">
                    <div className="siteContactForm">
                      {fields.map((field, index) => (
                        <Row
                          key={field.key}
                          gutter={16}
                          style={{
                            padding: "15px 0",
                            borderBottom:
                              index < fields.length - 1
                                ? "1px solid #FADCEA"
                                : "none",
                          }}
                        >
                          <Col span={24}>
                            <Row gutter={24}>
                              {/* Contact Name */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "contactName"]}
                                  fieldKey={[field.fieldKey, "contactName"]}
                                  label="Contact Name"
                                  rules={[
                                    {
                                      required: kills > 0,
                                      message: "Contact Name required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              {/* Email */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "email"]}
                                  fieldKey={[field.fieldKey, "email"]}
                                  label="Email"
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              {/* Role */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "role"]}
                                  fieldKey={[field.fieldKey, "role"]}
                                  label="Role"
                                  rules={[
                                    {
                                      required: kills > 0,
                                      message: "Role required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              {/* Organization */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "organization"]}
                                  fieldKey={[field.fieldKey, "organization"]}
                                  label="Organization"
                                  rules={[
                                    {
                                      required: kills > 0,
                                      message: "Organization required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              {/* Phone */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "phone"]}
                                  fieldKey={[field.fieldKey, "phone"]}
                                  label="Phone"
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                              {/* Skype */}
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "skype"]}
                                  fieldKey={[field.fieldKey, "skype"]}
                                  label="Name of the tool"
                                >
                                  <Input placeholder="Enter text here" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Form.List>
          </>
        )}
      </div>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="ObservationListCheck"
            label="Observation checklist"
            style={{ marginBottom: 20, marginTop: 20 }}
            valuePropName="checked"
          >
            <Switch
              value={observationList}
              onChange={() => setObservationList(!observationList)}
            />
          </Form.Item>
        </Col>

        {observationList == true ? (
          <Col span={12}>
            <Form.Item
              name="observationChecklistCount"
              label="Observation checklist"
              required={observationList}
              tooltip="Items to be checked during observation"
            >
              <InputNumber min={0} defaultValue={3} />
            </Form.Item>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row>
        {observationList == true ? (
          <Col span={24}>
            <Form.Item
              label="What type of observation checklist?"
              style={{ paddingTop: "20px" }}
              name="observationChecklistType"
            >
              <Input.TextArea rows={3} placeholder="Explain the type of list" />
            </Form.Item>
          </Col>
        ) : (
          ""
        )}
      </Row>

      {/* <div className="mediaWrapper">
        <div className="info">
          <div className="content">
            <div className="image">
              <img src={dangerIcon} alt="danger icon" />
            </div>
            <p>
              <span>Disclosure:</span> prior informed consent is needed.
            </p>
          </div>
        </div>
        <div className="card">
          <Form.Item name="videosRequired" valuePropName="checked">
            <Checkbox
              value={requiredVideos}
              onChange={handleVideosRequiredChange}
            >
              Videos
            </Checkbox>
          </Form.Item>

          <Divider className="m-2" />
          <Form.Item
            name="videosLimit"
            label="How many?"
            required={requiredVideos}
          >
            <InputNumber
              min={0}
              value={videosLimit}
              defaultValue={0}
              onChange={handleVideosLimitChange}
              disabled={!requiredVideos}
            />
          </Form.Item>
        </div>
        <div className="card">
          <Form.Item name="photosRequired" valuePropName="checked">
            <Checkbox
              value={requiredPhotos}
              onChange={handlePhotosRequiredChange}
            >
              Photos
            </Checkbox>
          </Form.Item>

          <Divider className="m-2" />
          <Form.Item
            name="photosLimit"
            label="How many?"
            required={requiredPhotos}
          >
            <InputNumber
              value={photosLimit}
              defaultValue={0}
              onChange={handlePhtosLimitChange}
              min={0}
              disabled={!requiredPhotos}
            />
          </Form.Item>
        </div>
      </div> */}
      <Row>
        <Col span={24}>
          <Form.Item
            label="Specifics of on-site operation"
            style={{ paddingTop: "20px" }}
            name="specificsOfOnSiteOperation"
          >
            <Input.TextArea rows={3} placeholder="Enter instructions" />
          </Form.Item>
          {/* <Form.Item name="observationList" valuePropName="checked">
            <div className="d-flex  align-items-center" style={{ gap: "10px" }}>
              <Switch />
              <span>Observation List </span>
            </div>
          </Form.Item> */}
        </Col>
      </Row>
      <Form.Item
        name="samplingInstructions"
        label="Sampling instructions (beneficiaries, sample, profile, selection method, etc)"
      >
        <Input.TextArea rows={3} placeholder="Enter sampling instructions" />
      </Form.Item>
      <Form.Item name="clientInstructions" label="Instructions for any KII(s)">
        <Input.TextArea rows={3} placeholder="Enter instructions" />
      </Form.Item>
      <Form.Item name="otherInstructions" label="Any other instructions">
        <Input.TextArea rows={3} placeholder="Enter instructions" />
      </Form.Item>
      <Form.Item name="visitPlanApproval" label="Visit plan approver">
        <Input.TextArea rows={3} placeholder="Enter visit plan approver" />
      </Form.Item>
    </div>
  );
};
export default VisitDetails;
