import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Space, Divider, Spin } from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import minusButtonIcon from "../../../Assets/Images/minusButtonIcon.png";
import { httpService } from "../../../services/httpService.service";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";
import showConfirm from "../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const AddMember = ({ visible, onClose, onSubmit, setMessage }) => {
  const [form] = Form.useForm();
  const [rolePermissions, setRolePermissions] = useState([{ id: 1 }]);
  const idCounter = useRef(1);
  const [workspaces, setWorkspaces] = useState([]);
  const [roles, setRoles] = useState([]);
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setOffices([]);
    setRolePermissions([{ id: 1 }]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const fetchOfficeData = () => {
    httpService.get(
      `/api/Office/getAll?IgnorePagination=true`,
      (res) => setOffices(res.data),
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    fetchOfficeData();
    httpService.get(
      "/api/Account/getAllRoles",
      (res) => setRoles(res.data),
      (error) => console.log(error)
    );

    httpService.get(
      "/api/contract/getAll?pageSize=10000&pageNumber=1",
      (res) => setWorkspaces(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleRoleChange = (roleId) => {
    const updatedValues = rolePermissions.reduce((acc, role) => {
      acc[`role${role.id}`] = roleId;
      return acc;
    }, {});
    form.setFieldsValue(updatedValues);
  };

  const handleAddRolePermissions = () => {
    const newId = idCounter.current + 1;
    idCounter.current = newId;

    const lastRoleId = rolePermissions[rolePermissions.length - 1].id;
    const lastRoleValue = form.getFieldValue(`role${lastRoleId}`);

    const newRolePermissions = [...rolePermissions, { id: newId }];
    setRolePermissions(newRolePermissions);

    form.setFieldsValue({
      [`role${newId}`]: lastRoleValue,
    });
  };
  const handleRemoveRolePermissions = (idToRemove) => {
    const updatedPermissions = rolePermissions.filter(
      (role) => role.id !== idToRemove
    );
    form.resetFields([`role${idToRemove}`, `contract${idToRemove}`]);
    setRolePermissions(updatedPermissions);
  };

  const handleOk = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { email, position, country, city, state, office, ...rest } =
          values;

        const officeLocations = Array.isArray(office)
          ? office
          : office
          ? [office]
          : [];

        const contractRoles = rolePermissions.map((role) => ({
          contractId: rest[`contract${role.id}`],
          roleId: rest[`role${role.id}`],
        }));

        const requestBody = {
          email,
          position,
          officeLocations,
          contractRoles,
        };

        httpService.post(
          "/api/User/addMember",
          requestBody,
          (response) => {
            const message =
              response.message ||
              "A new member has been created and added to your database!";

            form.resetFields();

            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Member added successfully!",
              text: message,
            });

            onSubmit();
            handleLeave();
            setLoading(false);

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this member to your database, please try again.";

            console.error("Error message:", errorMessage);

            setMessage({
              trigger: true,
              type: "danger",
              icon: AddMemberIcon,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setLoading(false);

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
          }
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAvailableWorkspaces = (index) => {
    const selectedWorkspaceIds = rolePermissions
      .filter((_, idx) => idx !== index)
      .map((role) => form.getFieldValue(`workspace${role.id}`))
      .filter(Boolean);
    return workspaces.filter(
      (workspace) => !selectedWorkspaceIds.includes(workspace.id)
    );
  };

  const validateDuplicateContract = (_, value) => {
    const contractValues = rolePermissions.map((role) =>
      form.getFieldValue(`contract${role.id}`)
    );
    const duplicate =
      contractValues.filter((contract) => contract === value).length > 1;
    return duplicate
      ? Promise.reject(new Error("You can not select alredy selected contract"))
      : Promise.resolve();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Add New Member</h2>

      <Form form={form} layout="vertical">
        <p className="modalsHeader1">Personal Information</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Email" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true, message: "Please select position" }]}
            style={{ width: "48%" }}
          >
            <Input placeholder="Enter Position" autoComplete="off" />
          </Form.Item>
        </div>
        <p className="modalsHeader1">Location Information</p>

        <Form.Item
          label="Office"
          name="office"
          rules={[{ required: true, message: "Please select an office" }]}
        >
          <Select
            allowClear
            mode="multiple"
            placeholder="Select an office"
            filterOption={(input, option) =>
              option.label.toLowerCase().startsWith(input.toLowerCase())
            }
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
        </Form.Item>
        <p className="modalsHeader1">Roles and Permissions</p>
        {rolePermissions.map((role, index) => (
          <div key={role.id} className="rolesAndPremissions">
            <Form.Item
              name={`role${role.id}`}
              label="Role"
              rules={[{ required: true, message: "Please select a role" }]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Role"
                allowClear
                showSearch
                onChange={handleRoleChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {roles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.roleName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={`contract${role.id}`}
              label="Contract"
              rules={[
                { required: true, message: "Please select contract" },
                { validator: validateDuplicateContract },
              ]}
              style={{ width: "48%" }}
            >
              <Select
                placeholder="Select Contract"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {getAvailableWorkspaces(index).map((workspace) => (
                  <Option key={workspace.id} value={workspace.id}>
                    {workspace.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {rolePermissions.length > 1 && (
              <div onClick={() => handleRemoveRolePermissions(role.id)}>
                <img
                  src={minusButtonIcon}
                  style={{ cursor: "pointer" }}
                  alt="Remove role permission"
                />
              </div>
            )}
          </div>
        ))}
        <div
          className="addRolesAndPremissins"
          onClick={handleAddRolePermissions}
        >
          Enroll to another workspace
        </div>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Add Member
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMember;
